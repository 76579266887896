import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, TextField } from "react-md";
import { Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../styles/createAppointmentStyles.css";
import FontIcon from "react-md/lib/FontIcons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NavigoDashboardUtils from "../services/NavigoDashboardUtils";
import EventService from "../../../../../service/event/EventService";
import debounce from "debounce";
import { TextField as TextField2 } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import intToTime from "../helper/intToTime";

export const CreateAccount = (props) => {
  const [patientIdList, setPatientIdList] = useState([]);
  const [patientDetails, setPatientDetails] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const [accountSelected, setAccountSelected] = useState(null);
  const [planList, setPlanList] = useState([]);
  const [planSelected, setPlanSelected] = useState(null);
  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [serviceTypeSelected, setServiceTypeSelected] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [serviceListToShow, setServiceListToShow] = useState([]);
  const [serviceSelected, setServiceSelected] = useState(null);
  const [serviceCodeNameSelected, setServiceCodeNameSelected] = useState(0);
  const [serviceModeList, setServiceModeList] = useState([]);
  const [serviceModeSelected, setServiceModeSelected] = useState(null);
  const [selectServiceProviderCheckbox, setSelectServiceProviderCheckbox] =
    useState(false);
  const [serviceProviderList, setServiceProviderList] = useState([]);
  const [serviceProviderListToShow, setServiceProviderListToShow] = useState(
    []
  );
  const [serviceProviderSelected, setServiceProviderSelected] = useState(null);
  const [serviceProviderAutoSelected, setServiceProviderAutoSelected] =
    useState(null);
  const [familyMemberList, setFamilyMemberList] = useState([]);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState(null);
  const [relationsList, setRelationsList] = useState([]);
  const [dateSelected, setDateSelected] = useState(null);
  const [slotList, setSlotList] = useState([]);
  const [slotSelected, setSlotSelected] = useState(null);
  const [patientContactNumber, setPatientContactNumber] = useState(null);
  const [patientContactNumberCheckbox, setPatientContactNumberCheckbox] =
    useState(false);
  const [reasonList, setReasonList] = useState([]);
  const [reasonSelected, setReasonSelected] = useState(null);
  const [reasonDesc, setReasonDesc] = useState("");
  const [stateList, setStateList] = useState([]);
  const [stateSelected, setStateSelected] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [citySelected, setCitySelected] = useState(null);
  const [localityList, setLocalityList] = useState([]);
  const [localitySelected, setLocalitySelected] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [addressSelected, setAddressSelected] = useState(null);
  const [
    serviceProviderLocationFilterArr,
    setServiceProviderLocationFilterArr,
  ] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigoDashboardUtils = new NavigoDashboardUtils();
  const MODES_WITH_AUTO_SP = ["Video", "Tele", "At Home"];
  function isAutoSPAllowed(mode) {
    return MODES_WITH_AUTO_SP.includes(mode);
  }

  useEffect(() => {
    getAllRelation();
    fetchReasonsForConsultation();
  }, []);

  useEffect(() => {
    if (patientDetails != null && patientDetails.id) {
      getAccountsForPid(patientDetails.id);
      getFamilyMembers(patientDetails.id);
    }
  }, [patientDetails]);
  useEffect(() => {
    if (accountSelected != null && accountSelected != "") {
      console.log(
        "pr- going to fect plan for acc=",
        patientDetails.id,
        accountSelected
      );

      getPlansForPid(patientDetails.id, accountSelected);
    }
  }, [accountSelected]);

  useEffect(() => {
    if (planSelected != null) {
      console.log("pr- going to fect service for plan=", planSelected.planId);
      getAssignedServicesToPlanByFilter(planSelected.planId);
    }
  }, [planSelected]);

  useEffect(() => {
    if (serviceTypeSelected != null) {
      let newList = serviceList.filter(
        (e) => e.serviceTypeId == serviceTypeSelected.serviceTypeId
      );
      setServiceListToShow(newList);
      console.log("pr- options=", serviceList, newList);
    }
  }, [serviceTypeSelected]);

  useEffect(() => {
    if (serviceSelected != null) {
      let newList = serviceSelected.serviceModeDetails.map((e) => e.modeName);
      console.log("pr- modes=", newList);
      setServiceModeList(newList);
    }
  }, [serviceSelected, serviceCodeNameSelected]);

  useEffect(() => {
    setSelectServiceProviderCheckbox(false);
    setServiceProviderSelected(null);
    setDateSelected(null);
    setSlotSelected(null);
    setSlotList([]);
    if (serviceModeSelected != null) {
      getProviderOfPlanService(serviceModeSelected);
      if (serviceTypeSelected.serviceTypeId == 2) {
        getSPLocationState();
      }
    }
  }, [serviceModeSelected]);

  useEffect(() => {
    console.log("pr- tie=", dateSelected?.format("YYYY-MM-DD"));

    if (dateSelected != null) {
      setSlotSelected(null);
      getAvailableSlots(dateSelected?.format("YYYY-MM-DD"));
    }
  }, [dateSelected]);

  useEffect(() => {
    if (stateSelected != null) {
      getSPLocationCity();
    }
  }, [stateSelected]);
  useEffect(() => {
    if (citySelected != null) {
      getSPLocationLocality();
    }
  }, [citySelected]);
  useEffect(() => {
    console.log(
      "pr- filt changed=",
      serviceProviderLocationFilterArr,
      serviceProviderList,
      serviceProviderListToShow
    );

    if (serviceProviderLocationFilterArr.length == 0) {
      setServiceProviderListToShow(serviceProviderList);
    } else {
      setServiceProviderListToShow(
        serviceProviderList.filter((e) =>
          serviceProviderLocationFilterArr.includes(e.serviceProviderId)
        )
      );
    }
  }, [serviceProviderLocationFilterArr]);

  const handlePidSearch = useCallback(
    debounce((e, pid) => {
      if (pid && pid.length > 0 && !isNaN(parseInt(pid, 10))) {
        navigoDashboardUtils.fetchPatientId(
          pid,
          (res) => {
            if (res && res.id !== undefined) {
              setPatientIdList([res]);
            } else {
              setPatientIdList([]);
            }
          },
          (err) => {
            setPatientIdList([]);
          }
        );
      }
    }, 300),
    []
  );

  // error when exit page
  // useEffect(() => {
  //   return () => handlePidSearch.cancel(); // Cleanup debounce on unmount
  // }, []);

  const getAccountsForPid = (id) => {
    console.log("pr- fetchng acc");

    const onResponse = (res) => {
      console.log("pr- accounts=", res);
      if (res.accountCodes.length != 0) {
        setAccountList(res.accountCodes);
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };

    navigoDashboardUtils.getAccountsForPid(id, onResponse, onError);
  };
  const getPlansForPid = (pid, accId) => {
    console.log("pr- fetchng acc");

    const onResponse = (res) => {
      console.log("pr- plans=", res);
      setPlanList(res.length != 0 ? res : []);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };

    navigoDashboardUtils.getPlansForPid(pid, accId, onResponse, onError);
  };
  const getAssignedServicesToPlanByFilter = (planId) => {
    console.log("pr- fetchng services");

    const onResponse = (res) => {
      console.log("pr- services=", res);
      if (res.services.length > 0) {
        let serviceTypesMap = new Map();

        res.services.forEach((e) => {
          if (!serviceTypesMap.has(e.serviceTypeId)) {
            serviceTypesMap.set(e.serviceTypeId, {
              serviceTypeId: e.serviceTypeId,
              serviceTypeName: e.serviceTypeName,
            });
          }
        });

        let serviceTypes = Array.from(serviceTypesMap.values()).sort(
          (a, b) => a.serviceTypeId - b.serviceTypeId
        );
        setServiceTypeList(serviceTypes);
        setServiceList(res.services);
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };

    navigoDashboardUtils.getAssignedServicesToPlanByFilter(
      {
        pageNum: 0,
        pageSize: 0,
        planIds: [planId],
      },
      onResponse,
      onError
    );
  };

  const getProviderOfPlanService = (mode) => {
    let body = {
      pageNum: 0,
      pageSize: 0,
      serviceIds: [serviceSelected.id],
      planId: planSelected.planId,
      mode: mode,
    };
    const onResponse = (res) => {
      console.log("pr- gort SPs=", res);

      setServiceProviderList(res.providers.length != 0 ? res.providers : []);
      setServiceProviderListToShow(
        res.providers.length != 0 ? res.providers : []
      );
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getProviderOfPlanService(body, onResponse, onError);
  };

  const getFamilyMembers = (pid) => {
    const onResponse = (res) => {
      let list = res.families;
      list.unshift({
        firstName: patientDetails.firstName,
        lastName: patientDetails.lastName,
        memberPatientId: patientDetails.id,
        relation: 0,
      });
      console.log("pr- got family members=", list);
      setFamilyMemberList(list);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getFamilyMembers(pid, onResponse, onError);
  };

  const getAllRelation = () => {
    const onResponse = (res) => {
      console.log("pr- got relations=", res);
      let relations = res;
      relations.unshift({ code: 0, value: "Self", gender: 0 });
      setRelationsList(relations);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getAllRelation(onResponse, onError);
  };

  const getAvailableSlots = (date) => {
    let body = {
      planId: planSelected.planId,
      serviceId: serviceSelected.id,
      serviceProviderId: selectServiceProviderCheckbox
        ? 0
        : serviceProviderSelected?.serviceProviderId == undefined
        ? null
        : serviceProviderSelected.serviceProviderId,
      bookingMode: serviceModeSelected,
      collectionDate: date,
      vendor: "",
      vendorCode: "",
    };
    const onResponse = (res) => {
      console.log("pr- got slots=", res);
      setSlotList(res.slots == null ? [] : res.slots);
      if (selectServiceProviderCheckbox) {
        setServiceProviderAutoSelected(res.serviceProviderId);
      } else {
        setServiceProviderAutoSelected(null);
      }
    };
    const onError = (err) => {
      setServiceProviderAutoSelected(null);
      setSlotList([]);
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getAvailableSlots(body, onResponse, onError);
  };

  const fetchReasonsForConsultation = () => {
    const onResponse = (res) => {
      console.log("pr- rfc=", res);
      setReasonList(res.length != 0 ? res : []);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.fetchReasonsForConsultation(onResponse, onError);
  };

  const handleSave = () => {
    let body = {};
    if (isDataValid()) {
      if (serviceTypeSelected.serviceTypeId == 1) {
        body = {
          appointmentDate: moment(dateSelected)
            .utc()
            .startOf("day")
            .toISOString(),
          bookedFor:
            selectedFamilyMember.memberPatientId == patientDetails.id
              ? 0
              : selectedFamilyMember.memberPatientId,
          patientID: patientDetails.id,
          serviceID: serviceSelected.id,
          timeSlot: slotSelected.value,
          planID: planSelected.planId,
          mode: serviceModeSelected,
          serviceProviderID: selectServiceProviderCheckbox
            ? serviceProviderAutoSelected
            : serviceProviderSelected?.serviceProviderId,
          isCustomContactNumber: patientContactNumberCheckbox,
          customContactNumber: patientContactNumberCheckbox
            ? {
                countryCode: patientDetails.countryCode,
                phoneNo: patientContactNumber,
              }
            : {
                countryCode: 0,
                phoneNo: 0,
              },
          reportURL: "",
          reasonId: reasonSelected.id,
          reasonDetails: reasonDesc,
        };
      } else if (serviceTypeSelected.serviceTypeId == 2) {
        body = {
          addressId: addressSelected.id,
          appointmentDate: moment(dateSelected)
            .utc()
            .startOf("day")
            .toISOString(),
          bookedFor:
            selectedFamilyMember.memberPatientId == patientDetails.id
              ? 0
              : selectedFamilyMember.memberPatientId,
          patientID: patientDetails.id,
          serviceID: serviceSelected.id,
          timeSlot: slotSelected.value,
          planID: planSelected.planId,
          mode: serviceModeSelected,
          serviceProviderID: selectServiceProviderCheckbox
            ? serviceProviderAutoSelected
            : serviceProviderSelected?.serviceProviderId,
          reportURL: "",
        };
      }
      createAppointment(body);
    }
  };

  const isDataValid = () => {
    if (
      !patientDetails?.id ||
      !Number.isInteger(patientDetails.id) ||
      patientDetails.id <= 0
    ) {
      EventService.emit("showError", {
        message: "Please enter valid patient details",
      });
      return;
    } else if (accountSelected == null) {
      EventService.emit("showError", {
        message: "Please select an account",
      });
      return;
    } else if (planSelected == null) {
      EventService.emit("showError", {
        message: "Please select a plan",
      });
      return;
    } else if (serviceTypeSelected == null) {
      EventService.emit("showError", {
        message: "Please select a service type",
      });
      return;
    } else if (serviceSelected == null) {
      EventService.emit("showError", {
        message: "Please select a service",
      });
      return;
    } else if (serviceModeSelected == null) {
      EventService.emit("showError", {
        message: "Please select a service mode",
      });
      return;
    } else if (
      selectServiceProviderCheckbox == false &&
      serviceProviderSelected == null
    ) {
      EventService.emit("showError", {
        message: "Please select a service provider",
      });
      return;
    } else if (selectedFamilyMember == null) {
      EventService.emit("showError", {
        message: "Please select a booking for member",
      });
      return;
    } else if (dateSelected == null) {
      EventService.emit("showError", {
        message: "Please select an appointment date",
      });
      return;
    } else if (slotSelected == null) {
      EventService.emit("showError", {
        message: "Please select a time slot",
      });
      return;
    } else {
      if (serviceTypeSelected.serviceTypeId == 1) {
        if (
          patientContactNumberCheckbox &&
          (!Number.isInteger(patientContactNumber) ||
            patientContactNumber.toString().length != 10)
        ) {
          EventService.emit("showError", {
            message: "Please enter a valid patient contact number",
          });
          return;
        } else if (reasonSelected == null) {
          EventService.emit("showError", {
            message: "Please select a reason for consultation",
          });
          return;
        } else if (reasonSelected.id == 6 && reasonDesc == "") {
          EventService.emit("showError", {
            message: "Please describe the reason",
          });
          return;
        } else {
          return true;
        }
      } else if (serviceTypeSelected.serviceTypeId == 2) {
        if (addressSelected == null) {
          EventService.emit("showError", {
            message: "Please select an address",
          });
          return;
        } else {
          return true;
        }
      }
    }

    return false;
  };
  const createAppointment = (body) => {
    setLoading(true);
    const onResponse = (res) => {
      EventService.emit("showError", {
        message: `Appointment created`,
      });
      setLoading(false);
      exitPage();
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
      setLoading(false);
    };
    navigoDashboardUtils.createAppointment(body, onResponse, onError);
  };

  const getSPLocationState = () => {
    let body = {
      type: "state",
      planId: planSelected.planId,
      serviceId: serviceSelected.id,
      serviceMode: serviceModeSelected,
    };

    const onResponse = (res) => {
      console.log("pr- got loc state=", res.state);
      setStateList(res.state.length != 0 ? res.state : []);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getSPLocation(body, onResponse, onError);
  };
  const getSPLocationCity = () => {
    let body = {
      type: "city",
      state: stateSelected,
      planId: planSelected.planId,
      serviceId: serviceSelected.id,
      serviceMode: serviceModeSelected,
    };

    const onResponse = (res) => {
      console.log("pr- got loc city=", res.city);
      setCityList(res.city.length != 0 ? res.city : []);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getSPLocation(body, onResponse, onError);
  };
  const getSPLocationLocality = () => {
    let body = {
      type: "locality",
      state: stateSelected,
      city: citySelected,
      planId: planSelected.planId,
      serviceId: serviceSelected.id,
      serviceMode: serviceModeSelected,
    };

    const onResponse = (res) => {
      console.log("pr- got loc locality=", res.locality);
      setLocalityList(res.locality.length != 0 ? res.locality : []);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getSPLocation(body, onResponse, onError);
  };
  const getSPByLoc = (state, city, locality) => {
    let body;
    if (locality != null) {
      body = {
        pageNum: 0,
        pageSize: 0,
        planId: planSelected.planId,
        serviceId: serviceSelected.id,
        serviceMode: serviceModeSelected,
        patientId: patientDetails.id,
        stateMatch: [state],
        cityMatch: [city],
        landmarkMatch: [locality],
      };
    } else if (city != null) {
      body = {
        pageNum: 0,
        pageSize: 0,
        planId: planSelected.planId,
        serviceId: serviceSelected.id,
        serviceMode: serviceModeSelected,
        patientId: patientDetails.id,
        stateMatch: [state],
        cityMatch: [city],
        // landmarkMatch: [locality],
      };
    } else if (state != null) {
      body = {
        pageNum: 0,
        pageSize: 0,
        planId: planSelected.planId,
        serviceId: serviceSelected.id,
        serviceMode: serviceModeSelected,
        patientId: patientDetails.id,
        stateMatch: [state],
        // cityMatch: [city],
        // landmarkMatch: [locality],
      };
    }

    const onResponse = (res) => {
      console.log("pr- got SP DEV=", res);
      setServiceProviderLocationFilterArr(
        res.providers.length == 0 ? [] : res.providers.map((e) => e.id)
      );
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.planServiceProviderPatientFilter(
      body,
      onResponse,
      onError
    );
  };

  const getPatientAddress = () => {
    const onResponse = (res) => {
      console.log("pr- address=", res);
      setAddressList(res.length != 0 ? res : []);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getPatientAddress(
      patientDetails.id,
      onResponse,
      onError
    );
  };

  const exitPage = () => {
    resetValues();
    props.setView(0);
  };

  const resetValues = (code) => {
    switch (code) {
      case 1: // after patient id change
        setPatientIdList([]);
        setPatientDetails(null);
        setAccountList([]);
        setAccountSelected(null);
        setPlanList([]);
        setPlanSelected(null);
        setServiceTypeList([]);
        setServiceTypeSelected(null);
        setServiceList([]);
        setServiceListToShow([]);
        setServiceSelected(null);
        setServiceCodeNameSelected(0);
        setServiceModeList([]);
        setServiceModeSelected(null);
        setSelectServiceProviderCheckbox(false);
        setServiceProviderLocationFilterArr([]);
        setServiceProviderList([]);
        setServiceProviderListToShow([]);
        setServiceProviderSelected(null);
        setServiceProviderAutoSelected(null);
        setFamilyMemberList([]);
        setSelectedFamilyMember(null);
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setPatientContactNumber(null);
        setPatientContactNumberCheckbox(false);
        setReasonSelected(null);
        setReasonDesc("");
        setStateList([]);
        setStateSelected(null);
        setCityList([]);
        setCitySelected(null);
        setLocalityList([]);
        setLocalitySelected(null);
        setAddressList([]);
        setAddressSelected(null);
        setLoading(false);
        break;
      case 2: // after account change
        setPlanList([]);
        setPlanSelected(null);
        setServiceTypeList([]);
        setServiceTypeSelected(null);
        setServiceList([]);
        setServiceListToShow([]);
        setServiceSelected(null);
        setServiceCodeNameSelected(0);
        setServiceModeList([]);
        setServiceModeSelected(null);
        setSelectServiceProviderCheckbox(false);
        setServiceProviderLocationFilterArr([]);
        setServiceProviderList([]);
        setServiceProviderListToShow([]);
        setServiceProviderSelected(null);
        setServiceProviderAutoSelected(null);
        setSelectedFamilyMember(null);
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setPatientContactNumberCheckbox(false);
        setReasonSelected(null);
        setReasonDesc("");
        setStateList([]);
        setStateSelected(null);
        setCityList([]);
        setCitySelected(null);
        setLocalityList([]);
        setLocalitySelected(null);
        setAddressList([]);
        setAddressSelected(null);
        setLoading(false);
        break;

      case 3: // after plan selection change
        setServiceTypeList([]);
        setServiceTypeSelected(null);
        setServiceList([]);
        setServiceListToShow([]);
        setServiceSelected(null);
        setServiceCodeNameSelected(0);
        setServiceModeList([]);
        setServiceModeSelected(null);
        setSelectServiceProviderCheckbox(false);
        setServiceProviderLocationFilterArr([]);
        setServiceProviderList([]);
        setServiceProviderListToShow([]);
        setServiceProviderSelected(null);
        setServiceProviderAutoSelected(null);
        setSelectedFamilyMember(null);
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setPatientContactNumberCheckbox(false);
        setReasonSelected(null);
        setReasonDesc("");
        setStateList([]);
        setStateSelected(null);
        setCityList([]);
        setCitySelected(null);
        setLocalityList([]);
        setLocalitySelected(null);
        setAddressList([]);
        setAddressSelected(null);
        setLoading(false);
        break;

      case 4: // after service type change
        setServiceListToShow([]);
        setServiceSelected(null);
        setServiceCodeNameSelected(0);
        setServiceModeList([]);
        setServiceModeSelected(null);
        setSelectServiceProviderCheckbox(false);
        setServiceProviderLocationFilterArr([]);
        setServiceProviderList([]);
        setServiceProviderListToShow([]);
        setServiceProviderSelected(null);
        setServiceProviderAutoSelected(null);
        setSelectedFamilyMember(null);
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setStateList([]);
        setStateSelected(null);
        setCityList([]);
        setCitySelected(null);
        setLocalityList([]);
        setLocalitySelected(null);
        setAddressList([]);
        setAddressSelected(null);
        setLoading(false);
        break;

      case 5: // after service code change
        setServiceModeList([]);
        setServiceModeSelected(null);
        setSelectServiceProviderCheckbox(false);
        setServiceProviderLocationFilterArr([]);
        setServiceProviderList([]);
        setServiceProviderListToShow([]);
        setServiceProviderSelected(null);
        setServiceProviderAutoSelected(null);
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setStateList([]);
        setStateSelected(null);
        setCityList([]);
        setCitySelected(null);
        setLocalityList([]);
        setLocalitySelected(null);
        setLoading(false);
        break;

      case 6: // after mode change
        setSelectServiceProviderCheckbox(false);
        setServiceProviderLocationFilterArr([]);
        setServiceProviderList([]);
        setServiceProviderListToShow([]);
        setServiceProviderSelected(null);
        setServiceProviderAutoSelected(null);
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setStateList([]);
        setStateSelected(null);
        setCityList([]);
        setCitySelected(null);
        setLocalityList([]);
        setLocalitySelected(null);
        setLoading(false);
        break;

      case 7: // after service provider change
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setLoading(false);
        break;

      case 8: // after location change
        setSelectServiceProviderCheckbox(false);
        setServiceProviderAutoSelected(null);
        setServiceProviderSelected(null);
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setLoading(false);
        break;

      default:
        setPatientIdList([]);
        setPatientDetails(null);
        setAccountList([]);
        setAccountSelected(null);
        setPlanList([]);
        setPlanSelected(null);
        setServiceTypeList([]);
        setServiceTypeSelected(null);
        setServiceList([]);
        setServiceListToShow([]);
        setServiceSelected(null);
        setServiceCodeNameSelected(0);
        setServiceModeList([]);
        setServiceModeSelected(null);
        setSelectServiceProviderCheckbox(false);
        setServiceProviderLocationFilterArr([]);
        setServiceProviderList([]);
        setServiceProviderListToShow([]);
        setServiceProviderSelected(null);
        setServiceProviderAutoSelected(null);
        setFamilyMemberList([]);
        setSelectedFamilyMember(null);
        setRelationsList([]);
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setPatientContactNumber(null);
        setPatientContactNumberCheckbox(false);
        setReasonList([]);
        setReasonSelected(null);
        setReasonDesc("");
        setStateList([]);
        setStateSelected(null);
        setCityList([]);
        setCitySelected(null);
        setLocalityList([]);
        setLocalitySelected(null);
        setAddressList([]);
        setAddressSelected(null);
        setLoading(false);
        break;
    }
  };

  return (
    <div className="navigo-module-page-padding">
      {console.log("pr- jumb=", serviceProviderLocationFilterArr)}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="navigo-module-view">
            <div className="navigo-module-heading-with-back-button">
              <div
                className="navigo-module-back-btn"
                onClick={() => {
                  exitPage();
                }}
              >
                <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
              </div>
              <h2>{`Create appointment`}</h2>
            </div>
            <div className="navigo-module-buttons-container">
              <div className="navigo-module-buttons-right-alignment">
                <Button
                  raised
                  disabled={loading}
                  className={
                    loading
                      ? "navigo-module-button-save-disabled"
                      : "navigo-module-button-save-enabled"
                  }
                  onClick={() => {
                    handleSave();
                  }}
                >
                  {"Save"}
                </Button>

                <Button
                  raised
                  disabled={loading}
                  className="navigo-module-button-cancel"
                  onClick={() => {
                    exitPage();
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <div className="navigo-module-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Patient ID`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <Autocomplete
                  style={{ top: "2px" }}
                  id="appointment-patient-id"
                  options={patientIdList}
                  value={patientDetails}
                  onChange={(e, v) => {
                    if (v) {
                      console.log("pr- got selected=", v);
                      setPatientDetails(v);
                      setPatientContactNumber(v.phoneno);
                    } else {
                      console.log("pr- got nothing on selected=", v);
                      resetValues(1);
                    }
                  }}
                  getOptionLabel={(option) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  filterOptions={(x) => x}
                  onInputChange={handlePidSearch}
                  renderInput={(params) => (
                    <TextField2
                      {...params}
                      placeholder="Type here"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "8px",
                        },
                        classes: {
                          underline: "custom-underline",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>

        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Patient name`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={
                    patientDetails
                      ? `${patientDetails.firstName} ${patientDetails.lastName}`
                      : ""
                  }
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Patient number`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "40px", marginRight: "20px" }}>
                    <TextField
                      value={
                        patientDetails ? `+${patientDetails.countryCode}` : ""
                      }
                      lineDirection="left"
                      disabled
                    />
                  </div>
                  <div style={{ flex: "1" }}>
                    <TextField
                      value={patientDetails ? `${patientDetails.phoneno}` : ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {serviceTypeSelected?.serviceTypeId == 2 && (
          <>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Date of birth`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={
                        patientDetails
                          ? `${moment(patientDetails.dateOfBirth).format(
                              "DD MMM YYYY"
                            )}`
                          : ""
                      }
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Email ID`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={patientDetails ? `${patientDetails.email}` : ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Select account`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <Autocomplete
                  id="mp-navigo-account-selection"
                  options={accountList}
                  value={accountSelected}
                  onChange={(e, v) => {
                    if (v) {
                      if (accountSelected != null) {
                        resetValues(2);
                      }
                      setAccountSelected(v);
                      console.log("pr- Selected account:", v);
                    } else {
                      setAccountSelected(null);
                      resetValues(2);
                    }
                  }}
                  getOptionLabel={(option) => option || ""}
                  filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField2
                      {...params}
                      placeholder="Select"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "8px",
                        },
                        classes: {
                          underline: "custom-underline",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Select plan`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <Autocomplete
                  id="mp-navigo-plan-selection"
                  options={planList}
                  value={planSelected}
                  onChange={(e, v) => {
                    if (v) {
                      if (planSelected != null) {
                        resetValues(3);
                      }
                      setPlanSelected(v);
                      console.log("pr- plan selected :", v);
                    } else {
                      setPlanSelected(null);
                      console.log("pr- No plan selected");
                      resetValues(3);
                    }
                  }}
                  getOptionLabel={(option) =>
                    option ? `(${option.planId}) ${option.name}` : ""
                  }
                  filterOptions={(options, { inputValue }) => {
                    const searchText = inputValue.toLowerCase();

                    return options.filter((option) => {
                      const planName = option.name.toLowerCase();
                      const planId = option.planId.toString();

                      return (
                        planName.includes(searchText) ||
                        planId.includes(searchText)
                      );
                    });
                  }}
                  renderInput={(params) => (
                    <TextField2
                      {...params}
                      placeholder="Select"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "8px",
                        },
                        classes: {
                          underline: "custom-underline",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Select service type`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <Autocomplete
                  id="mp-navigo-service-type-selection"
                  options={serviceTypeList}
                  value={serviceTypeSelected}
                  onChange={(e, v) => {
                    if (v) {
                      if (serviceTypeSelected != null) {
                        resetValues(4);
                      }
                      setServiceTypeSelected(v);
                      console.log("pr- ST selected :", v);
                    } else {
                      setServiceTypeSelected(null);
                      console.log("pr- No ST selected");
                      resetValues(4);
                    }
                  }}
                  getOptionLabel={(option) =>
                    option ? option.serviceTypeName : ""
                  }
                  filterOptions={(options, { inputValue }) => {
                    const searchText = inputValue.toLowerCase();

                    return options.filter((option) => {
                      const serviceTypeName =
                        option.serviceTypeName?.toLowerCase() || "";

                      return serviceTypeName.includes(searchText);
                    });
                  }}
                  renderInput={(params) => (
                    <TextField2
                      {...params}
                      placeholder="Select"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "8px",
                        },
                        classes: {
                          underline: "custom-underline",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        {serviceTypeSelected != null &&
        serviceTypeSelected.serviceTypeId == 1 ? (
          <div>
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Select service code`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-service-code-selection"
                      options={serviceListToShow}
                      value={
                        serviceCodeNameSelected == 2 ? null : serviceSelected
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (serviceSelected != null) {
                            resetValues(5);
                          }
                          setServiceSelected(v);
                          setServiceCodeNameSelected(1);
                          console.log("pr- Service selected :", v);
                        } else {
                          setServiceSelected(null);
                          setServiceCodeNameSelected(0);
                          resetValues(5);
                        }
                      }}
                      getOptionLabel={(option) => (option ? option.name : "")}
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const name = option.name?.toLowerCase() || "";

                          return name.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div
                  className="navigo-module-label"
                  style={{
                    height: "20px",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  Or
                </div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Select service name`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-service-name-selection"
                      options={serviceListToShow}
                      value={
                        serviceCodeNameSelected == 1 ? null : serviceSelected
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (serviceSelected != null) {
                            resetValues(5);
                          }
                          setServiceSelected(v);
                          setServiceCodeNameSelected(2);
                          console.log("pr- Service selected :", v);
                        } else {
                          setServiceSelected(null);
                          setServiceCodeNameSelected(0);
                          resetValues(5);
                        }
                      }}
                      getOptionLabel={(option) =>
                        option ? option.serviceName : ""
                      }
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const serviceName =
                            option.serviceName?.toLowerCase() || "";

                          return serviceName.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Select service mode`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-service-mode-selection"
                      options={serviceModeList}
                      value={serviceModeSelected}
                      onChange={(e, v) => {
                        if (v) {
                          if (serviceModeSelected != null) {
                            resetValues(6);
                          }

                          setServiceModeSelected(v);
                          console.log("pr- Service mode selected :", v);
                        } else {
                          setServiceModeSelected(null);
                          console.log("pr- No Service mode  selected");
                          resetValues(6);
                        }
                      }}
                      getOptionLabel={(option) => (option ? option : "")}
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const serviceMode = option.toLowerCase() || "";

                          return serviceMode.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <Checkbox
                      id="mp-appointment-checkbox-1"
                      name="mp-appointment-checkbox-1"
                      style={{ marginLeft: -13, marginTop: 3 }}
                      disabled={!isAutoSPAllowed(serviceModeSelected)}
                      // color={mode == 2 ? "default" : "secondary"}
                      checked={selectServiceProviderCheckbox}
                      onChange={(value, proxy) => {
                        setDateSelected(null);
                        setSlotSelected(null);
                        setSlotList([]);
                        setServiceProviderSelected(null);
                        setServiceProviderAutoSelected(null);
                        if (selectServiceProviderCheckbox) {
                          setSelectServiceProviderCheckbox(false);
                        } else {
                          setSelectServiceProviderCheckbox(true);
                        }
                      }}
                    />
                  </div>
                  <div className="navigo-module-label-checkbox">
                    {`Select service provider or check mark to select automatically`}{" "}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                </div>

                <div
                  className="navigo-module-checkbox-field-container"
                  style={{ marginTop: "-16px" }}
                >
                  <div>
                    <Autocomplete
                      id="mp-navigo-service-provider-selection"
                      options={serviceProviderListToShow}
                      value={serviceProviderSelected}
                      disabled={selectServiceProviderCheckbox}
                      onChange={(e, v) => {
                        if (v) {
                          if (serviceProviderSelected != null) {
                            resetValues(7);
                          }
                          setServiceProviderSelected(v);
                          console.log("pr- SP selected :", v);
                        } else {
                          setServiceProviderSelected(null);
                          console.log("pr- No SP selected");
                          resetValues(7);
                        }
                      }}
                      getOptionLabel={(option) =>
                        option ? option.serviceProviderName : ""
                      }
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const serviceProviderName =
                            option.serviceProviderName?.toLowerCase() || "";

                          return serviceProviderName.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Booking for`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-booking-for-selection"
                      options={familyMemberList}
                      value={selectedFamilyMember}
                      onChange={(e, v) => {
                        if (v) {
                          setSelectedFamilyMember(v);
                          console.log("pr- family member selected :", v);
                        } else {
                          setSelectedFamilyMember(null);
                          console.log("pr- No family member selected");
                        }
                      }}
                      getOptionLabel={(option) =>
                        option
                          ? `(${option.memberPatientId}) ${option.firstName} ${
                              option.lastName
                            } (${
                              relationsList.find(
                                (e) => e.code === option.relation
                              ).value
                            })`
                          : ""
                      }
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const memberPatientId =
                            option.memberPatientId?.toString() || "";
                          const firstName =
                            option.firstName?.toLowerCase() || "";
                          const lastName = option.lastName?.toLowerCase() || "";
                          const relationValue =
                            relationsList
                              .find((e) => e.code === option.relation)
                              ?.value?.toLowerCase() || "";

                          return (
                            memberPatientId.includes(searchText) ||
                            firstName.includes(searchText) ||
                            lastName.includes(searchText) ||
                            relationValue.includes(searchText)
                          );
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Choose date`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        style={{ width: "100%" }}
                        value={dateSelected}
                        onChange={(date) => {
                          setDateSelected(date);
                        }}
                        autoOk={true}
                        className="navigo-module-appointment-datepicker"
                        format="DD MMM YYYY"
                        placeholder="DD MMM YYYY"
                        keyboardIcon={<FontIcon>calendar_month</FontIcon>}
                        variant="inline"
                        minDate={
                          selectServiceProviderCheckbox
                            ? undefined
                            : serviceProviderSelected?.serviceModes[0]
                                .availability.config.minBookingDays ===
                              undefined
                            ? moment()
                            : moment().add(
                                serviceProviderSelected.serviceModes[0]
                                  .availability.config.minBookingDays,
                                "days"
                              )
                        }
                        maxDate={
                          selectServiceProviderCheckbox
                            ? undefined
                            : serviceProviderSelected?.serviceModes[0]
                                .availability.config.maxAdvBookingDays ===
                              undefined
                            ? moment()
                            : moment().add(
                                serviceProviderSelected.serviceModes[0]
                                  .availability.config.maxAdvBookingDays,
                                "days"
                              )
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Choose timeslot`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-slot-selection"
                      value={slotSelected}
                      options={slotList}
                      onChange={(e, v) => {
                        if (v) {
                          setSlotSelected(v);
                          console.log("pr- slot selected :", v);
                        } else {
                          setSlotSelected(null);
                          console.log("pr- No slot selected");
                        }
                      }}
                      getOptionLabel={(option) =>
                        option ? intToTime(option.value) : ""
                      }
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Choose timeslot"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <Checkbox
                      id="mp-appointment-checkbox-2"
                      name="mp-appointment-checkbox-2"
                      style={{ marginLeft: -13, marginTop: 3 }}
                      // disabled={mode == 2}
                      // color={mode == 2 ? "default" : "secondary"}
                      checked={patientContactNumberCheckbox}
                      onChange={() => {
                        if (patientContactNumberCheckbox) {
                          setPatientContactNumberCheckbox(false);
                          setPatientContactNumber(patientDetails.phoneno);
                        } else {
                          setPatientContactNumberCheckbox(true);
                        }
                      }}
                    />
                  </div>
                  <div className="navigo-module-label-checkbox">
                    {`Patient contact number`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                </div>

                <div
                  className="navigo-module-checkbox-field-container"
                  style={{ marginTop: "-16px" }}
                >
                  <div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "40px", marginRight: "20px" }}>
                        <TextField
                          value={
                            patientDetails
                              ? `+${patientDetails.countryCode}`
                              : ""
                          }
                          lineDirection="left"
                          disabled
                        />
                      </div>
                      <div style={{ flex: "1" }}>
                        <TextField
                          value={
                            patientContactNumber != null
                              ? `${patientContactNumber}`
                              : ""
                          }
                          lineDirection="left"
                          type="text"
                          disabled={!patientContactNumberCheckbox}
                          onChange={(e) => {
                            let value = e.replace(/\D/g, "");

                            if (value.length <= 10) {
                              setPatientContactNumber(
                                value === "" ? "" : Number(value)
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Reason for consultation`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-reason-for-consultation-selection"
                      options={reasonList}
                      value={reasonSelected}
                      onChange={(e, v) => {
                        if (v) {
                          setReasonSelected(v);
                          console.log("pr- reason for cons selected :", v);
                        } else {
                          setReasonSelected(null);
                          console.log("pr- No reason for cons  selected");
                        }
                      }}
                      getOptionLabel={(option) =>
                        option && option.isActive ? option.name : ""
                      }
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const reason = option.name?.toLowerCase() || "";

                          return reason.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Describe the reason (mandatory only if "other" is selected in Reason for consultation field)`}
                  </div>
                  <div>
                    <TextField
                      value={reasonDesc}
                      lineDirection="left"
                      placeholder="Type here"
                      onChange={(e) => {
                        setReasonDesc(e);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ) : serviceTypeSelected != null &&
          serviceTypeSelected.serviceTypeId == 2 ? (
          <div>
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Select service code`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-service-code-selection"
                      options={serviceListToShow}
                      value={
                        serviceCodeNameSelected == 2 ? null : serviceSelected
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (serviceSelected != null) {
                            resetValues(5);
                          }
                          setServiceSelected(v);
                          setServiceCodeNameSelected(1);
                          console.log("pr- Service selected :", v);
                        } else {
                          setServiceSelected(null);
                          setServiceCodeNameSelected(0);
                          resetValues(5);
                        }
                      }}
                      getOptionLabel={(option) => (option ? option.name : "")}
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const name = option.name?.toLowerCase() || "";

                          return name.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div
                  className="navigo-module-label"
                  style={{
                    height: "20px",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  Or
                </div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Select service name`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-service-name-selection"
                      options={serviceListToShow}
                      value={
                        serviceCodeNameSelected == 1 ? null : serviceSelected
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (serviceSelected != null) {
                            resetValues(5);
                          }

                          setServiceSelected(v);
                          setServiceCodeNameSelected(2);
                          console.log("pr- Service selected :", v);
                        } else {
                          setServiceSelected(null);
                          setServiceCodeNameSelected(0);
                          resetValues(5);
                        }
                      }}
                      getOptionLabel={(option) =>
                        option ? option.serviceName : ""
                      }
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const serviceName =
                            option.serviceName?.toLowerCase() || "";

                          return serviceName.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Select service mode`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-service-mode-selection"
                      options={serviceModeList}
                      value={serviceModeSelected}
                      onChange={(e, v) => {
                        if (v) {
                          if (serviceModeSelected != null) {
                            resetValues(6);
                          }
                          setServiceModeSelected(v);
                          console.log("pr- Service mode selected :", v);
                        } else {
                          setServiceModeSelected(null);
                          console.log("pr- No Service mode  selected");
                          resetValues(6);
                        }
                      }}
                      getOptionLabel={(option) => (option ? option : "")}
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const serviceMode = option.toLowerCase() || "";

                          return serviceMode.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Select state`}
                    {/* <label className="navigo-module-label-red"> *</label> */}
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-state-selection"
                      options={stateList}
                      value={stateSelected}
                      onChange={(e, v) => {
                        if (v) {
                          if (stateSelected != null) {
                            resetValues(8);
                            setCityList([]);
                            setCitySelected(null);
                            setLocalityList([]);
                            setLocalitySelected(null);
                          }
                          getSPByLoc(v, null, null);
                          setStateSelected(v);
                          console.log("pr- state selected :", v);
                        } else {
                          setStateSelected(null);
                          console.log("pr- No state  selected");
                          resetValues(8);
                          setCityList([]);
                          setCitySelected(null);
                          setLocalityList([]);
                          setLocalitySelected(null);
                          getProviderOfPlanService(serviceModeSelected);
                          setServiceProviderLocationFilterArr([]);
                        }
                      }}
                      getOptionLabel={(option) => (option ? option : "")}
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const state = option.toLowerCase() || "";

                          return state.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Select city`}
                    {/* <label className="navigo-module-label-red"> *</label> */}
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-city-selection"
                      options={cityList}
                      value={citySelected}
                      onChange={(e, v) => {
                        if (v) {
                          if (citySelected != null) {
                            resetValues(8);

                            setLocalityList([]);
                            setLocalitySelected(null);
                          }
                          getSPByLoc(stateSelected, v, null);
                          setCitySelected(v);
                          console.log("pr- city selected :", v);
                        } else {
                          setCitySelected(null);
                          console.log("pr- No city  selected");
                          resetValues(8);
                          getSPByLoc(stateSelected, null, null);
                          setLocalityList([]);
                          setLocalitySelected(null);
                        }
                      }}
                      getOptionLabel={(option) => (option ? option : "")}
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const city = option.toLowerCase() || "";

                          return city.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">{`Select locality`}</div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-locality-selection"
                      options={localityList}
                      value={localitySelected}
                      onChange={(e, v) => {
                        if (v) {
                          if (localitySelected != null) {
                            resetValues(8);
                          }
                          getSPByLoc(stateSelected, citySelected, v);
                          setLocalitySelected(v);
                          console.log("pr- locality selected :", v);
                        } else {
                          setLocalitySelected(null);
                          console.log("pr- No locality  selected");
                          resetValues(8);
                          getSPByLoc(stateSelected, citySelected, null);
                        }
                      }}
                      getOptionLabel={(option) => (option ? option : "")}
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const locality = option.toLowerCase() || "";

                          return locality.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <Checkbox
                      id="mp-appointment-checkbox-3"
                      name="mp-appointment-checkbox-3"
                      style={{ marginLeft: -13, marginTop: 3 }}
                      disabled={!isAutoSPAllowed(serviceModeSelected)}
                      // color={mode == 2 ? "default" : "secondary"}
                      checked={selectServiceProviderCheckbox}
                      onChange={(value, proxy) => {
                        setDateSelected(null);
                        setSlotSelected(null);
                        setSlotList([]);
                        setServiceProviderSelected(null);
                        setServiceProviderAutoSelected(null);
                        if (selectServiceProviderCheckbox) {
                          setSelectServiceProviderCheckbox(false);
                        } else {
                          setSelectServiceProviderCheckbox(true);
                        }
                      }}
                    />
                  </div>
                  <div className="navigo-module-label-checkbox">
                    {`Select service provider or check mark to select automatically`}{" "}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                </div>

                <div
                  className="navigo-module-checkbox-field-container"
                  style={{ marginTop: "-16px" }}
                >
                  <div>
                    <Autocomplete
                      id="mp-navigo-service-provider-selection"
                      options={serviceProviderListToShow}
                      value={serviceProviderSelected}
                      disabled={selectServiceProviderCheckbox}
                      onChange={(e, v) => {
                        if (v) {
                          if (serviceProviderSelected != null) {
                            resetValues(7);
                          }
                          setServiceProviderSelected(v);
                          console.log("pr- SP selected :", v);
                        } else {
                          setServiceProviderSelected(null);
                          console.log("pr- No SP selected");
                          resetValues(7);
                        }
                      }}
                      getOptionLabel={(option) =>
                        option ? option.serviceProviderName : ""
                      }
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const serviceProviderName =
                            option.serviceProviderName?.toLowerCase() || "";

                          return serviceProviderName.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Booking for`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-booking-for-selection"
                      options={familyMemberList}
                      value={selectedFamilyMember}
                      onChange={(e, v) => {
                        if (v) {
                          setSelectedFamilyMember(v);
                          console.log("pr- family member selected :", v);
                        } else {
                          setSelectedFamilyMember(null);
                          console.log("pr- No family member selected");
                        }
                      }}
                      getOptionLabel={(option) =>
                        option
                          ? `(${option.memberPatientId}) ${option.firstName} ${
                              option.lastName
                            } (${
                              relationsList.find(
                                (e) => e.code === option.relation
                              ).value
                            })`
                          : ""
                      }
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const memberPatientId =
                            option.memberPatientId?.toString() || "";
                          const firstName =
                            option.firstName?.toLowerCase() || "";
                          const lastName = option.lastName?.toLowerCase() || "";
                          const relationValue =
                            relationsList
                              .find((e) => e.code === option.relation)
                              ?.value?.toLowerCase() || "";

                          return (
                            memberPatientId.includes(searchText) ||
                            firstName.includes(searchText) ||
                            lastName.includes(searchText) ||
                            relationValue.includes(searchText)
                          );
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Select address`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-address-selection"
                      options={addressList}
                      value={addressSelected}
                      onOpen={() => {
                        getPatientAddress();
                      }}
                      onChange={(e, v) => {
                        if (v) {
                          setAddressSelected(v);
                          console.log("pr- address selected :", v);
                        } else {
                          setAddressSelected(null);
                          console.log("pr- No address selected");
                        }
                      }}
                      getOptionLabel={(option) =>
                        option
                          ? `${option.line1}, ${option.line2}, ${
                              option.landmark && option.landmark != ""
                                ? `${option.landmark}, `
                                : ""
                            }${option.city}, ${option.district}, ${
                              option.state
                            } - ${option.pincode}`
                          : ""
                      }
                      filterOptions={(options, { inputValue }) => {
                        const searchText = inputValue.toLowerCase();

                        return options.filter((option) => {
                          const addressString = [
                            option.line1,
                            option.line2,
                            option.landmark,
                            option.city,
                            option.district,
                            option.state,
                            option.pincode,
                          ]
                            .filter(Boolean)
                            .join(", ")
                            .toLowerCase();

                          return addressString.includes(searchText);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Select"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <a
                  href={`/patients/${patientDetails?.id}/new-bridge?address=true`}
                  target="_blank"
                  className="navigo-module-add-more"
                  style={{ height: "20px" }}
                >
                  + Add new
                </a>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Choose date`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        style={{ width: "100%" }}
                        value={dateSelected}
                        onChange={(date) => {
                          setDateSelected(date);
                        }}
                        autoOk={true}
                        className="navigo-module-appointment-datepicker"
                        format="DD MMM YYYY"
                        placeholder="DD MMM YYYY"
                        keyboardIcon={<FontIcon>calendar_month</FontIcon>}
                        variant="inline"
                        minDate={
                          selectServiceProviderCheckbox
                            ? undefined
                            : serviceProviderSelected?.serviceModes[0]
                                .availability.config.minBookingDays ===
                              undefined
                            ? moment()
                            : moment().add(
                                serviceProviderSelected.serviceModes[0]
                                  .availability.config.minBookingDays,
                                "days"
                              )
                        }
                        maxDate={
                          selectServiceProviderCheckbox
                            ? undefined
                            : serviceProviderSelected?.serviceModes[0]
                                .availability.config.maxAdvBookingDays ===
                              undefined
                            ? moment()
                            : moment().add(
                                serviceProviderSelected.serviceModes[0]
                                  .availability.config.maxAdvBookingDays,
                                "days"
                              )
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Choose timeslot`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <Autocomplete
                      id="mp-navigo-slot-selection"
                      value={slotSelected}
                      options={slotList}
                      onChange={(e, v) => {
                        if (v) {
                          setSlotSelected(v);
                          console.log("pr- slot selected :", v);
                        } else {
                          setSlotSelected(null);
                          console.log("pr- No slot selected");
                        }
                      }}
                      getOptionLabel={(option) =>
                        option ? intToTime(option.value) : ""
                      }
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          placeholder="Choose timeslot"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "8px",
                            },
                            classes: {
                              underline: "custom-underline",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CreateAccount;
