import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, TextField, Checkbox } from "react-md";
import "../styles/createAccountStyles.css";
import FontIcon from "react-md/lib/FontIcons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AssignUtils from "../services/AssignUtils";
import EventService from "../../../../../service/event/EventService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GlobalSkeletonLoader from "../../../../../components/common/GlobalSkeletonLoader";
import NoRecordsIcon from "../../../../../assets/icons/no-records.png";

export const AssignedAccountsOnPid = (props) => {
  const history = useHistory();
  const [pid, setPid] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [accountsOnPid, setAccountsOnPid] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const assignUtils = new AssignUtils();

  useEffect(() => {
    const path = history.location.pathname;
    const match = path.match(/\/assigned-accounts\/(\d+)/);

    const id = match ? match[1] : null;
    console.log("pr- id=", id);
    if (id != null) {
      setPid(id);
      getPatientDetails(id);
      fetchAccountsOnPatientId(id);
    }
  }, []);

  const getPatientDetails = (id) => {
    const onResponse = (res) => {
      setPatientDetails(res);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };

    assignUtils.fetchPatientId(id, onResponse, onError);
  };
  const fetchAccountsOnPatientId = (id) => {
    setLoadingTable(true);
    const onResponse = (res) => {
      console.log("pr- got accts=", res);

      setAccountsOnPid(
        res.patientAccounts.length == 0 ? [] : res.patientAccounts
      );
      setLoadingTable(false);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
      setLoadingTable(false);
    };

    assignUtils.fetchAccountsOnPatientId(id, onResponse, onError);
  };

  return (
    <div className="account-module-page-padding">
      {patientDetails == null ? (
        <></>
      ) : (
        <>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="account-module-view">
                <div className="account-module-heading-with-back-button">
                  <div
                    className="account-module-back-btn"
                    onClick={() => {
                      history.go(-1);
                    }}
                  >
                    <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
                  </div>
                  <h2>{`Assigned accounts`}</h2>
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <div className="account-module-grey-heading">Patient details</div>
          <br />
          <div className="account-module-create-service-container">
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="account-module-create-service-field-container">
                  <div className="account-module-label">{`Patient ID`}</div>
                  <div>
                    <TextField
                      value={patientDetails?.id}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="account-module-create-service-field-container">
                  <div className="account-module-label">{`Patient name`}</div>
                  <div>
                    <TextField
                      value={`${patientDetails?.firstName} ${patientDetails?.lastName}`}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h3>Assigned accounts</h3>
            </div>
            <div>
              <Button
                raised
                className={"account-module-button-save-enabled"}
                onClick={() => {
                  history.push(`/assign-account/${pid}`);
                }}
              >
                {"Add account"}
              </Button>
            </div>
          </div>
          <br />
          <div className="account-module-table-container">
            <div className="table">
              <div className="table-row1 table-header1">
                <div className="table-cell" style={{ width: "15%" }}>
                  Account ID
                </div>
                <div className="table-cell" style={{ width: "25%" }}>
                  Account name
                </div>
                <div className="table-cell" style={{ width: "20%" }}>
                  Account type
                </div>
                <div className="table-cell" style={{ width: "20%" }}>
                  KAM
                </div>
                <div className="table-cell" style={{ width: "20%" }}>
                  Plan status
                </div>
              </div>
              {loadingTable ? (
                <GlobalSkeletonLoader />
              ) : accountsOnPid.length == 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "20px" }}>
                      No records to show
                    </div>
                    <img src={NoRecordsIcon} width={50} height={62} />
                  </div>
                </div>
              ) : (
                accountsOnPid.map((acc) => {
                  return (
                    <div className="table-row1">
                      <div className="table-cell" style={{ width: "15%" }}>
                        {acc.accountCode}
                      </div>
                      <div className="table-cell" style={{ width: "25%" }}>
                        {acc.accountName}
                      </div>
                      <div className="table-cell" style={{ width: "20%" }}>
                        {acc.mode}
                      </div>
                      <div className="table-cell" style={{ width: "20%" }}>
                        {acc.managerName}
                      </div>
                      <div className="table-cell" style={{ width: "20%" }}>
                        {acc.patientStatus == false ? "Inactive" : "Active"}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AssignedAccountsOnPid;
