import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, TextField } from "react-md";
import UploadFileIcon from "../../../../../assets/icons/upload-file-icon.png";
import "../styles/createPlan.css";
import FontIcon from "react-md/lib/FontIcons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EventService from "../../../../../service/event/EventService";
import PlansUtils from "../services/PlansUtils";
import { Accordion, AccordionDetails } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import AuthenticationService from "../../../../../service/auth/AuthenticationService";
import ExpandableTableAssignedServices from "../helper/expandableTableAssignedServices";

export const CreatePlan = (props) => {
  const [mode, setMode] = useState(1);
  const [accountDetails, setAccountDetails] = useState(null);
  const [planName, setPlanName] = useState("");
  const [planPrice, setPlanPrice] = useState("");
  const [planStatus, setPlanStatus] = useState("");
  const [planDuration, setPlanDuration] = useState("");
  const [planDateFrom, setPlanDateFrom] = useState(null);
  const [planDdateTo, setPlanDdateTo] = useState(null);
  const [planDefaultTherapy, setPlanDefaultTherapy] = useState(null);
  const [planMobileImage, setPlanMobileImage] = useState(null);
  const [planMobilePreview, setPlanMobilePreview] = useState("");
  const [planDesktopImage, setPlanDesktopImage] = useState(null);
  const [planDesktopPreview, setPlanDesktopPreview] = useState("");
  const [planShortDescription, setPlanShortDescription] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [planLongDescription, setPlanLongDescription] = useState("");
  const [planAutoActivate, setPlanAutoActivate] = useState(false);
  const [planEmailDomains, setPlanEmailDomains] = useState([]);
  const [domainTyped, setDomainTyped] = useState("");
  const [isWalletIncluded, setIsWalletIncluded] = useState(false);
  const [isUnlimitedWallet, setIsUnlimitedWallet] = useState(false);
  const [walletLimit, setWalletLimit] = useState("");
  const [canWalletBeUsedByFamily, setWalletCanBeUsedByFamily] = useState(false);
  const [familyRelationsArr, setFamilyRelationsArr] = useState([]);
  const [allRelations, setAllRelations] = useState([]);
  const [selectAllFamilyForWallet, setSelectAllFamilyForWallet] =
    useState(false);
  const [allowedFamilyRelationsForWallet, setAllowedFamilyRelationsForWallet] =
    useState([]);
  const [isReimbursementIncluded, setIsReimbursementIncluded] = useState(false);
  const [canReimbursementBeUsedByFamily, setReimbursementCanBeUsedByFamily] =
    useState(false);
  const [selectAllFamilyForReimbursement, setSelectAllFamilyForReimbursement] =
    useState(false);
  const [
    allowedFamilyRelationsForReimbursement,
    setAllowedFamilyRelationsForReimbursement,
  ] = useState([]);
  const [isWelcomeMsgIncluded, setIsWelcomeMsgIncluded] = useState(false);
  const [customMessage, setCustomMessage] = useState("");
  const [isCTAButtonEnabled, setIsCTAButtonEnabled] = useState(false);
  const [ctaButton, setCtaButton] = useState("");
  const [ctaText, setCtaText] = useState("");
  const [customCtaText, setCustomCtaText] = useState("");
  const [ctaLink, setCtaLink] = useState("");
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [fetchedPlan, setFetchedPlan] = useState("");
  const [expandableTile, setExpandableTile] = useState(<></>);
  const [assignedServicesArray, setAssignedServicesArray] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(2);
  const [totalCount, setTotalCount] = useState(10);
  const [componentMounted, setComponentMounted] = useState(false);

  const plansUtils = new PlansUtils();
  const authenticationService = new AuthenticationService();

  const planMobileImageInputRef = useRef(null);
  const planDesktopImageInputRef = useRef(null);

  useEffect(() => {
    console.log("pr- pid=", props?.data?.planId);

    getFamilyRelations();
    if (props?.data?.mode == "view" || props?.data?.mode == "assign-service") {
      getPlan(props?.data?.planId);
      setMode(2);
    } else if (props?.data?.mode == "update") {
      getPlan(props?.data?.planId);
      setMode(3);
    } else if (props?.data?.mode == "create") {
      getAccount(props?.data?.accountId);
      setMode(1);
    }

    setComponentMounted(true);
  }, []);

  useEffect(() => {
    console.log("ni -> fetchedPlan ->", fetchedPlan);
    if (fetchedPlan?.id && props?.data?.mode == "assign-service") {
      getAssignedServicesToPlanByFilter(fetchedPlan?.id);
    }
  }, [fetchedPlan, pageNum, pageSize]);

  useEffect(() => {
    console.log("ni -> assignedServicesToPlan ->", assignedServicesArray);
    if (componentMounted && props?.data?.mode == "assign-service") {
      setExpandableTile((prev) => (
        <ExpandableTableAssignedServices
          {...{
            data: {
              planData: fetchedPlan,
              assignedServices: assignedServicesArray,
            },
            setView: props?.setView,
            goToPrevPage: navigateToPrevPage,
            goToNextPage: navigateToNextPage,
            fetchAssignedServicesByFilter: getAssignedServicesToPlanByFilter,
            totalCount: totalCount,
            pageNum: pageNum,
            pageSize: pageSize,
          }}
        />
      ));
    }
  }, [assignedServicesArray]);

  useEffect(() => {
    if (
      areArraysEqual(allRelations, allowedFamilyRelationsForWallet) &&
      allowedFamilyRelationsForWallet.length != 0
    ) {
      setSelectAllFamilyForWallet(true);
    } else {
      setSelectAllFamilyForWallet(false);
    }
  }, [allowedFamilyRelationsForWallet, allRelations]);
  useEffect(() => {
    if (
      areArraysEqual(allRelations, allowedFamilyRelationsForReimbursement) &&
      allowedFamilyRelationsForReimbursement.length != 0
    ) {
      setSelectAllFamilyForReimbursement(true);
    } else {
      setSelectAllFamilyForReimbursement(false);
    }
  }, [allowedFamilyRelationsForReimbursement, allRelations]);

  useEffect(() => {
    if (isWalletIncluded == false) {
      setIsUnlimitedWallet(false);
      setWalletLimit("");
      setWalletCanBeUsedByFamily(false);
      setSelectAllFamilyForWallet(false);
      setAllowedFamilyRelationsForWallet([]);
    }
  }, [isWalletIncluded]);

  useEffect(() => {
    if (isReimbursementIncluded == false) {
      setReimbursementCanBeUsedByFamily(false);
      setSelectAllFamilyForReimbursement(false);
      setAllowedFamilyRelationsForReimbursement([]);
    }
  }, [isReimbursementIncluded]);

  useEffect(() => {
    if (isWelcomeMsgIncluded == false) {
      setCustomMessage("");
      setIsCTAButtonEnabled(false);
      setCtaText("");
      setCustomCtaText("");
      setCtaLink("");
      setEditorState2(EditorState.createEmpty());
    }
  }, [isWelcomeMsgIncluded]);

  useEffect(() => {
    if (planMobileImage != null) {
      uploadImage(planMobileImage)
        .then((e) => {
          setPlanMobilePreview(e);
        })
        .catch((err) => {
          EventService.emit("showError", {
            message: `Error while uploading the plan mobile image: ${err}`,
          });
        });
    }
  }, [planMobileImage]);
  useEffect(() => {
    if (planDesktopImage != null) {
      uploadImage(planDesktopImage)
        .then((e) => {
          setPlanDesktopPreview(e);
        })
        .catch((err) => {
          EventService.emit("showError", {
            message: `Error while uploading the plan desktop image: ${err}`,
          });
        });
    }
  }, [planDesktopImage]);

  const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", file, file.name);

      plansUtils.uploadImg(
        data,
        (res) => {
          resolve(res.publicUrl);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const navigateToNextPage = () => {
    if (pageNum * pageSize < totalCount) {
      setPageNum((prevPageNum) => prevPageNum + 1);
    }
  };
  const navigateToPrevPage = () => {
    if (pageNum > 1) {
      setPageNum((prevPageNum) => prevPageNum - 1);
    }
  };

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    return [...arr1].sort().toString() === [...arr2].sort().toString();
  };

  let PLAN_STATUS_OPTIONS = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Inactive",
      value: 2,
    },
  ];
  let THERAPY_OPTIONS = [
    {
      label: "Diabetes",
      value: 1,
    },
    {
      label: "Heart health",
      value: 2,
    },
    // {
    //   label: "PCOD",
    //   value: "PCOD",
    // },
    {
      label: "Cancer (other)",
      value: 5,
    },
    // {
    //   label: "Pregnancy",
    //   value: 9,
    // },
    {
      label: "New born care",
      value: 10,
    },
    {
      label: "Other",
      value: 6,
    },
    {
      label: "Ovarian cancer",
      value: 12,
    },
    {
      label: "Prostate cancer",
      value: 13,
    },
    {
      label: "Breast cancer",
      value: 14,
    },
    {
      label: "Head and neck cancer",
      value: 15,
    },
  ];
  let CTA_BUTTON_OPTIONS = [
    {
      label: "Open Camera",
      value: "Open Camera",
    },
    {
      label: "Open Upload Image",
      value: "Open Upload Image",
    },
    {
      label: "Open Meditation",
      value: "Open Meditation",
    },
    {
      label: "Open Upload Documents",
      value: "Open Upload Documents",
    },
    {
      label: "Open Vital Chart",
      value: "Open Vital Chart",
    },
    {
      label: "Open Share Vitals",
      value: "Open Share Vitals",
    },
    {
      label: "Open Routine Reporting",
      value: "Open Routine Reporting",
    },
    {
      label: "Open Health Plan",
      value: "Open Health Plan",
    },
    {
      label: "Open Profile",
      value: "Open Profile",
    },
    {
      label: "Open Upload Option",
      value: "Open Upload Option",
    },
    {
      label: "Open Home",
      value: "Open Home",
    },
    {
      label: "Get Offer",
      value: "Get Offer",
    },
    {
      label: "Custom CTA text",
      value: "Custom CTA text",
    },
  ];

  const exitPage = () => {
    setMode(1);
    setAccountDetails(null);
    setPlanName("");
    setPlanPrice("");
    setPlanStatus("");
    setPlanDuration("");
    setPlanDateFrom(null);
    setPlanDdateTo(null);
    setPlanDefaultTherapy(null);
    setPlanMobileImage(null);
    setPlanMobilePreview("");
    setPlanDesktopImage(null);
    setPlanDesktopPreview("");
    setPlanShortDescription("");
    setEditorState(EditorState.createEmpty());
    setPlanLongDescription("");
    setPlanAutoActivate(false);
    setPlanEmailDomains([]);
    setDomainTyped("");
    setIsWalletIncluded(false);
    setIsUnlimitedWallet(false);
    setWalletLimit("");
    setWalletCanBeUsedByFamily(false);
    setFamilyRelationsArr([]);
    setAllRelations([]);
    setSelectAllFamilyForWallet(false);
    setAllowedFamilyRelationsForWallet([]);
    setIsReimbursementIncluded(false);
    setReimbursementCanBeUsedByFamily(false);
    setSelectAllFamilyForReimbursement(false);

    setAllowedFamilyRelationsForReimbursement([]);
    setIsWelcomeMsgIncluded(false);
    setCustomMessage("");
    setIsCTAButtonEnabled(false);
    setCtaText("");
    setCtaButton("");
    setCustomCtaText("");
    setCtaLink("");
    setEditorState2(EditorState.createEmpty());
    setLoading(false);
    props.setView(2, {
      mode: "assign",
      accountId:
        mode == 2 || mode == 3 ? accountDetails.id : props?.data?.accountId,
    });
  };
  const getAccount = (id) => {
    const onResponse = (res) => {
      console.log("pr- acc=", res);
      setAccountDetails(res);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    plansUtils.getAccountsById(id, onResponse, onError);
  };
  const getAccountByAccountCode = (code) => {
    let body = {
      pageNum: 1,
      pageSize: 1,
      accountCode: code,
    };
    const onResponse = (res) => {
      console.log("pr- acc code res=", res);
      setAccountDetails(res.accounts[0]);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    plansUtils.getAccountsByFilter(body, onResponse, onError);
  };

  const getAssignedServicesToPlanByFilter = (id, filters) => {
    const onResponse = (res) => {
      console.log("pr- acc=", res);
      setTotalCount((prev) => res?.totalCount ?? 0);
      setAssignedServicesArray(res?.services ?? []);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };

    plansUtils.getAssignedServicesToPlanByFilter(
      {
        pageNum: pageNum,
        pageSize: pageSize,
        planIds: [id],
        // "serviceTypeIds":["67ac2bf47a2de919dc103605"]
      },
      onResponse,
      onError
    );
  };

  const getPlan = (id) => {
    let body = {
      pageNum: 1,
      pageSize: 1,
      planIds: [id],
    };

    const onResponse = (res) => {
      console.log("pr- plan=", res);
      let plan = res.plans[0];
      let accountCode = plan.accountCode;
      console.log("pr- accplan=", plan);
      getAccountByAccountCode(accountCode);
      setData(plan);
      setFetchedPlan(plan);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    console.log("pr- body=", body);

    plansUtils.getPlanByFilter(body, onResponse, onError);
  };
  const setData = (plan) => {
    setPlanName(plan.basicDetails.name);
    setPlanPrice(plan.basicDetails.price);
    setPlanStatus(plan.basicDetails.status);
    setPlanDuration(
      plan.basicDetails.duration ? plan.basicDetails.duration : ""
    );
    setPlanDateFrom(
      plan.basicDetails.startDate == "0001-01-01T00:00:00Z"
        ? null
        : plan.basicDetails.startDate
    );
    setPlanDdateTo(
      plan.basicDetails.endDate == "0001-01-01T00:00:00Z"
        ? null
        : plan.basicDetails.endDate
    );
    setPlanDefaultTherapy(plan.basicDetails?.defaultTherapy);

    setPlanMobilePreview(
      plan.basicDetails.mobileCover ? plan.basicDetails.mobileCover : ""
    );

    setPlanDesktopPreview(
      plan.basicDetails.desktopCover ? plan.basicDetails.desktopCover : ""
    );
    setPlanShortDescription(
      plan.basicDetails.shortDescription
        ? plan.basicDetails.shortDescription
        : ""
    );
    let contentBlock = htmlToDraft(plan?.basicDetails?.longDescription);
    if (contentBlock) {
      let contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      let editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
    setPlanLongDescription(
      plan.basicDetails.longDescription ? plan.basicDetails.longDescription : ""
    );
    setPlanAutoActivate(plan.basicDetails.autoActivate);
    setPlanEmailDomains(
      plan.basicDetails.emailDomains.length != 0
        ? plan.basicDetails.emailDomains
        : []
    );
    setIsWalletIncluded(plan.walletDetails.isWalletIncluded);
    setIsUnlimitedWallet(plan.walletDetails.isUnlimitedWallet);
    setWalletLimit(
      plan.walletDetails.walletLimit != 0 ? plan.walletDetails.walletLimit : ""
    );
    setWalletCanBeUsedByFamily(plan.walletDetails.canBeUsedByFamily);
    setAllowedFamilyRelationsForWallet(
      plan.walletDetails.allowedFamilyRelations.length != 0
        ? plan.walletDetails.allowedFamilyRelations
        : []
    );
    setIsReimbursementIncluded(
      plan.reimbursementDetails.isReimbursementIncluded
    );
    setReimbursementCanBeUsedByFamily(
      plan.reimbursementDetails.canReimburseUsedByFamily
    );

    setAllowedFamilyRelationsForReimbursement(
      plan.reimbursementDetails.allowedFamilyRelations.length != 0
        ? plan.reimbursementDetails.allowedFamilyRelations
        : []
    );

    setIsWelcomeMsgIncluded(plan.planChatDetails.isCustomMessage);
    setCustomMessage(
      plan.planChatDetails.customMessage
        ? plan.planChatDetails.customMessage
        : ""
    );
    setIsCTAButtonEnabled(plan.planChatDetails.isCTAButton);
    setCtaButton(plan.planChatDetails.ctaButton);
    setCustomCtaText(plan.planChatDetails.ctaText);
    setCtaLink(plan.planChatDetails.ctaLink);
    let contentBlock2 = htmlToDraft(
      plan.planChatDetails.customMessage
        ? plan.planChatDetails.customMessage
        : ""
    );
    if (contentBlock2) {
      let contentState = ContentState.createFromBlockArray(
        contentBlock2.contentBlocks
      );
      let editorState2 = EditorState.createWithContent(contentState);
      setEditorState2(editorState2);
    }
    setLoading(false);
  };

  const getFamilyRelations = () => {
    const onResponse = (res) => {
      console.log("pr- rel=", res);
      let allRelations = res.map((e) => {
        return e.code;
      });
      setAllRelations(allRelations);
      setFamilyRelationsArr(res);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    plansUtils.getFamilyRelations(onResponse, onError);
  };
  const renderWhitelistedDomains = () => {
    if (accountDetails?.whitelistedDomains) {
      if (accountDetails.whitelistedDomains.length == 0) {
        return <></>;
      } else {
        return (
          <div className="account-module-domains-container">
            {accountDetails.whitelistedDomains.map((e, index) => {
              return (
                <div
                  className="account-module-domains-box"
                  style={{ marginTop: "10px" }}
                >
                  <div className="account-module-font">{`@${e}`}</div>
                </div>
              );
            })}
          </div>
        );
      }
    }
  };

  const planMobileUploadClick = () => {
    if (planMobileImageInputRef.current) {
      planMobileImageInputRef.current.click();
    }
  };

  const planMobileImageSelected = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("No file selected or file picker was canceled");
      return;
    }

    const maxSize = 20 * 1024 * 1024; // 20MB
    if (file.size > maxSize) {
      EventService.emit("showError", {
        message: "File size cannot be more than 20MB",
      });
      return;
    }
    const img = new Image();
    img.onload = function () {
      if (this.width !== 328 || this.height !== 175) {
        EventService.emit("showError", {
          message: "Image dimensions must be 328x175 pixels",
        });
        return;
      }
      setPlanMobileImage(file);
    };
    img.src = URL.createObjectURL(file);
  };

  const planDesktopUploadClick = () => {
    if (planDesktopImageInputRef.current) {
      planDesktopImageInputRef.current.click();
    }
  };

  const planDesktopImageSelected = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("No file selected or file picker was canceled");
      return;
    }

    const maxSize = 20 * 1024 * 1024; // 20MB
    if (file.size > maxSize) {
      EventService.emit("showError", {
        message: "File size cannot be more than 20MB",
      });
      return;
    }
    const img = new Image();
    img.onload = function () {
      if (this.width !== 1080 || this.height !== 1080) {
        EventService.emit("showError", {
          message: "Image dimensions must be 1080x1080 pixels",
        });
        return;
      }
      setPlanDesktopImage(file);
    };
    img.src = URL.createObjectURL(file);
  };
  const _onEditorStateChange = (editorState) => {
    let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setPlanLongDescription(body);
    setEditorState(editorState);
  };
  const _onEditorStateChange2 = (editorState2) => {
    let body = draftToHtml(convertToRaw(editorState2.getCurrentContent()));
    setCustomMessage(body);
    setEditorState2(editorState2);
  };

  const handleAddPlanEmailDomainIcon = () => {
    if (domainTyped.trim() != "") {
      setPlanEmailDomains([...planEmailDomains, domainTyped]);
      setDomainTyped("");
    }
  };
  const renderPlanEmailDomains = () => {
    if (planEmailDomains.length == 0) {
      return <></>;
    } else {
      return (
        <div className="mp-plan-domains-container">
          {planEmailDomains.map((e, index) => {
            return (
              <div className="mp-plan-domains-box">
                <div className="mp-plan-font">{`@${e}`}</div>
                <div>
                  <FontIcon
                    style={{
                      color: "#6A5EF5",
                      fontSize: "17px",
                      fontWeight: 700,
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (mode != 2) {
                        let removedDomainObj = planEmailDomains.filter(
                          (_, i) => i !== index
                        );
                        setPlanEmailDomains(removedDomainObj);
                      }
                    }}
                  >
                    close
                  </FontIcon>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const handleSave = () => {
    if (isDataValid()) {
      console.log("pr- data is valid, ready for obj create");
      let planObj = {
        accountId: mode == 3 ? accountDetails.id : props?.data?.accountId,
        accountCode: accountDetails?.accountCode,
        tenantId: authenticationService.getTenantId(),
        basicDetails: {
          name: planName,
          price: planPrice == "" ? 0 : planPrice,
          status: planStatus,
          mobileCover: planMobilePreview,
          desktopCover: planDesktopPreview,
          shortDescription: planShortDescription,
          longDescription: planLongDescription,
          duration: planDuration == "" ? null : planDuration,
          startDate: planDateFrom,
          endDate: planDdateTo,
          defaultTherapy: planDefaultTherapy,
          autoActivate: planAutoActivate,
          emailDomains: planEmailDomains,
        },
        walletDetails: {
          isWalletIncluded: isWalletIncluded,
          isUnlimitedWallet: isUnlimitedWallet,
          walletLimit: walletLimit == "" ? null : walletLimit,
          canBeUsedByFamily: canWalletBeUsedByFamily,
          allowedFamilyRelations: allowedFamilyRelationsForWallet,
        },
        reimbursementDetails: {
          isReimbursementIncluded: isReimbursementIncluded,
          canReimburseUsedByFamily: canReimbursementBeUsedByFamily,
          allowedFamilyRelations: allowedFamilyRelationsForReimbursement,
        },
        planChatDetails: {
          isCustomMessage: isWelcomeMsgIncluded,
          customMessage: customMessage,
          isCTAButton: isCTAButtonEnabled,
          ctaButton: ctaButton,
          ctaText: customCtaText,
          ctaLink: ctaLink,
        },
        createdBy: authenticationService.getUserProfile().employeeProfile?.id,
        updatedBy: authenticationService.getUserProfile().employeeProfile?.id,
      };
      console.log("pr- final obj = ", planObj);
      if (mode == 3) {
        delete planObj.createdBy;
        console.log("pr- going to UP=", planObj);

        updatePlan(planObj);
      } else {
        createPlan(planObj);
      }
    }
  };
  const isDataValid = () => {
    console.log("pr- pss=", planStatus);

    if (planName == "") {
      EventService.emit("showError", {
        message: "Please enter the plan name",
      });
      return false;
    } else if ((planStatus != 1) & (planStatus != 2)) {
      EventService.emit("showError", {
        message: "Please select plan status",
      });
      return false;
    } else if (planMobilePreview == null || planMobilePreview == "") {
      EventService.emit("showError", {
        message: "Please upload plan image mobile view",
      });
      return false;
    } else if (planShortDescription == "") {
      EventService.emit("showError", {
        message: "Please enter plan short description",
      });
      return false;
    } else if (
      planLongDescription.trim() === "" ||
      planLongDescription.trim() === "<p></p>" ||
      planLongDescription.trim() === "<p><br></p>"
    ) {
      EventService.emit("showError", {
        message: "Please enter plan long description",
      });
      return false;
    } else {
      return true;
    }
  };

  let createPlan = (body) => {
    setLoading(true);
    const onResponse = (res) => {
      setLoading(false);
      EventService.emit("showError", {
        message: `Plan created`,
      });
      exitPage();
    };
    const onError = (err) => {
      setLoading(false);
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    plansUtils.createPlan(body, onResponse, onError);
  };
  let updatePlan = (body) => {
    setLoading(true);
    const onResponse = (res) => {
      setLoading(false);
      EventService.emit("showError", {
        message: `Plan updated`,
      });
      exitPage();
    };
    const onError = (err) => {
      setLoading(false);
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    plansUtils.updatePlan(body, fetchedPlan?.id, onResponse, onError);
  };

  return (
    <div className="mp-plan-page-padding">
      {/* {console.log("pr- plan1=", accountDetails)} */}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="mp-plan-view">
            <div className="mp-plan-heading-with-back-button">
              <div
                className="mp-plan-back-btn"
                onClick={() => {
                  exitPage();
                }}
              >
                <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
              </div>
              <h2>
                {props?.data?.mode == "assign-service"
                  ? "Assigned services to plan"
                  : mode == 2
                  ? `View plan`
                  : mode == 3
                  ? `Update plan`
                  : `Add new plan`}
              </h2>
            </div>
            <div className="mp-plan-buttons-container">
              <div className="mp-plan-buttons-right-alignment">
                {mode != 2 && (
                  <Button
                    raised
                    disabled={loading}
                    className={
                      loading
                        ? "mp-plan-button-save-disabled"
                        : "mp-plan-button-save-enabled"
                    }
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    {mode == 3 ? "Update" : "Save"}
                  </Button>
                )}

                <Button
                  raised
                  className="mp-plan-button-cancel"
                  disabled={loading}
                  onClick={() => {
                    exitPage();
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <div className="mp-plan-grey-heading">Account details</div>
      <br />
      <div className="mp-plan-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Account type`}</div>
              <div>
                <TextField
                  value={
                    accountDetails?.accountCode
                      ? accountDetails.accountCode.substring(0, 2) == "01"
                        ? "Insurance"
                        : accountDetails.accountCode.substring(0, 2) == "02"
                        ? "Pharma"
                        : accountDetails.accountCode.substring(0, 2) == "03"
                        ? "Corporate"
                        : accountDetails.accountCode.substring(0, 2) == "04"
                        ? "Zyla"
                        : ""
                      : ""
                  }
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Account ID`}</div>
              <div>
                <TextField
                  value={
                    accountDetails?.accountCode
                      ? accountDetails.accountCode
                      : ""
                  }
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Status`}</div>
              <div>
                <TextField
                  value={
                    accountDetails?.status != undefined
                      ? accountDetails.status == true
                        ? "Active"
                        : "Inactive"
                      : ""
                  }
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Account name`}</div>
              <div>
                <TextField
                  value={
                    accountDetails?.accountName
                      ? accountDetails.accountName
                      : ""
                  }
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Account type`}</div>
              <div>
                <TextField
                  value={accountDetails?.mode ? accountDetails.mode : ""}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Key account manager`}</div>
              <div>
                <TextField
                  value={
                    accountDetails?.managerName
                      ? accountDetails.managerName
                      : ""
                  }
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Whitelist email domain`}</div>
              {renderWhitelistedDomains()}
            </div>
          </Col>
        </Row>
      </div>
      <br />
      <div style={{ margin: "0px -15px 0px -15px" }}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
            style={{ backgroundColor: "#CED4DA" }}
          >
            <div
              style={{
                fontWeight: "900",
                fontSize: "16px",
              }}
            >
              Plan
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {props?.data?.mode == "assign-service" && (
                <div>
                  <Row>
                    <Col xs={12} sm={3} md={3} lg={3}>
                      <div
                        className="mp-plan-create-service-field-container"
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="mp-plan-label">{`Plan ID`}</div>
                        <div>
                          <TextField
                            value={fetchedPlan?.id ? fetchedPlan?.id : ""}
                            lineDirection="left"
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              <div style={{ margin: "0px -12px 0px -12px" }}>
                <Accordion
                  defaultExpanded={
                    props?.data?.mode == "assign-service" ? false : true
                  }
                >
                  <AccordionSummary
                    expandIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
                    style={{ border: "1px solid #CED4DA", borderRadius: "5px" }}
                  >
                    <div
                      style={{
                        fontWeight: "900",
                        fontSize: "16px",
                      }}
                    >
                      Basic details
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mp-plan-create-plan-container">
                      <Row>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div className="mp-plan-create-service-field-container">
                            <div className="mp-plan-label">
                              {`Plan name`}
                              <label className="account-module-label-red">
                                *
                              </label>
                            </div>
                            <div>
                              <TextField
                                value={`${planName}`}
                                lineDirection="left"
                                disabled={mode == 2}
                                onChange={(e) => {
                                  setPlanName(e);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={1} md={1} lg={1}>
                          <div style={{ height: "20px" }}></div>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div className="mp-plan-create-service-field-container">
                            <div className="mp-plan-label">{`Plan price (Rs)`}</div>
                            <div>
                              <TextField
                                value={`${planPrice}`}
                                lineDirection="left"
                                type="number"
                                disabled={mode == 2}
                                onChange={(e) => {
                                  setPlanPrice(Number(e));
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={1} md={1} lg={1}>
                          <div style={{ height: "20px" }}></div>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div className="mp-plan-create-service-field-container">
                            <div className="mp-plan-label">
                              {`Status`}
                              <label className="account-module-label-red">
                                {" "}
                                *
                              </label>
                            </div>
                            <div>
                              <SelectField
                                placeholder="Select"
                                className="mp-plan-select-field"
                                menuItems={PLAN_STATUS_OPTIONS}
                                disabled={mode == 2}
                                value={planStatus}
                                onChange={(e) => {
                                  setPlanStatus(e);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div className="mp-plan-create-service-field-container">
                            <div className="mp-plan-label">{`Plan duration (in days)`}</div>
                            <div>
                              <TextField
                                value={`${planDuration}`}
                                lineDirection="left"
                                type="number"
                                disabled={mode == 2}
                                onChange={(e) => {
                                  if (/^\d+$/.test(e) || e === "") {
                                    setPlanDuration(Number(e));
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={1} md={1} lg={1}>
                          <div style={{ height: "20px" }}></div>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div className="mp-plan-create-service-field-container">
                            <div className="mp-plan-label">{`Plan start date`}</div>
                            <div style={{ paddingTop: "6px" }}>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  style={{ width: "100%" }}
                                  value={planDateFrom ? planDateFrom : null}
                                  onChange={(date) => {
                                    setPlanDateFrom(
                                      moment(date)
                                        .utc()
                                        .startOf("day")
                                        .toISOString()
                                    );
                                  }}
                                  autoOk={true}
                                  className="mp-plan-start-date"
                                  format="DD MMM YYYY"
                                  placeholder="Choose a date"
                                  keyboardIcon={
                                    <FontIcon>calendar_month</FontIcon>
                                  }
                                  variant="inline"
                                  // maxDate={addOnDialogMode == 3 ? false : dateTo ? dateTo : false}
                                  disabled={mode == 2}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={1} md={1} lg={1}>
                          <div style={{ height: "20px" }}></div>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div className="mp-plan-create-service-field-container">
                            <div className="mp-plan-label">{`Plan end date`}</div>
                            <div style={{ paddingTop: "6px" }}>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  style={{ width: "100%" }}
                                  value={planDdateTo ? planDdateTo : null}
                                  onChange={(date) => {
                                    setPlanDdateTo(
                                      moment(date)
                                        .utc()
                                        .startOf("day")
                                        .toISOString()
                                    );
                                  }}
                                  autoOk={true}
                                  className="mp-plan-end-date"
                                  format="DD MMM YYYY"
                                  placeholder="Choose a date"
                                  keyboardIcon={
                                    <FontIcon>calendar_month</FontIcon>
                                  }
                                  variant="inline"
                                  // maxDate={addOnDialogMode == 3 ? false : dateTo ? dateTo : false}
                                  disabled={mode == 2}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div className="mp-plan-create-service-field-container">
                            <div className="mp-plan-label">
                              {`Default therapy (if any)`}
                            </div>
                            <div>
                              <SelectField
                                placeholder="Select"
                                className="mp-plan-select-field"
                                menuItems={THERAPY_OPTIONS}
                                disabled={mode == 2}
                                value={planDefaultTherapy}
                                onChange={(e) => {
                                  setPlanDefaultTherapy(e);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div className="mp-plan-create-service-field-container">
                            <div className="mp-plan-label">
                              {`Plan image (Mobile view)`}
                              <label className="account-module-label-red">
                                *
                              </label>
                            </div>
                            <div className="mp-plan-image-requirements">{`(Max file size: 20mb, dimension 328*175px)`}</div>
                            <Button
                              raised
                              disabled={mode == 2}
                              className="mp-plan-button-upload"
                              onClick={() => {
                                planMobileUploadClick();
                              }}
                            >
                              {"Upload"}
                            </Button>
                            <input
                              type="file"
                              accept="image/png, image/jpeg"
                              ref={planMobileImageInputRef}
                              style={{ display: "none" }}
                              onChange={planMobileImageSelected}
                            />
                            <Col xs={12} sm={5} md={4} lg={4}>
                              {planMobilePreview && planMobilePreview != "" && (
                                <>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      border: "1px solid black",
                                      marginTop: "15px",
                                      marginLeft: "-6px",
                                    }}
                                  >
                                    <img
                                      src={planMobilePreview}
                                      alt="Selected Preview"
                                      style={{
                                        width: "200px",
                                        height: "auto",
                                        display: "block",
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </Col>
                          </div>
                        </Col>
                        <Col xs={12} sm={1} md={1} lg={1}>
                          <div style={{ height: "20px" }}></div>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div className="mp-plan-create-service-field-container">
                            <div className="mp-plan-label">
                              {`Plan image (Desktop view)`}
                            </div>
                            <div className="mp-plan-image-requirements">{`(Max file size: 20mb, dimension 1080*1080px)`}</div>
                            <Button
                              raised
                              disabled={mode == 2}
                              className="mp-plan-button-upload"
                              onClick={() => {
                                planDesktopUploadClick();
                              }}
                            >
                              {"Upload"}
                            </Button>
                            <input
                              type="file"
                              accept="image/png, image/jpeg"
                              ref={planDesktopImageInputRef}
                              style={{ display: "none" }}
                              onChange={planDesktopImageSelected}
                            />
                            <Col xs={12} sm={5} md={4} lg={4}>
                              {planDesktopPreview &&
                                planDesktopPreview != "" && (
                                  <>
                                    <div
                                      style={{
                                        display: "inline-block",
                                        border: "1px solid black",
                                        marginTop: "15px",
                                        marginLeft: "-6px",
                                      }}
                                    >
                                      <img
                                        src={planDesktopPreview}
                                        alt="Selected Preview"
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                          display: "block",
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12} sm={11} md={11} lg={11}>
                          <div className="mp-plan-field-container">
                            <div className="mp-plan-label">
                              {`Plan short description (50 characters)`}
                              <label className="mp-plan-label-red"> *</label>
                            </div>
                            <div>
                              <TextField
                                disabled={mode == 2}
                                value={
                                  planShortDescription
                                    ? `${planShortDescription}`
                                    : ""
                                }
                                lineDirection="left"
                                error={
                                  planShortDescription != null
                                    ? planShortDescription >= 50
                                      ? true
                                      : false
                                    : false
                                }
                                errorText="Short description can be of max 50 characters only"
                                onChange={(e) => {
                                  if (e.length <= 50) {
                                    setPlanShortDescription(e);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12} sm={11} md={11} lg={11}>
                          <div className="mp-plan-field-container">
                            <div className="mp-plan-label-2">
                              {`Plan long description`}
                              <label className="account-module-label-red">
                                *
                              </label>
                            </div>
                            <div
                              style={{
                                borderBottom: "2px solid #E4E4E4",
                                marginTop: "10px",
                              }}
                            >
                              <Editor
                                readOnly={mode == 2 ? true : false}
                                editorState={editorState}
                                wrapperClassName=""
                                editorClassName=""
                                onEditorStateChange={_onEditorStateChange}
                                stripPastedStyles={true}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12} sm={11} md={11} lg={11}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div>
                              <Checkbox
                                id={`mp-plan-checkbox-auto-activate`}
                                name={`mp-plan-checkbox-auto-activate`}
                                color={mode == 2 ? "default" : "secondary"}
                                disabled={mode == 2}
                                checked={planAutoActivate}
                                style={{ marginLeft: "-10px" }}
                                onChange={(event) => {
                                  setPlanAutoActivate(!planAutoActivate);
                                }}
                              />
                            </div>
                            <div className="mp-plan-label-checkbox">
                              {`Auto activate plan`}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div className="mp-plan-create-service-field-container">
                            <div className="mp-plan-label">
                              {`Email domain`}
                            </div>
                            <div style={{ display: "flex" }}>
                              <div className="account-module-email-prefix">{`@`}</div>
                              <TextField
                                id={"mp-plan-email-domain"}
                                lineDirection="left"
                                value={domainTyped}
                                disabled={mode == 2}
                                placeholder="Type here"
                                inlineIndicator={
                                  <Button
                                    icon
                                    style={{
                                      margin: "-10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <FontIcon
                                      style={{
                                        color: "orange",
                                        lineHeight: "0",
                                        marginBottom: "15px",
                                      }}
                                      onClick={() => {
                                        if (mode != 2) {
                                          handleAddPlanEmailDomainIcon();
                                        }
                                      }}
                                    >
                                      arrow_forward
                                    </FontIcon>
                                  </Button>
                                }
                                onChange={(e) => {
                                  setDomainTyped(e);
                                }}
                              />
                            </div>
                            {renderPlanEmailDomains()}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div style={{ margin: "0px -12px 0px -12px" }}>
                <Accordion defaultExpanded={false}>
                  <AccordionSummary
                    expandIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
                    style={{ border: "1px solid #CED4DA", borderRadius: "5px" }}
                  >
                    <div
                      style={{
                        fontWeight: "900",
                        fontSize: "16px",
                      }}
                    >
                      <Checkbox
                        id={`mp-plan-checkbox-isWalletIncluded`}
                        name={`mp-plan-checkbox-isWalletIncluded`}
                        color={mode == 2 ? "default" : "secondary"}
                        disabled={mode == 2}
                        checked={isWalletIncluded}
                        style={{ marginLeft: "-10px" }}
                        onChange={(e) => {
                          setIsWalletIncluded(!isWalletIncluded);
                        }}
                      />
                      Wallet included
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mp-plan-create-plan-container">
                      <Row>
                        <Col xs={12} sm={11} md={11} lg={11}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div>
                              <Checkbox
                                id={`mp-plan-checkbox-isUnlimitedWallet`}
                                name={`mp-plan-checkbox-isUnlimitedWallet`}
                                color={mode == 2 ? "default" : "secondary"}
                                disabled={
                                  mode == 2 || isWalletIncluded == false
                                }
                                checked={isUnlimitedWallet}
                                style={{ marginLeft: "-10px" }}
                                onChange={(event) => {
                                  if (!isUnlimitedWallet) {
                                    setWalletLimit("");
                                  }
                                  setIsUnlimitedWallet(!isUnlimitedWallet);
                                }}
                              />
                            </div>
                            <div className="mp-plan-label-checkbox">
                              Check mark if wallet unlimited or define limit
                              below
                              <label className="mp-plan-label-red"> *</label>
                            </div>
                          </div>

                          <div
                            className="mp-plan-create-service-field-container"
                            style={{ marginTop: "-16px" }}
                          >
                            <div>
                              <TextField
                                value={`${walletLimit}`}
                                lineDirection="left"
                                type="number"
                                style={{ width: "200px" }}
                                placeholder="Limit (Rs)"
                                disabled={
                                  mode == 2 ||
                                  isWalletIncluded == false ||
                                  isUnlimitedWallet
                                }
                                onChange={(e) => {
                                  setWalletLimit(Number(e));
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} sm={11} md={11} lg={11}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div>
                              <Checkbox
                                id={`mp-plan-checkbox-canWalletBeUsedByFamily`}
                                name={`mp-plan-checkbox-canWalletBeUsedByFamily`}
                                color={mode == 2 ? "default" : "secondary"}
                                disabled={
                                  mode == 2 || isWalletIncluded == false
                                }
                                checked={canWalletBeUsedByFamily}
                                style={{ marginLeft: "-10px" }}
                                onChange={(event) => {
                                  if (canWalletBeUsedByFamily) {
                                    setAllowedFamilyRelationsForWallet([]);
                                    setSelectAllFamilyForWallet(false);
                                  }
                                  setWalletCanBeUsedByFamily(
                                    !canWalletBeUsedByFamily
                                  );
                                }}
                              />
                            </div>
                            <div className="mp-plan-label-checkbox">
                              If wallet can be used by family member
                            </div>
                          </div>
                          <div
                            className={
                              isWalletIncluded
                                ? "mp-plan-box-selected"
                                : "mp-plan-box-unselected"
                            }
                          >
                            <div className="mp-plan-font">{"Self"}</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div>
                              <Checkbox
                                id={`mp-plan-checkbox-selectAllFamilyForWallet`}
                                name={`mp-plan-checkbox-selectAllFamilyForWallet`}
                                color={mode == 2 ? "default" : "secondary"}
                                disabled={
                                  mode == 2 || isWalletIncluded == false
                                }
                                checked={selectAllFamilyForWallet}
                                style={{ marginLeft: "-10px" }}
                                onChange={(event) => {
                                  if (canWalletBeUsedByFamily) {
                                    setAllowedFamilyRelationsForWallet(
                                      selectAllFamilyForWallet == false
                                        ? allRelations
                                        : []
                                    );
                                    setSelectAllFamilyForWallet(
                                      !selectAllFamilyForWallet
                                    );
                                  }
                                }}
                              />
                            </div>
                            <div
                              className="mp-plan-label-checkbox"
                              style={{ marginRight: "10px" }}
                            >
                              All family
                            </div>
                            <div className="mp-plan-multiple-box-options">
                              {familyRelationsArr.map((rel) => {
                                return (
                                  <div
                                    className={
                                      allowedFamilyRelationsForWallet.includes(
                                        rel.code
                                      )
                                        ? "mp-plan-box-selected"
                                        : "mp-plan-box-unselected"
                                    }
                                    onClick={() => {
                                      if (
                                        canWalletBeUsedByFamily &&
                                        isWalletIncluded == true &&
                                        mode != 2
                                      ) {
                                        setAllowedFamilyRelationsForWallet(
                                          (prevRelations) =>
                                            prevRelations.includes(rel.code)
                                              ? prevRelations.filter(
                                                  (code) => code !== rel.code
                                                )
                                              : [...prevRelations, rel.code]
                                        );
                                      }
                                    }}
                                  >
                                    <div className="mp-plan-font">
                                      {rel.code == 6
                                        ? "Daughter < 18"
                                        : rel.code == 7
                                        ? "Daughter > 18"
                                        : rel.code == 8
                                        ? "Son < 18"
                                        : rel.code == 9
                                        ? "Son > 18"
                                        : rel.code == 6
                                        ? "Daughter < 18"
                                        : rel.value}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div style={{ margin: "0px -12px 0px -12px" }}>
                <Accordion defaultExpanded={false}>
                  <AccordionSummary
                    expandIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
                    style={{ border: "1px solid #CED4DA", borderRadius: "5px" }}
                  >
                    <div
                      style={{
                        fontWeight: "900",
                        fontSize: "16px",
                      }}
                    >
                      <Checkbox
                        id={`mp-plan-checkbox-isReimbursementIncluded`}
                        name={`mp-plan-checkbox-isReimbursementIncluded`}
                        color={mode == 2 ? "default" : "secondary"}
                        disabled={mode == 2}
                        checked={isReimbursementIncluded}
                        style={{ marginLeft: "-10px" }}
                        onChange={(e) => {
                          setIsReimbursementIncluded(!isReimbursementIncluded);
                        }}
                      />
                      Reimbursement included
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mp-plan-create-plan-container">
                      <Row>
                        <Col xs={12} sm={11} md={11} lg={11}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div>
                              <Checkbox
                                id={`mp-plan-checkbox-canReimbursementBeUsedByFamily`}
                                name={`mp-plan-checkbox-canReimbursementBeUsedByFamily`}
                                color={mode == 2 ? "default" : "secondary"}
                                disabled={
                                  mode == 2 || isReimbursementIncluded == false
                                }
                                checked={canReimbursementBeUsedByFamily}
                                style={{ marginLeft: "-10px" }}
                                onChange={(event) => {
                                  if (canReimbursementBeUsedByFamily) {
                                    setAllowedFamilyRelationsForReimbursement(
                                      []
                                    );
                                    setSelectAllFamilyForReimbursement(false);
                                  }
                                  setReimbursementCanBeUsedByFamily(
                                    !canReimbursementBeUsedByFamily
                                  );
                                }}
                              />
                            </div>
                            <div className="mp-plan-label-checkbox">
                              If wallet can be used by family member
                            </div>
                          </div>
                          <div
                            className={
                              isReimbursementIncluded
                                ? "mp-plan-box-selected"
                                : "mp-plan-box-unselected"
                            }
                          >
                            <div className="mp-plan-font">{"Self"}</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div>
                              <Checkbox
                                id={`mp-plan-checkbox-selectAllFamilyForReimbursement`}
                                name={`mp-plan-checkbox-selectAllFamilyForReimbursement`}
                                color={mode == 2 ? "default" : "secondary"}
                                disabled={
                                  mode == 2 || isReimbursementIncluded == false
                                }
                                checked={selectAllFamilyForReimbursement}
                                style={{ marginLeft: "-10px" }}
                                onChange={(event) => {
                                  if (canReimbursementBeUsedByFamily) {
                                    setAllowedFamilyRelationsForReimbursement(
                                      selectAllFamilyForReimbursement == false
                                        ? allRelations
                                        : []
                                    );
                                    setSelectAllFamilyForReimbursement(
                                      !selectAllFamilyForReimbursement
                                    );
                                  }
                                }}
                              />
                            </div>
                            <div
                              className="mp-plan-label-checkbox"
                              style={{ marginRight: "10px" }}
                            >
                              All family
                            </div>
                            <div className="mp-plan-multiple-box-options">
                              {familyRelationsArr.map((rel) => {
                                return (
                                  <div
                                    className={
                                      allowedFamilyRelationsForReimbursement.includes(
                                        rel.code
                                      )
                                        ? "mp-plan-box-selected"
                                        : "mp-plan-box-unselected"
                                    }
                                    onClick={() => {
                                      if (
                                        canReimbursementBeUsedByFamily &&
                                        isReimbursementIncluded == true &&
                                        mode != 2
                                      ) {
                                        setAllowedFamilyRelationsForReimbursement(
                                          (prevRelations) =>
                                            prevRelations.includes(rel.code)
                                              ? prevRelations.filter(
                                                  (code) => code !== rel.code
                                                )
                                              : [...prevRelations, rel.code]
                                        );
                                      }
                                    }}
                                  >
                                    <div className="mp-plan-font">
                                      {rel.code == 6
                                        ? "Daughter < 18"
                                        : rel.code == 7
                                        ? "Daughter > 18"
                                        : rel.code == 8
                                        ? "Son < 18"
                                        : rel.code == 9
                                        ? "Son > 18"
                                        : rel.code == 6
                                        ? "Daughter < 18"
                                        : rel.value}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div style={{ margin: "0px -12px 0px -12px" }}>
                <Accordion defaultExpanded={false}>
                  <AccordionSummary
                    expandIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
                    style={{ border: "1px solid #CED4DA", borderRadius: "5px" }}
                  >
                    <div
                      style={{
                        fontWeight: "900",
                        fontSize: "16px",
                      }}
                    >
                      <Checkbox
                        id={`mp-plan-checkbox-isWelcomeMsgIncluded`}
                        name={`mp-plan-checkbox-isWelcomeMsgIncluded`}
                        color={mode == 2 ? "default" : "secondary"}
                        disabled={mode == 2}
                        checked={isWelcomeMsgIncluded}
                        style={{ marginLeft: "-10px" }}
                        onChange={(e) => {
                          setIsWelcomeMsgIncluded(!isWelcomeMsgIncluded);
                        }}
                      />
                      Welcome message on chat
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mp-plan-create-plan-container">
                      <Row>
                        <Col xs={12} sm={11} md={11} lg={11}>
                          <div className="mp-plan-field-container">
                            <div
                              style={{
                                borderBottom: "2px solid #E4E4E4",
                                marginTop: "10px",
                              }}
                            >
                              <Editor
                                readOnly={
                                  mode == 2 || isWelcomeMsgIncluded == false
                                    ? true
                                    : false
                                }
                                editorState={editorState2}
                                wrapperClassName=""
                                editorClassName=""
                                onEditorStateChange={_onEditorStateChange2}
                                stripPastedStyles={true}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div>
                              <Checkbox
                                id={`mp-plan-checkbox-isCTAButtonEnabled`}
                                name={`mp-plan-checkbox-isCTAButtonEnabled`}
                                color={mode == 2 ? "default" : "secondary"}
                                disabled={
                                  mode == 2 || isWelcomeMsgIncluded == false
                                }
                                checked={isCTAButtonEnabled}
                                style={{ marginLeft: "-10px" }}
                                onChange={(event) => {
                                  if (isCTAButtonEnabled) {
                                    setCtaButton("");
                                    setCtaText("");
                                    setCustomCtaText("");
                                    setCtaLink("");
                                  }
                                  setIsCTAButtonEnabled(!isCTAButtonEnabled);
                                }}
                              />
                            </div>
                            <div className="mp-plan-label-checkbox">
                              CTA button{" "}
                              <label className="account-module-label-red">
                                *
                              </label>
                            </div>
                          </div>

                          <div
                            className="mp-plan-create-service-field-container"
                            style={{ marginTop: "-16px" }}
                          >
                            <div>
                              <SelectField
                                placeholder="Select"
                                className="mp-plan-select-field"
                                menuItems={CTA_BUTTON_OPTIONS}
                                disabled={
                                  mode == 2 ||
                                  isWelcomeMsgIncluded == false ||
                                  isCTAButtonEnabled == false
                                }
                                value={ctaButton}
                                onChange={(e) => {
                                  setCtaButton(e);
                                  if (e != "Custom CTA text") {
                                    setCustomCtaText("");
                                    setCtaLink("");
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={1} md={1} lg={1}>
                          <div style={{ height: "20px" }}></div>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div
                            className="mp-plan-create-service-field-container"
                            style={{ marginTop: "10px" }}
                          >
                            <div className="mp-plan-label">
                              {`Custom CTA text`}
                            </div>
                            <div>
                              <TextField
                                value={customCtaText}
                                lineDirection="left"
                                disabled={
                                  mode == 2 ||
                                  ctaButton != "Custom CTA text" ||
                                  isWelcomeMsgIncluded == false
                                }
                                placeholder={
                                  ctaButton != "Custom CTA text"
                                    ? ""
                                    : "Type here"
                                }
                                onChange={(e) => {
                                  setCustomCtaText(e);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={1} md={1} lg={1}>
                          <div style={{ height: "20px" }}></div>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3}>
                          <div
                            className="mp-plan-create-service-field-container"
                            style={{ marginTop: "10px" }}
                          >
                            <div className="mp-plan-label">
                              {`Custom CTA link`}
                            </div>
                            <div>
                              <TextField
                                value={`${ctaLink}`}
                                lineDirection="left"
                                disabled={
                                  mode == 2 ||
                                  ctaButton != "Custom CTA text" ||
                                  isWelcomeMsgIncluded == false
                                }
                                placeholder={
                                  ctaButton != "Custom CTA text"
                                    ? ""
                                    : "Type here"
                                }
                                onChange={(e) => {
                                  setCtaLink(e);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {props?.data?.mode == "assign-service" && (
        <>
          <div style={{ height: "40px" }}></div>
          <div className="assigned-plans-table-outer-div">
            <h2 className="">Services included in the plan</h2>
            <div className="service-provider-header-inner-div">
              <Button
                // disabled={!(serviceData?.serviceTypeId == 2)}
                raised
                secondary
                onClick={() => {
                  if (fetchedPlan?.id) {
                    props.setView(4, {
                      mode: "assign",
                      planId: fetchedPlan?.id,
                    });
                  } else {
                    EventService.emit("showError", {
                      message: `Unable to get plan id`,
                    });
                  }
                }}
              >
                Add
              </Button>
              <Button raised secondary>
                Import from existing plan
              </Button>
              <Button raised secondary>
                Upload CSV
              </Button>
              <Button raised secondary>
                Download CSV
              </Button>
            </div>
          </div>
        </>
      )}
      {props?.data?.mode == "assign-service" && (
        <div style={{ marginTop: "15px" }}>{expandableTile}</div>
      )}
    </div>
  );
};

export default CreatePlan;
