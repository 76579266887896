import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, TextField, Checkbox } from "react-md";
import "../styles/createAccountStyles.css";
import FontIcon from "react-md/lib/FontIcons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AssignUtils from "../services/AssignUtils";
import EventService from "../../../../../service/event/EventService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField as TextField2 } from "@material-ui/core";

export const AssignAccountsOnPid = (props) => {
  const [pid, setPid] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const [accountSelected, setAccountSelected] = useState(null);
  const [accountCodeNameSelected, setAccountCodeNameSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const assignUtils = new AssignUtils();

  useEffect(() => {
    const path = history.location.pathname;
    const match = path.match(/\/assign-account\/(\d+)/);

    const id = match ? match[1] : null;
    console.log("pr- id=", id);
    setPid(id);
    getActiveAccounts();
  }, []);

  const ACCOUNT_TYPE_OPTIONS = [
    {
      label: "Zyla",
      value: 1,
    },
    {
      label: "Insurance",
      value: 2,
    },
    {
      label: "Pharma",
      value: 3,
    },
    {
      label: "Corporate",
      value: 4,
    },
  ];
  const getAccountTypeLabel = (code) => {
    const numericValue = parseInt(code, 10);
    const match = ACCOUNT_TYPE_OPTIONS.find(
      (item) => item.value === numericValue
    );
    return match ? match.label : null;
  };

  const getActiveAccounts = () => {
    let body = {
      pageNum: 0,
      pageSize: 0,
      status: "active",
    };
    const onResponse = (res) => {
      console.log("pr- all active acc=", res);
      setAccountList(res.accounts.length == 0 ? [] : res.accounts);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };

    assignUtils.getAccountsByFilter(body, onResponse, onError);
  };

  const assignAccount = () => {
    if (pid == null) {
      EventService.emit("showError", {
        message: `Patient ID is null`,
      });
      return;
    } else if (accountSelected == null) {
      EventService.emit("showError", {
        message: `Please select an account`,
      });
      return;
    } else {
      setLoading(true);
      let body = {
        patientId: Number(pid),
        accountCodes: [accountSelected.accountCode],
      };
      const onResponse = (res) => {
        EventService.emit("showError", {
          message: `Account assigned`,
        });
        setLoading(false);
        history.go(-1);
      };
      const onError = (err) => {
        EventService.emit("showError", {
          message: `Error: ${err?.message}`,
        });
        setLoading(false);
      };

      assignUtils.assignAccount(body, onResponse, onError);
    }
  };
  return (
    <div className="account-module-page-padding">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="account-module-view">
            <div className="account-module-heading-with-back-button">
              <div
                className="account-module-back-btn"
                onClick={() => {
                  history.go(-1);
                }}
              >
                <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
              </div>
              <h2>{`Assign account`}</h2>
            </div>
            <div className="account-module-buttons-container">
              <div className="account-module-buttons-right-alignment">
                <Button
                  raised
                  disabled={loading}
                  className={
                    loading
                      ? "account-module-button-save-disabled"
                      : "account-module-button-save-enabled"
                  }
                  onClick={() => {
                    assignAccount();
                  }}
                >
                  {"Assign"}
                </Button>

                <Button
                  raised
                  disabled={loading}
                  className="account-module-button-cancel"
                  onClick={() => {
                    history.go(-1);
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <div className="account-module-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Select account code`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <Autocomplete
                  id="mp-navigo-service-code-selection"
                  options={accountList}
                  value={accountCodeNameSelected == 2 ? null : accountSelected}
                  onChange={(e, v) => {
                    if (v) {
                      if (accountSelected != null) {
                      }

                      setAccountSelected(v);
                      setAccountCodeNameSelected(1);
                      console.log("pr- Service selected :", v);
                    } else {
                      setAccountSelected(null);
                      setAccountCodeNameSelected(0);
                    }
                  }}
                  getOptionLabel={(option) =>
                    option ? option.accountCode : ""
                  }
                  filterOptions={(options, { inputValue }) => {
                    const searchText = inputValue.toLowerCase();

                    return options.filter((option) => {
                      const accountCode =
                        option.accountCode?.toLowerCase() || "";

                      return accountCode.includes(searchText);
                    });
                  }}
                  renderInput={(params) => (
                    <TextField2
                      {...params}
                      placeholder="Select"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "8px",
                        },
                        classes: {
                          underline: "custom-underline",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div
              className="account-module-label"
              style={{
                height: "20px",
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              Or
            </div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Select account name`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <Autocomplete
                  id="mp-navigo-service-name-selection"
                  options={accountList}
                  value={accountCodeNameSelected == 1 ? null : accountSelected}
                  onChange={(e, v) => {
                    if (v) {
                      if (accountSelected != null) {
                      }
                      setAccountSelected(v);
                      setAccountCodeNameSelected(2);
                      console.log("pr- Service selected :", v);
                    } else {
                      setAccountSelected(null);
                      setAccountCodeNameSelected(0);
                    }
                  }}
                  getOptionLabel={(option) =>
                    option ? option.accountName : ""
                  }
                  filterOptions={(options, { inputValue }) => {
                    const searchText = inputValue.toLowerCase();

                    return options.filter((option) => {
                      const accountName =
                        option.accountName?.toLowerCase() || "";

                      return accountName.includes(searchText);
                    });
                  }}
                  renderInput={(params) => (
                    <TextField2
                      {...params}
                      placeholder="Select"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "8px",
                        },
                        classes: {
                          underline: "custom-underline",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        {accountSelected == null ? (
          <></>
        ) : (
          <>
            {" "}
            <br />
            <div className="account-module-grey-heading">Account details</div>
            <br />
            <div className="account-module-create-service-container">
              <Row>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <div className="account-module-create-service-field-container">
                    <div className="account-module-label">{`Account ID`}</div>
                    <div>
                      <TextField
                        value={accountSelected?.accountCode}
                        lineDirection="left"
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={1} md={1} lg={1}>
                  <div style={{ height: "20px" }}></div>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <div className="account-module-create-service-field-container">
                    <div className="account-module-label">{`Account name`}</div>
                    <div>
                      <TextField
                        value={accountSelected?.accountName}
                        lineDirection="left"
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={1} md={1} lg={1}>
                  <div style={{ height: "20px" }}></div>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <div className="account-module-create-service-field-container">
                    <div className="account-module-label">{`Account status`}</div>
                    <div>
                      <TextField
                        value={
                          accountSelected?.status == true
                            ? "Active"
                            : "Inactive"
                        }
                        lineDirection="left"
                        disabled
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <div className="account-module-create-service-field-container">
                    <div className="account-module-label">{`Account type`}</div>
                    <div>
                      <TextField
                        value={getAccountTypeLabel(
                          accountSelected?.accountCode.slice(0, 2)
                        )}
                        lineDirection="left"
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={1} md={1} lg={1}>
                  <div style={{ height: "20px" }}></div>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <div className="account-module-create-service-field-container">
                    <div className="account-module-label">{`Account mode`}</div>
                    <div>
                      <TextField
                        value={accountSelected?.mode}
                        lineDirection="left"
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={1} md={1} lg={1}>
                  <div style={{ height: "20px" }}></div>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <div className="account-module-create-service-field-container">
                    <div className="account-module-label">{`Key account manager`}</div>
                    <div>
                      <TextField
                        value={accountSelected?.managerName}
                        lineDirection="left"
                        disabled
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AssignAccountsOnPid;
