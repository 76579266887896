import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, Autocomplete, TextField } from "react-md";
import { Checkbox } from "@material-ui/core";
import FontIcon from "react-md/lib/FontIcons";
import { Select, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "../styles/index.css";
import UploadFileIcon from "../../../../../assets/icons/upload-file-icon.png";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import EventService from "../../../../../service/event/EventService";
import CreateServiceProviderUtil from "../services/CreateServiceProviderUtil";
import CreateServiceUtil from "../services/CreateServiceUtil";
import moment from "moment";
import MultipleSelectChip from "../helper/MultipleSelectChip";
import AuthenticationService from "../../../../../service/auth/AuthenticationService";
import AvailabilityCalendar from "../helper/AvailabilityCalendar";
import SimpleBackdrop from "../helper/SimpleBackdrop";

export const ServiceProviderHealthCheckup = ({
  providerToCreate,
  setProviderToCreate,
  mode,
  calendarHeadings,
  countries,
}) => {
  const [serviceProviderMobileImage, setServiceProviderMobileImage] =
    useState(null);
  const [serviceProviderMobilePreview, setServiceProviderMobilePreview] =
    useState("");
  const [serviceProviderDesktopImage, setServiceProviderDesktopImage] =
    useState(null);
  const [serviceProviderDesktopPreview, setServiceProviderDesktopPreview] =
    useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [servicesSubTypeArr, setServicesSubTypeArr] = useState([]);
  const [servicesSubTypeObj, setServicesSubTypeObj] = useState([]);
  const [groupVendorTypeArr, setGroupVendorTypeArr] = useState([]);
  const [groupVendorTypeObj, setGroupVendorTypeObj] = useState([]);
  const [countryCodeArr, setCountryCodeArr] = useState([]);
  const [countryCodeObj, setCountryCodeObj] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [altEmail, setAltEmail] = useState([]);
  const [spocDetails, setSpocDetails] = useState([
    {
      name: "",
      countryCode: 91,
      phoneNo: "",
      emailId: "",
    },
  ]);
  const createServiceProviderUtil = new CreateServiceProviderUtil();
  const createServiceUtil = new CreateServiceUtil();
  const serviceProviderMobileImageInputRef = useRef(null);
  const serviceProviderDesktopImageInputRef = useRef(null);

  useEffect(() => {
    getServiceSubType(providerToCreate?.serviceTypeId);
    getGroupVendor();
    setCountryCodeArr(countries);
    if (mode == 2 || mode == 3) {
      setServiceProviderMobilePreview(
        providerToCreate?.checkupInfo?.identityDetails?.mobileCover
      );
      setServiceProviderDesktopPreview(
        providerToCreate?.checkupInfo?.identityDetails?.desktopCover
      );
      let contentBlock = htmlToDraft(
        providerToCreate?.checkupInfo?.identityDetails?.longDescription
      );
      if (contentBlock) {
        let contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        let editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
      if (
        providerToCreate.checkupInfo.contactDetails.alternateBookingEmails &&
        providerToCreate.checkupInfo.contactDetails.alternateBookingEmails
          .length > 0
      ) {
        setAltEmail(
          providerToCreate.checkupInfo.contactDetails.alternateBookingEmails
        );
      }
      if (
        providerToCreate.checkupInfo.contactDetails.spoc &&
        providerToCreate.checkupInfo.contactDetails.spoc.length > 0
      ) {
        setSpocDetails(providerToCreate.checkupInfo.contactDetails.spoc);
      }
    }
  }, []);
  useEffect(() => {
    let dropdownObj = servicesSubTypeArr.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
    setServicesSubTypeObj(dropdownObj);
  }, [servicesSubTypeArr]);

  useEffect(() => {
    let dropdownObj = groupVendorTypeArr.map((e) => {
      return {
        label: e.name,
        value: e.name,
      };
    });
    setGroupVendorTypeObj(dropdownObj);
  }, [groupVendorTypeArr]);

  useEffect(() => {
    setProviderToCreate((prev) => {
      let providerToCreate = prev;
      return {
        ...providerToCreate,
        checkupInfo: {
          ...providerToCreate.checkupInfo,
          contactDetails: {
            ...providerToCreate.checkupInfo.contactDetails,
            spoc: spocDetails,
          },
        },
      };
    });
  }, [spocDetails]);
  useEffect(() => {
    let dropdownObj = countryCodeArr?.map((e) => {
      return {
        label: `+${e.code}`,
        value: e.code,
      };
    });
    console.log("pr- obj cc=", dropdownObj);
    setCountryCodeObj(dropdownObj);
  }, [countryCodeArr]);

  useEffect(() => {
    if (serviceProviderMobileImage != null) {
      setShowBackdrop(true);
      uploadImage(serviceProviderMobileImage)
        .then((e) => {
          setServiceProviderMobilePreview(e);
          let obj = {
            ...providerToCreate,
            checkupInfo: {
              ...providerToCreate.checkupInfo,
              identityDetails: {
                ...providerToCreate.checkupInfo.identityDetails,
                mobileCover: e,
              },
            },
          };
          setProviderToCreate(obj);
          setTimeout(() => {
            setShowBackdrop(false);
          }, 500);
        })
        .catch((err) => {
          setTimeout(() => {
            setShowBackdrop(false);
            EventService.emit("showError", {
              message: `Error while uploading the plan mobile image: ${err}`,
            });
          }, 500);
        });
    }
  }, [serviceProviderMobileImage]);
  useEffect(() => {
    if (serviceProviderDesktopImage != null) {
      setShowBackdrop(true);
      uploadImage(serviceProviderDesktopImage)
        .then((e) => {
          setServiceProviderDesktopPreview(e);
          let obj = {
            ...providerToCreate,
            checkupInfo: {
              ...providerToCreate.checkupInfo,
              identityDetails: {
                ...providerToCreate.checkupInfo.identityDetails,
                desktopCover: e,
              },
            },
          };
          setProviderToCreate(obj);
          setTimeout(() => {
            setShowBackdrop(false);
          }, 500);
        })
        .catch((err) => {
          setTimeout(() => {
            setShowBackdrop(false);
            EventService.emit("showError", {
              message: `Error while uploading the plan desktop image: ${err}`,
            });
          }, 500);
        });
    }
  }, [serviceProviderDesktopImage]);

  useEffect(() => {
    setProviderToCreate((prev) => {
      let providerToCreate = prev;
      return {
        ...providerToCreate,
        checkupInfo: {
          ...providerToCreate.checkupInfo,
          contactDetails: {
            ...providerToCreate.checkupInfo.contactDetails,
            alternateBookingEmails: altEmail,
          },
        },
      };
    });
  }, [altEmail]);

  let getServiceSubType = (id) => {
    let onResponse = (res) => {
      setServicesSubTypeArr((prev) => {
        const updatedState = [...res];
        return updatedState;
      });
      if (mode == 1) {
        setProviderToCreate({
          ...providerToCreate,
          checkupInfo: {
            ...providerToCreate.checkupInfo,
            vendorGroup: {
              ...providerToCreate.checkupInfo.vendorGroup,
              isVendorGroup: false,
            },
            amenities: {
              breakfastAvailable: false,
            },
            otherInfo: {
              advancePaymentRequired: false,
              autoBookingConfirmation: false,
            },
          },
        });
      }
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceUtil.getServiceSubType(id, true, onResponse, onError);
  };
  const phoneNoLen = (cc) => {
    return countries?.find((c) => c.code == cc).phoneNoLen;
  };

  let getGroupVendor = () => {
    let onResponse = (res) => {
      setGroupVendorTypeArr((prev) => {
        const updatedState = [...res];
        return updatedState;
      });
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceProviderUtil.getGroupVendor(onResponse, onError);
  };

  const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", file, file.name);

      createServiceUtil.uploadImg(
        data,
        (res) => {
          resolve(res.publicUrl);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const addSpocRow = () => {
    setSpocDetails([
      ...spocDetails,
      {
        name: "",
        countryCode: 91,
        phoneNo: "",
        emailId: "",
      },
    ]);
  };
  const removeSpocRow = (index) => {
    setSpocDetails((prev) => prev.filter((_, i) => i !== index));
  };
  const setSpocData = (e, index) => {
    setSpocDetails((prev) => prev.map((item, i) => (i === index ? e : item)));
  };
  const addAlternateEmail = () => {
    if (altEmail == []) {
      setAltEmail([""]);
    } else {
      setAltEmail([...altEmail, ""]);
    }
  };
  const removeAltEmail = (index) => {
    setAltEmail((prev) => prev.filter((_, i) => i !== index));
  };
  const setAltEmails = (e, index) => {
    setAltEmail((prev) => prev.map((item, i) => (i === index ? e : item)));
  };

  const serviceProviderMobileUploadClick = () => {
    if (serviceProviderMobileImageInputRef.current) {
      serviceProviderMobileImageInputRef.current.click();
    }
  };

  const serviceProviderMobileImageSelected = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("No file selected or file picker was canceled");
      return;
    }

    const maxSize = 20 * 1024 * 1024; // 20MB
    if (file.size > maxSize) {
      EventService.emit("showError", {
        message: "File size cannot be more than 20MB",
      });
      return;
    }
    const img = new Image();
    img.onload = function () {
      if (this.width !== 1080 || this.height !== 1080) {
        EventService.emit("showError", {
          message: "Image dimensions must be 1080x1080 pixels",
        });
        return;
      }
      setServiceProviderMobileImage(file);
    };
    img.src = URL.createObjectURL(file);
  };
  const serviceProviderDesktopUploadClick = () => {
    if (serviceProviderDesktopImageInputRef.current) {
      serviceProviderDesktopImageInputRef.current.click();
    }
  };

  const serviceProviderDesktopImageSelected = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("No file selected or file picker was canceled");
      return;
    }

    const maxSize = 20 * 1024 * 1024; // 20MB
    if (file.size > maxSize) {
      EventService.emit("showError", {
        message: "File size cannot be more than 20MB",
      });
      return;
    }
    const img = new Image();
    img.onload = function () {
      if (this.width !== 1080 || this.height !== 1080) {
        EventService.emit("showError", {
          message: "Image dimensions must be 1080x1080 pixels",
        });
        return;
      }
      setServiceProviderDesktopImage(file);
    };
    img.src = URL.createObjectURL(file);
  };
  let SERVICE_PROVIDER_STATUS = [
    {
      label: "Active",
      value: "true",
    },
    {
      label: "Inactive",
      value: "false",
    },
  ];
  const _onEditorStateChange = (editorState) => {
    let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setProviderToCreate({
      ...providerToCreate,
      checkupInfo: {
        ...providerToCreate.checkupInfo,
        identityDetails: {
          ...providerToCreate.checkupInfo.identityDetails,
          longDescription: body,
        },
      },
    });
    setEditorState(editorState);
  };

  const fetchPincodeDetails = (pincode) => {
    let onResponse = (res) => {
      setProviderToCreate({
        ...providerToCreate,
        checkupInfo: {
          ...providerToCreate.checkupInfo,
          address: {
            ...providerToCreate.checkupInfo.address,
            pincode: res.pincode,
            district: res.district,
            state: res.state,
          },
        },
      });
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceProviderUtil.getPincodeDetails(pincode, onResponse, onError);
  };

  let setAvailability = (availability1) => {
    setProviderToCreate({
      ...providerToCreate,
      checkupInfo: {
        ...providerToCreate.checkupInfo,
        availability: availability1,
      },
    });
  };

  return (
    <>
      <div style={{ height: "20px" }} />
      {console.log("pr- HC=", providerToCreate)}
      <Row>
        {/* <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Provider subtype`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <SelectField
                id={"mp-service-provider-service-subtype"}
                placeholder="Select"
                className="mp-select-field"
                disabled={mode == 2 || mode == 3}
                menuItems={servicesSubTypeObj}
                value={providerToCreate?.serviceSubtypeId}
                onChange={(e) => {
                  setProviderToCreate({
                    ...providerToCreate,
                    serviceSubtypeId: e,
                  });
                }}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} sm={1} md={1} lg={1}>
          <div style={{ height: "20px" }}></div>
        </Col> */}
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Status`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <SelectField
                id={"mp-service-provider-status"}
                placeholder="Select"
                disabled={mode == 2}
                className="mp-select-field"
                menuItems={SERVICE_PROVIDER_STATUS}
                // defaultValue={"true"}
                value={providerToCreate?.isActive}
                onChange={(e) =>
                  setProviderToCreate({ ...providerToCreate, isActive: e })
                }
              />
            </div>
          </div>
        </Col>
      </Row>
      <h3 className="mp-sub-heading-margin">{`Personal details`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-create-service-field-container">
            <div className="mp-label">
              {`Service provider name`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <TextField
                id={"mp-service-provider-name"}
                lineDirection="left"
                disabled={mode == 2}
                placeholder="This will be displayed on the app"
                value={`${
                  providerToCreate?.checkupInfo?.identityDetails?.name != null
                    ? providerToCreate?.checkupInfo?.identityDetails?.name
                    : ""
                }`}
                onChange={(e) => {
                  setProviderToCreate({
                    ...providerToCreate,
                    checkupInfo: {
                      ...providerToCreate.checkupInfo,
                      identityDetails: {
                        ...providerToCreate.checkupInfo.identityDetails,
                        name: e,
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div style={{ height: "20px" }}></div>
        </Col>

        <Col xs={12} sm={7} md={7} lg={7}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Service provider short description (50 characters)`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <TextField
                disabled={mode == 2}
                value={
                  providerToCreate?.checkupInfo?.identityDetails
                    ?.shortdescription
                    ? `${providerToCreate?.checkupInfo?.identityDetails?.shortdescription}`
                    : ""
                }
                lineDirection="left"
                placeholder="Type here"
                error={
                  providerToCreate?.checkupInfo?.identityDetails
                    ?.shortdescription != null
                    ? providerToCreate.checkupInfo.identityDetails
                        .shortdescription >= 50
                      ? true
                      : false
                    : false
                }
                errorText="Short description can be of max 50 characters only"
                onChange={(e) => {
                  if (e.length <= 50) {
                    setProviderToCreate({
                      ...providerToCreate,
                      checkupInfo: {
                        ...providerToCreate.checkupInfo,
                        identityDetails: {
                          ...providerToCreate.checkupInfo.identityDetails,
                          shortdescription: e,
                        },
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={11} md={11} lg={11}>
          <div className="mp-field-container">
            <div className="mp-label-2">
              {`Service provider long description`}
            </div>
            <div
              style={{ borderBottom: "2px solid #E4E4E4", marginTop: "10px" }}
            >
              <Editor
                // toolbarClassName={showToolbar ? null : "toolBar-hidden"}
                // toolbarClassName={showToolbar ? null : " toolBar-hidden-pn"}

                readOnly={mode == 2 ? true : false}
                editorState={editorState}
                wrapperClassName=""
                editorClassName=""
                // hashtag={{
                //   separator: " ",
                //   trigger: "#",
                // }}
                onEditorStateChange={_onEditorStateChange}
                stripPastedStyles={true}
                // placeholder={
                //   <label>{`Please enter service long description`}</label>
                // }
              />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-create-service-field-container">
            <div className="mp-label">
              {`Image (Mobile view)`}
              <label className="mp-label-red"> *</label>
            </div>
            <div className="mp-image-requirements">{`(Max file size: 20mb, dimension 1080*1080px)`}</div>
            <Button
              raised
              disabled={mode == 2}
              className="mp-button-upload"
              onClick={() => {
                serviceProviderMobileUploadClick();
              }}
            >
              {"Upload"}
            </Button>
            <input
              type="file"
              accept="image/png, image/jpeg"
              ref={serviceProviderMobileImageInputRef}
              style={{ display: "none" }}
              onChange={serviceProviderMobileImageSelected}
            />
            <Col xs={12} sm={5} md={4} lg={4}>
              {serviceProviderMobilePreview &&
                serviceProviderMobilePreview != "" && (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px solid black",
                        marginTop: "15px",
                        marginLeft: "-6px",
                      }}
                    >
                      <img
                        src={serviceProviderMobilePreview}
                        alt="Selected Preview"
                        style={{
                          width: "100px",
                          height: "auto",
                          display: "block",
                        }}
                      />
                    </div>
                  </>
                )}
            </Col>
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div style={{ height: "20px" }}></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-create-service-field-container">
            <div className="mp-label">{`Image (Desktop view)`}</div>
            <div className="mp-image-requirements">{`(Max file size: 20mb, dimension 1080*1080px)`}</div>
            <Button
              raised
              disabled={mode == 2}
              className="mp-button-upload"
              onClick={() => {
                serviceProviderDesktopUploadClick();
              }}
            >
              {"Upload"}
            </Button>
            <input
              type="file"
              accept="image/png, image/jpeg"
              ref={serviceProviderDesktopImageInputRef}
              style={{ display: "none" }}
              onChange={serviceProviderDesktopImageSelected}
            />
            <Col xs={12} sm={5} md={4} lg={4}>
              {serviceProviderDesktopPreview &&
                serviceProviderDesktopPreview != "" && (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px solid black",
                        marginTop: "15px",
                        marginLeft: "-6px",
                      }}
                    >
                      <img
                        src={serviceProviderDesktopPreview}
                        alt="Selected Preview"
                        style={{
                          width: "100px",
                          height: "auto",
                          display: "block",
                        }}
                      />
                    </div>
                  </>
                )}
            </Col>
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <h3 className="mp-sub-heading-margin">{`Contact details`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Booking Email Id`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              id={"service-provider-contact-number-website"}
              lineDirection="left"
              disabled={mode == 2}
              placeholder="Type here"
              value={
                providerToCreate?.checkupInfo?.contactDetails?.bookingEmailId !=
                null
                  ? `${providerToCreate?.checkupInfo?.contactDetails?.bookingEmailId}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    contactDetails: {
                      ...providerToCreate.checkupInfo.contactDetails,
                      bookingEmailId: e,
                    },
                  },
                });
              }}
            />
          </div>
          <div>
            <div
              className="mp-add-options"
              onClick={() => {
                if (mode != 2) {
                  addAlternateEmail();
                }
              }}
            >{`+ Add alternate email`}</div>
            {altEmail.map((e, index) => {
              return (
                <div>
                  <div className="mp-label" style={{ marginTop: "5px" }}>
                    {`Alternate email`}
                  </div>
                  <div className="mp-email-container">
                    <div style={{ flex: "1" }}>
                      <TextField
                        id={`service-provider-alt-email-${index}`}
                        lineDirection="left"
                        disabled={mode == 2}
                        value={e != null ? `${e}` : ""}
                        onChange={(e) => {
                          if (e.length <= 50) {
                            setAltEmails(e, index);
                          }
                        }}
                      />
                    </div>
                    <div
                      className="mp-alt-email-remove"
                      onClick={() => {
                        if (mode != 2) {
                          removeAltEmail(index);
                        }
                      }}
                    >
                      <FontIcon style={{ fontSize: "16px" }}>close</FontIcon>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Website link`}</div>

            <TextField
              id={"service-provider-contact-number-website"}
              lineDirection="left"
              disabled={mode == 2}
              placeholder="Type here"
              value={
                providerToCreate?.checkupInfo?.contactDetails?.website != null
                  ? `${providerToCreate?.checkupInfo?.contactDetails?.website}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    contactDetails: {
                      ...providerToCreate.checkupInfo.contactDetails,
                      website: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <div className="mp-spoc-heading-container">
        <h3 className="mp-sub-heading-margin">{`SPOC details`}</h3>
        <div
          className="mp-add-options"
          style={{ padding: "5px" }}
          onClick={() => {
            if (mode != 2) {
              addSpocRow();
            }
          }}
        >{`+ Add new`}</div>
      </div>
      <Row>
        {spocDetails.map((e, index) => {
          return (
            <>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div
                  className="mp-field-container"
                  style={{ marginTop: "5px" }}
                >
                  <div className="mp-label">
                    {`SPOC ${index + 1} full name`}
                    {index == 0 && <label className="mp-label-red"> *</label>}
                  </div>

                  <TextField
                    id={"service-provider-spoc-name"}
                    lineDirection="left"
                    placeholder="Type here"
                    disabled={mode == 2}
                    value={e.name != "" ? e.name : ""}
                    onChange={(elem) => {
                      let data = { ...e, name: elem };
                      setSpocData(data, index);
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div className="mp-between-two-side"></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-field-container">
                  <div className="mp-label">
                    {`SPOC ${index + 1} contact number`}
                    {index == 0 && <label className="mp-label-red"> *</label>}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "40px", marginRight: "20px" }}>
                      <SelectField
                        id={`service-provider-spoc-contact-number-cc-${index}`}
                        placeholder="+XX"
                        disabled={mode == 2}
                        className="mp-select-field"
                        menuItems={countryCodeObj}
                        value={e.countryCode}
                        onChange={(elem) => {
                          let data = { ...e, countryCode: elem };
                          setSpocData(data, index);
                        }}
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <TextField
                        id={"service-provider-spoc-contact-number-phone"}
                        lineDirection="left"
                        placeholder="Enter phone number"
                        disabled={mode == 2}
                        type="text"
                        value={e.phoneNo != "" ? e.phoneNo : ""}
                        onChange={(elem) => {
                          let value = elem.replace(/\D/g, "");
                          if (value.length <= phoneNoLen(e.countryCode)) {
                            let data = {
                              ...e,
                              phoneNo: value == "" ? "" : Number(value),
                            };
                            setSpocData(data, index);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div className="mp-between-two-side"></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-field-container">
                  <div className="mp-label">
                    {`SPOC ${index + 1} email ID`}
                    {index == 0 && <label className="mp-label-red"> *</label>}
                  </div>

                  <TextField
                    id={"service-provider-contact-number-website"}
                    lineDirection="left"
                    placeholder="Type here"
                    disabled={mode == 2}
                    value={e.emailId != "" ? e.emailId : ""}
                    onChange={(elem) => {
                      let data = { ...e, emailId: elem };
                      setSpocData(data, index);
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                {index != 0 && (
                  <div
                    className="mp-spoc-remove"
                    onClick={() => {
                      if (mode != 2) {
                        removeSpocRow(index);
                      }
                    }}
                  >
                    <FontIcon style={{ fontSize: "16px" }}>close</FontIcon>
                  </div>
                )}
              </Col>
            </>
          );
        })}
      </Row>
      <h3 className="mp-sub-heading-margin">{`Address details`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Pincode`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              value={
                providerToCreate?.checkupInfo?.address?.pincode
                  ? `${providerToCreate?.checkupInfo?.address?.pincode}`
                  : ""
              }
              type="number"
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              onChange={(e) => {
                if (e.length <= 6) {
                  if (/^\d+$/.test(e) || e === "") {
                    setProviderToCreate({
                      ...providerToCreate,
                      checkupInfo: {
                        ...providerToCreate.checkupInfo,
                        address: {
                          ...providerToCreate.checkupInfo.address,
                          pincode: Number(e),
                        },
                      },
                    });
                  }

                  // if (e.length == 6) {
                  //   fetchPincodeDetails(e);
                  // }
                }
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`City (This will be displayed on the app)`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              id={"service-provider-address-city"}
              lineDirection="left"
              disabled={mode == 2}
              placeholder="Type here"
              value={
                providerToCreate?.checkupInfo?.address?.city
                  ? `${providerToCreate?.checkupInfo?.address?.city}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    address: {
                      ...providerToCreate.checkupInfo.address,
                      city: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`District`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              id={"service-provider-address-district"}
              lineDirection="left"
              disabled={mode == 2}
              placeholder="Type here"
              value={
                providerToCreate?.checkupInfo?.address?.district
                  ? `${providerToCreate?.checkupInfo?.address?.district}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    address: {
                      ...providerToCreate.checkupInfo.address,
                      district: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`State`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              id={"service-provider-address-state"}
              lineDirection="left"
              disabled={mode == 2}
              placeholder="Type here"
              value={
                providerToCreate?.checkupInfo?.address?.state
                  ? `${providerToCreate?.checkupInfo?.address?.state}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    address: {
                      ...providerToCreate.checkupInfo.address,
                      state: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`House No. /Floor /Building`}</div>

            <TextField
              id={"service-provider-address-houseno"}
              lineDirection="left"
              disabled={mode == 2}
              placeholder="Type here"
              value={
                providerToCreate?.checkupInfo?.address?.housing
                  ? `${providerToCreate?.checkupInfo?.address?.housing}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    address: {
                      ...providerToCreate.checkupInfo.address,
                      housing: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Colony /Street /Area`}</div>

            <TextField
              id={"service-provider-address-area"}
              lineDirection="left"
              disabled={mode == 2}
              placeholder="Type here"
              value={
                providerToCreate?.checkupInfo?.address?.area
                  ? `${providerToCreate?.checkupInfo?.address?.area}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    address: {
                      ...providerToCreate.checkupInfo.address,
                      area: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Locality (This will be displayed on the app)`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              id={"service-provider-address-landmark"}
              lineDirection="left"
              disabled={mode == 2}
              placeholder="Type here"
              value={
                providerToCreate?.checkupInfo?.address?.landmark
                  ? `${providerToCreate?.checkupInfo?.address?.landmark}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    address: {
                      ...providerToCreate.checkupInfo.address,
                      landmark: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={7} md={7} lg={7}>
          <div className="mp-field-container">
            <div className="mp-label">{`Google map link`}</div>

            <TextField
              id={"service-provider-address-google-map-link"}
              lineDirection="left"
              disabled={mode == 2}
              placeholder="Type here"
              value={
                providerToCreate?.checkupInfo?.address?.googleMapLink
                  ? `${providerToCreate?.checkupInfo?.address?.googleMapLink}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    address: {
                      ...providerToCreate.checkupInfo.address,
                      googleMapLink: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <h3 className="mp-sub-heading-margin">{`Reviews`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`CSAT rating`}</div>

            <TextField
              id={"service-provider-address-rating"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              type="number"
              value={
                providerToCreate?.checkupInfo?.reviewData?.csatRating
                  ? `${providerToCreate?.checkupInfo?.reviewData?.csatRating}`
                  : ""
              }
              onChange={(e) => {
                const value = e === "" ? "" : Number(e);

                if (
                  value === "" ||
                  (Number.isInteger(value) && value >= 1 && value <= 5)
                ) {
                  setProviderToCreate({
                    ...providerToCreate,
                    checkupInfo: {
                      ...providerToCreate.checkupInfo,
                      reviewData: {
                        ...providerToCreate.checkupInfo.reviewData,
                        csatRating: value === "" ? null : value,
                      },
                    },
                  });
                }
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Google review link`}</div>

            <TextField
              id={"service-provider-address-google-review"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              value={
                providerToCreate?.checkupInfo?.reviewData?.googleReviewLink
                  ? `${providerToCreate?.checkupInfo?.reviewData?.googleReviewLink}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    reviewData: {
                      ...providerToCreate.checkupInfo.reviewData,
                      googleReviewLink: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <h3 className="mp-sub-heading-margin">{`Group vendor`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Checkbox
                id="mp-checkbox-1"
                name="mp-service-provider-experience-checkbox"
                style={{ marginLeft: -13, marginTop: 3 }}
                disabled={mode == 2}
                color={mode == 2 ? "default" : "secondary"}
                checked={
                  providerToCreate?.checkupInfo?.vendorGroup?.isVendorGroup !=
                  null
                    ? providerToCreate?.checkupInfo?.vendorGroup?.isVendorGroup
                      ? true
                      : false
                    : false
                }
                onChange={(value, proxy) => {
                  providerToCreate?.checkupInfo?.vendorGroup?.isVendorGroup ==
                  true
                    ? setProviderToCreate({
                        ...providerToCreate,
                        checkupInfo: {
                          ...providerToCreate.checkupInfo,
                          vendorGroup: {
                            ...providerToCreate.checkupInfo.vendorGroup,
                            isVendorGroup: false,
                            vendorName: "",
                          },
                        },
                      })
                    : setProviderToCreate({
                        ...providerToCreate,
                        checkupInfo: {
                          ...providerToCreate.checkupInfo,
                          vendorGroup: {
                            ...providerToCreate.checkupInfo.vendorGroup,
                            isVendorGroup: true,
                          },
                        },
                      });
                }}
              />
            </div>
            <div className="mp-label-checkbox">
              {`Whether sub service provider`}
            </div>
          </div>

          <div
            className="mp-checkbox-field-container"
            style={{ marginTop: "-16px" }}
          >
            <div>
              <SelectField
                id={"mp-service-provider-group-vendor"}
                placeholder="Select main service provider"
                className="mp-select-field"
                disabled={
                  mode == 2 ||
                  providerToCreate?.checkupInfo?.vendorGroup?.isVendorGroup ==
                    false
                }
                menuItems={groupVendorTypeObj}
                value={providerToCreate?.checkupInfo?.vendorGroup?.vendorName}
                onChange={(e) => {
                  setProviderToCreate({
                    ...providerToCreate,
                    checkupInfo: {
                      ...providerToCreate.checkupInfo,
                      vendorGroup: {
                        ...providerToCreate.checkupInfo.vendorGroup,
                        vendorName: e,
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <h3 className="mp-sub-heading-margin">{`Amenities`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Checkbox
                id="mp-checkbox-2"
                name="mp-service-provider-experience-checkbox"
                style={{ marginLeft: -13, marginTop: 3 }}
                disabled={mode == 2}
                color={mode == 2 ? "default" : "secondary"}
                checked={
                  providerToCreate?.checkupInfo?.amenities
                    ?.breakfastAvailable != null
                    ? providerToCreate?.checkupInfo?.amenities
                        ?.breakfastAvailable
                      ? true
                      : false
                    : false
                }
                onChange={(value, proxy) => {
                  providerToCreate?.checkupInfo?.amenities
                    ?.breakfastAvailable == true
                    ? setProviderToCreate({
                        ...providerToCreate,
                        checkupInfo: {
                          ...providerToCreate.checkupInfo,
                          amenities: {
                            breakfastAvailable: false,
                          },
                        },
                      })
                    : setProviderToCreate({
                        ...providerToCreate,
                        checkupInfo: {
                          ...providerToCreate.checkupInfo,
                          amenities: {
                            breakfastAvailable: true,
                          },
                        },
                      });
                }}
              />
            </div>
            <div className="mp-label-checkbox">{`Breakfast available`}</div>
          </div>
        </Col>
      </Row>
      <h3 className="mp-sub-heading-margin">{`Other information`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Checkbox
                id="mp-checkbox-3"
                name="mp-service-provider-payment-checkbox"
                style={{ marginLeft: -13, marginTop: 3 }}
                disabled={mode == 2}
                color={mode == 2 ? "default" : "secondary"}
                checked={
                  providerToCreate?.checkupInfo?.otherInfo
                    ?.advancePaymentRequired != null
                    ? providerToCreate?.checkupInfo?.otherInfo
                        ?.advancePaymentRequired
                      ? true
                      : false
                    : false
                }
                onChange={(value, proxy) => {
                  providerToCreate?.checkupInfo?.otherInfo
                    ?.advancePaymentRequired == true
                    ? setProviderToCreate({
                        ...providerToCreate,
                        checkupInfo: {
                          ...providerToCreate.checkupInfo,
                          otherInfo: {
                            ...providerToCreate.checkupInfo.otherInfo,
                            advancePaymentRequired: false,
                          },
                        },
                      })
                    : setProviderToCreate({
                        ...providerToCreate,
                        checkupInfo: {
                          ...providerToCreate.checkupInfo,
                          otherInfo: {
                            ...providerToCreate.checkupInfo.otherInfo,
                            advancePaymentRequired: true,
                          },
                        },
                      });
                }}
              />
            </div>
            <div className="mp-label-checkbox">
              {`Advance payment required`}
            </div>
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Checkbox
                id="mp-checkbox-4"
                name="mp-service-provider-auto-booking-confirmation-checkbox"
                style={{ marginLeft: -13, marginTop: 3 }}
                disabled={mode == 2}
                color={mode == 2 ? "default" : "secondary"}
                checked={
                  providerToCreate?.checkupInfo?.otherInfo
                    ?.autoBookingConfirmation != null
                    ? providerToCreate?.checkupInfo?.otherInfo
                        ?.autoBookingConfirmation
                      ? true
                      : false
                    : false
                }
                onChange={(value, proxy) => {
                  providerToCreate?.checkupInfo?.otherInfo
                    ?.autoBookingConfirmation == true
                    ? setProviderToCreate({
                        ...providerToCreate,
                        checkupInfo: {
                          ...providerToCreate.checkupInfo,
                          otherInfo: {
                            ...providerToCreate.checkupInfo.otherInfo,
                            autoBookingConfirmation: false,
                          },
                        },
                      })
                    : setProviderToCreate({
                        ...providerToCreate,
                        checkupInfo: {
                          ...providerToCreate.checkupInfo,
                          otherInfo: {
                            ...providerToCreate.checkupInfo.otherInfo,
                            autoBookingConfirmation: true,
                          },
                        },
                      });
                }}
              />
            </div>
            <div className="mp-label-checkbox">
              {`Automatic confirmation of booking`}
            </div>
          </div>
        </Col>
      </Row>

      {
        <AvailabilityCalendar
          availability={providerToCreate.checkupInfo.availability}
          setAvailability={setAvailability}
          mode={mode} // if 2 -> view mode , else , edit/create
          headings={calendarHeadings}
        />
      }
      <h3 className="mp-sub-heading-margin">{`Master booking details`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Duration (mins)`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              id={"service-provider-Master-booking-duration"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              type="text"
              value={`${providerToCreate?.checkupInfo?.availability?.config?.slotDurationInMinutes}`}
              onChange={(e) => {
                let value = e.replace(/\D/g, "");
                if (value.length > 8) return;

                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    availability: {
                      ...providerToCreate.checkupInfo.availability,
                      config: {
                        ...providerToCreate.checkupInfo.availability.config,
                        slotDurationInMinutes: value == "" ? "" : Number(value),
                      },
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Booking per slot`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              id={"service-provider-Master-booking-per-slot"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              type="text"
              value={`${providerToCreate?.checkupInfo?.availability?.config?.bookingPerSlot}`}
              onChange={(e) => {
                let value = e.replace(/\D/g, "");
                if (value.length > 8) return;

                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    availability: {
                      ...providerToCreate.checkupInfo.availability,
                      config: {
                        ...providerToCreate.checkupInfo.availability.config,
                        bookingPerSlot: value == "" ? "" : Number(value),
                      },
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Min booking notice (days)`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              id={"service-provider-Master-booking-notice-days"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              type="text"
              value={`${providerToCreate?.checkupInfo?.availability?.config?.minBookingDays}`}
              onChange={(e) => {
                let value = e.replace(/\D/g, "");
                if (value.length > 8) return;

                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    availability: {
                      ...providerToCreate.checkupInfo.availability,
                      config: {
                        ...providerToCreate.checkupInfo.availability.config,
                        minBookingDays: value == "" ? "" : Number(value),
                      },
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Max advance booking (days)`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              id={"service-provider-Master-max-advance-booking"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              type="text"
              value={`${providerToCreate?.checkupInfo?.availability?.config?.maxAdvBookingDays}`}
              onChange={(e) => {
                let value = e.replace(/\D/g, "");
                if (value.length > 8) return;

                setProviderToCreate({
                  ...providerToCreate,
                  checkupInfo: {
                    ...providerToCreate.checkupInfo,
                    availability: {
                      ...providerToCreate.checkupInfo.availability,
                      config: {
                        ...providerToCreate.checkupInfo.availability.config,
                        maxAdvBookingDays: value == "" ? "" : Number(value),
                      },
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      {showBackdrop ? <SimpleBackdrop /> : <></>}
    </>
  );
};

export default ServiceProviderHealthCheckup;
