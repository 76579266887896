import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, Autocomplete, TextField } from "react-md";
import { Checkbox } from "@material-ui/core";
import FontIcon from "react-md/lib/FontIcons";
import { Select, MenuItem } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "../styles/index.css";
import UploadFileIcon from "../../../../../assets/icons/upload-file-icon.png";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import EventService from "../../../../../service/event/EventService";
import CreateServiceProviderUtil from "../services/CreateServiceProviderUtil";
import CreateServiceUtil from "../services/CreateServiceUtil";
import moment from "moment";
import MultipleSelectChip from "../helper/MultipleSelectChip";
import AuthenticationService from "../../../../../service/auth/AuthenticationService";
import AvailabilityCalendar from "../helper/AvailabilityCalendar";

export const ServiceProviderConsultation = ({
  providerToCreate,
  setProviderToCreate,
  mode,
  calendarHeadings,
  countries,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [doctorFetched, setDoctorFetched] = useState(null);
  const [servicesSubTypeArr, setServicesSubTypeArr] = useState([]);
  const [servicesSubTypeObj, setServicesSubTypeObj] = useState([]);
  const [countryCodeArr, setCountryCodeArr] = useState([]);
  const [countryCodeObj, setCountryCodeObj] = useState([]);
  const [languagesArr, setLanguagesArr] = useState([]);
  const [mobileViewImage, setMobileViewImage] = useState(null);
  const [desktopViewImage, setDesktopViewImage] = useState(null);
  const [mobileViewPreview, setMobileViewPreview] = useState(null);
  const [desktopViewPreview, setDesktopViewPreview] = useState(null);
  const [altContactNumber, setAltContactNumber] = useState([]);
  const [altEmail, setAltEmail] = useState([]);
  const [spocDetails, setSpocDetails] = useState([
    {
      name: "",
      countryCode: 91,
      phoneNo: "",
      emailId: "",
    },
  ]);
  const createServiceProviderUtil = new CreateServiceProviderUtil();
  const createServiceUtil = new CreateServiceUtil();
  const authenticationService = new AuthenticationService();

  useEffect(() => {
    getServiceSubType(providerToCreate?.serviceTypeId);
    getLanguages();
    setCountryCodeArr(countries);
    if (mode == 2 || mode == 3) {
      let contentBlock = htmlToDraft(
        providerToCreate?.consultationInfo?.identityDetails?.longDescription
      );
      if (contentBlock) {
        let contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        let editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
      if (
        providerToCreate.consultationInfo.contactDetails
          .alternateContactNumber &&
        providerToCreate.consultationInfo.contactDetails.alternateContactNumber
          .length > 0
      ) {
        let altPhoneArr =
          providerToCreate.consultationInfo.contactDetails.alternateContactNumber.map(
            (e) => {
              return {
                countryCode: e.countryCode,
                phoneNo: e.phoneNo,
              };
            }
          );
        setAltContactNumber(altPhoneArr);
      }
      if (
        providerToCreate.consultationInfo.contactDetails.alternateEmails &&
        providerToCreate.consultationInfo.contactDetails.alternateEmails
          .length > 0
      ) {
        setAltEmail(
          providerToCreate.consultationInfo.contactDetails.alternateEmails
        );
      }
      if (
        providerToCreate.consultationInfo.contactDetails.spoc &&
        providerToCreate.consultationInfo.contactDetails.spoc.length > 0
      ) {
        setSpocDetails(providerToCreate.consultationInfo.contactDetails.spoc);
      }
    }
  }, []);
  useEffect(() => {
    let dropdownObj = servicesSubTypeArr.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
    setServicesSubTypeObj(dropdownObj);
  }, [servicesSubTypeArr]);
  useEffect(() => {
    let dropdownObj = countryCodeArr?.map((e) => {
      return {
        label: `+${e.code}`,
        value: e.code,
      };
    });
    console.log("pr- obj cc=", dropdownObj);
    if (mode == 1) {
      setProviderToCreate((prev) => {
        let providerToCreate = prev;
        return {
          ...providerToCreate,
          consultationInfo: {
            ...providerToCreate.consultationInfo,
            identityDetails: {
              ...providerToCreate.consultationInfo.identityDetails,
              showPracticeStartDate: false,
            },
            contactDetails: {
              ...providerToCreate.consultationInfo.contactDetails,
              callingNumber: {
                ...providerToCreate.consultationInfo.contactDetails
                  .callingNumber,
                countryCode: 91,
                phoneNo: "",
              },
            },
          },
        };
      });
    }

    setCountryCodeObj(dropdownObj);
  }, [countryCodeArr]);

  useEffect(() => {
    setProviderToCreate((prev) => {
      let providerToCreate = prev;
      return {
        ...providerToCreate,
        consultationInfo: {
          ...providerToCreate.consultationInfo,
          contactDetails: {
            ...providerToCreate.consultationInfo.contactDetails,
            alternateContactNumber: altContactNumber,
          },
        },
      };
    });
  }, [altContactNumber]);
  useEffect(() => {
    setProviderToCreate((prev) => {
      let providerToCreate = prev;
      return {
        ...providerToCreate,
        consultationInfo: {
          ...providerToCreate.consultationInfo,
          contactDetails: {
            ...providerToCreate.consultationInfo.contactDetails,
            alternateEmails: altEmail,
          },
        },
      };
    });
  }, [altEmail]);
  useEffect(() => {
    setProviderToCreate((prev) => {
      let providerToCreate = prev;
      return {
        ...providerToCreate,
        consultationInfo: {
          ...providerToCreate.consultationInfo,
          contactDetails: {
            ...providerToCreate.consultationInfo.contactDetails,
            spoc: spocDetails,
          },
        },
      };
    });
  }, [spocDetails]);

  useEffect(() => {
    if (desktopViewImage != null) {
      uploadImage(desktopViewImage)
        .then((e) => {
          let obj = {
            ...providerToCreate,
            consultationInfo: {
              ...providerToCreate.consultationInfo,
              identityDetails: {
                ...providerToCreate.consultationInfo.identityDetails,
                desktopCover: e,
              },
            },
          };
          setProviderToCreate(obj);
        })
        .catch((err) => {
          EventService.emit("showError", {
            message: `Something went wrong while uploading File: ${err}`,
          });
        });
    }
  }, [desktopViewImage]);
  useEffect(() => {
    if (mobileViewImage != null) {
      uploadImage(mobileViewImage)
        .then((e) => {
          let obj = {
            ...providerToCreate,
            consultationInfo: {
              ...providerToCreate.consultationInfo,
              identityDetails: {
                ...providerToCreate.consultationInfo.identityDetails,
                mobileCover: e,
              },
            },
          };
          setProviderToCreate(obj);
        })
        .catch((err) => {
          EventService.emit("showError", {
            message: `Something went wrong while uploading File: ${err}`,
          });
        });
    }
  }, [mobileViewImage]);

  let getServiceSubType = (id) => {
    let onResponse = (res) => {
      setServicesSubTypeArr((prev) => {
        const updatedState = [...res];
        return updatedState;
      });
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceUtil.getServiceSubType(id, true, onResponse, onError);
  };

  let getLanguages = () => {
    let onResponse = (res) => {
      const languages = res.map((item) => item.name);
      setLanguagesArr(languages);
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceProviderUtil.getLanguage(onResponse, onError);
  };
  let setSelectedLanguages = (arr) => {
    setProviderToCreate({
      ...providerToCreate,
      consultationInfo: {
        ...providerToCreate.consultationInfo,
        identityDetails: {
          ...providerToCreate.consultationInfo.identityDetails,
          languages: arr,
        },
      },
    });
  };

  const phoneNoLen = (cc) => {
    return countries?.find((c) => c.code == cc).phoneNoLen;
    // return 10;
  };
  const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", file, file.name);

      createServiceUtil.uploadImg(
        data,
        (res) => {
          resolve(res.publicUrl);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
  const calculateExp = () => {
    let date1 = moment(
      `${moment(
        providerToCreate?.consultationInfo?.identityDetails?.practiceStartDate
      ).format("YYYY-MM-DD")}`
    );
    let todayDate = moment(`${moment().format("YYYY-MM-DD")}`);
    let monthsDiff = todayDate.diff(date1, "months");
    let str = "";

    if (monthsDiff >= 12) {
      const years = Math.floor(monthsDiff / 12);
      const months = monthsDiff % 12;

      if (months === 0) {
        str = `${years} ${years === 1 ? "year" : "years"}`;
      } else {
        str = `${years} ${years === 1 ? "year" : "years"} / ${months} ${
          months === 1 ? "month" : "months"
        }`;
      }
    } else {
      str = `${monthsDiff} ${monthsDiff === 1 ? "month" : "months"}`;
    }
    return str;
  };
  const addAlternateContact = () => {
    if (altContactNumber == []) {
      setAltContactNumber([
        {
          countryCode: 91,
          phoneNo: "",
        },
      ]);
    } else {
      setAltContactNumber([
        ...altContactNumber,
        {
          countryCode: 91,
          phoneNo: "",
        },
      ]);
    }
  };
  const removeAltPhone = (index) => {
    setAltContactNumber((prev) => prev.filter((_, i) => i !== index));
  };
  const setAltPhoneCC = (e, index) => {
    setAltContactNumber((prev) =>
      prev.map((item, i) => (i === index ? { ...item, countryCode: e } : item))
    );
  };
  const setAltPhone = (e, index) => {
    setAltContactNumber((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, phoneNo: e == "" ? "" : Number(e) } : item
      )
    );
  };
  const addAlternateEmail = () => {
    if (altEmail == []) {
      setAltEmail([""]);
    } else {
      setAltEmail([...altEmail, ""]);
    }
  };
  const removeAltEmail = (index) => {
    setAltEmail((prev) => prev.filter((_, i) => i !== index));
  };
  const setAltEmails = (e, index) => {
    setAltEmail((prev) => prev.map((item, i) => (i === index ? e : item)));
  };

  const addSpocRow = () => {
    setSpocDetails([
      ...spocDetails,
      {
        name: "",
        countryCode: 91,
        phoneNo: "",
        emailId: "",
      },
    ]);
  };
  const removeSpocRow = (index) => {
    setSpocDetails((prev) => prev.filter((_, i) => i !== index));
  };
  const setSpocData = (e, index) => {
    setSpocDetails((prev) => prev.map((item, i) => (i === index ? e : item)));
  };

  let SERVICE_PROVIDER_STATUS = [
    {
      label: "Active",
      value: "true",
    },
    {
      label: "Inactive",
      value: "false",
    },
  ];
  const _onEditorStateChange = (editorState) => {
    let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setProviderToCreate({
      ...providerToCreate,
      consultationInfo: {
        ...providerToCreate.consultationInfo,
        identityDetails: {
          ...providerToCreate.consultationInfo.identityDetails,
          longDescription: body,
        },
      },
    });
    setEditorState(editorState);
  };
  const doctorInfoIsValid = (doc) => {
    if (doc.licenseNumber == "" || doc.licenseNumber == null) {
      EventService.emit("showError", {
        message: "Error: Fetched expert's license number is empty",
      });
      return false;
    } else if (doc.title == "" || doc.title == null) {
      EventService.emit("showError", {
        message: "Error: Fetched expert's prefix is empty",
      });
      return false;
    } else if (doc.name == "" || doc.name == null) {
      EventService.emit("showError", {
        message: "Error: Fetched expert's name is empty",
      });
      return false;
    } else if (doc.category == "" || doc.category == null) {
      EventService.emit("showError", {
        message: "Error: Fetched expert's service subtype  is empty",
      });
      return false;
    } else if (doc.countryCode == "" || doc.countryCode == null) {
      EventService.emit("showError", {
        message: "Error: Fetched expert's phone country code  is empty",
      });
      return false;
    } else if (doc.phoneno == "" || doc.phoneno == null) {
      EventService.emit("showError", {
        message: "Error: Fetched expert's phone number  is empty",
      });
      return false;
    } else if (doc.email == "" || doc.email == null) {
      EventService.emit("showError", {
        message: "Error: Fetched expert's email is empty",
      });
      return false;
    } else {
      return true;
    }
  };
  const fetchDoctorDetails = (docId) => {
    let onResponse = (res) => {
      if (res.status == 3) {
        EventService.emit("showError", {
          message: "Error: Doctor status is 3",
        });
      }
      if (doctorInfoIsValid(res) && res.status != 3) {
        setDoctorFetched(res);
        let name = {
          ...providerToCreate,
          serviceSubtypeId: res.category,
          consultationInfo: {
            ...providerToCreate.consultationInfo,
            identityDetails: {
              ...providerToCreate.consultationInfo.identityDetails,
              name: res.name,
              mobileCover: res.profile_image,
              licenseNo: res.licenseNumber,
              namePrefix: res.title,
            },
            contactDetails: {
              ...providerToCreate.consultationInfo.contactDetails,
              contactNumber: {
                countryCode: res.countryCode,
                phoneNo: res.phoneno,
              },
              emailId: res.email,
            },
          },
        };
        setProviderToCreate(name);
      }
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceProviderUtil.fetchDoctorDetails(docId, onResponse, onError);
  };

  const fetchPincodeDetails = (pincode) => {
    let onResponse = (res) => {
      setProviderToCreate({
        ...providerToCreate,
        consultationInfo: {
          ...providerToCreate.consultationInfo,
          address: {
            ...providerToCreate.consultationInfo.address,
            pincode: res.pincode,
            district: res.district,
            state: res.state,
          },
        },
      });
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceProviderUtil.getPincodeDetails(pincode, onResponse, onError);
  };
  let setAvailability = (availability1) => {
    setProviderToCreate({
      ...providerToCreate,
      consultationInfo: {
        ...providerToCreate.consultationInfo,
        availability: availability1,
      },
    });
  };

  return (
    <>
      {console.log("pr- ptc", providerToCreate)}
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Status`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <SelectField
                id={"mp-service-provider-status"}
                placeholder="Select"
                disabled={mode == 2}
                className="mp-select-field"
                menuItems={SERVICE_PROVIDER_STATUS}
                value={providerToCreate?.isActive}
                onChange={(e) =>
                  setProviderToCreate({ ...providerToCreate, isActive: e })
                }
              />
            </div>
          </div>
        </Col>
      </Row>
      <h3 className="mp-sub-heading-margin">{`Personal details`}</h3>

      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Expert ID`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              {" "}
              <TextField
                id={"mp-doctor-id"}
                lineDirection="left"
                type="text"
                disabled={mode == 2}
                value={
                  providerToCreate?.consultationInfo?.identityDetails
                    ?.doctorId ?? ""
                }
                placeholder="Type here"
                inlineIndicator={
                  <Button icon disabled={mode == 2} style={{ margin: "-10px" }}>
                    <FontIcon
                      style={{
                        color: "orange",
                        lineHeight: "0",
                        marginBottom: "15px",
                      }}
                      onClick={() => {
                        if (
                          providerToCreate.consultationInfo.identityDetails
                            .doctorId != undefined &&
                          providerToCreate.consultationInfo.identityDetails
                            .doctorId != ""
                        ) {
                          fetchDoctorDetails(
                            providerToCreate?.consultationInfo?.identityDetails
                              ?.doctorId
                          );
                        }
                      }}
                    >
                      arrow_forward
                    </FontIcon>
                  </Button>
                }
                onChange={(e) => {
                  let value = e.replace(/\D/g, "");

                  if (value.length > 8) return;

                  setDoctorFetched(null);
                  let provider = {
                    ...providerToCreate,
                    serviceSubtypeId: "",
                    consultationInfo: {
                      ...providerToCreate.consultationInfo,
                      identityDetails: {
                        ...providerToCreate.consultationInfo.identityDetails,
                        doctorId: value === "" ? "" : Number(value),
                        name: "",
                        namePrefix: "",
                        mobileCover: "",
                        licenseNo: "",
                      },
                      contactDetails: {
                        ...providerToCreate.consultationInfo.contactDetails,
                        contactNumber: {},
                        emailId: "",
                      },
                    },
                  };

                  setMobileViewPreview(null);
                  setMobileViewImage(null);
                  setProviderToCreate(provider);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-create-service-field-container">
            <div className="mp-label">
              {`License number`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <TextField
                id={"mp-service-provider-name"}
                disabled
                lineDirection="left"
                // placeholder="Name"
                value={`${
                  providerToCreate?.consultationInfo?.identityDetails
                    ?.licenseNo != null
                    ? providerToCreate?.consultationInfo?.identityDetails
                        ?.licenseNo
                    : ""
                }`}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Prefix`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <TextField
                id={"mp-service-provider-name"}
                disabled
                lineDirection="left"
                // placeholder="Name"
                value={`${
                  providerToCreate?.consultationInfo?.identityDetails
                    ?.namePrefix != null
                    ? providerToCreate?.consultationInfo?.identityDetails
                        ?.namePrefix
                    : ""
                }`}
              />
              {/* <SelectField
                id={"mp-doctor-name-prefix"}
                placeholder="Select"
                className="mp-select-field"
                disabled={mode == 2}
                menuItems={PREFIX}
                value={`${providerToCreate?.consultationInfo?.identityDetails?.namePrefix}`}
                onChange={(e) => {
                  setProviderToCreate({
                    ...providerToCreate,
                    consultationInfo: {
                      ...providerToCreate.consultationInfo,
                      identityDetails: {
                        ...providerToCreate.consultationInfo.identityDetails,
                        namePrefix: e,
                      },
                    },
                  });
                }}
              /> */}
            </div>
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div style={{ height: "20px" }}></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-create-service-field-container">
            <div className="mp-label">
              {`Service provider name`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <TextField
                id={"mp-service-provider-name"}
                disabled
                lineDirection="left"
                // placeholder="Name"
                value={`${
                  providerToCreate?.consultationInfo?.identityDetails?.name !=
                  null
                    ? providerToCreate?.consultationInfo?.identityDetails?.name
                    : ""
                }`}
              />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div style={{ height: "20px" }}></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Service subtype (Category)`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <SelectField
                id={"mp-service-provider-service-subtype"}
                placeholder="Category"
                className="mp-select-field"
                disabled
                menuItems={servicesSubTypeObj}
                value={providerToCreate?.serviceSubtypeId}
                onChange={(e) => {
                  setProviderToCreate({
                    ...providerToCreate,
                    serviceSubtypeId: e,
                  });
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          {providerToCreate?.consultationInfo?.identityDetails?.mobileCover !=
            null &&
            providerToCreate?.consultationInfo?.identityDetails?.mobileCover !=
              "" && (
              <>
                <p className="mp-text-headings">{`Image (Mobile view):`}</p>
                <div
                  style={{
                    display: "inline-block",
                    border: "1px solid black",
                  }}
                >
                  <img
                    src={
                      providerToCreate.consultationInfo.identityDetails
                        .mobileCover
                    }
                    alt="Selected Preview"
                    style={{
                      width: "100px",
                      height: "auto",
                      display: "block",
                    }}
                  />
                </div>
              </>
            )}
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div style={{ height: "20px" }}></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          {providerToCreate?.consultationInfo?.identityDetails?.desktopCover !=
            null &&
            providerToCreate?.consultationInfo?.identityDetails?.desktopCover !=
              "" && (
              <>
                <p className="mp-text-headings">{`Image (Desktop view):`}</p>
                <div
                  style={{
                    display: "inline-block",
                    border: "1px solid black",
                  }}
                >
                  <img
                    src={
                      providerToCreate.consultationInfo.identityDetails
                        .desktopCover
                    }
                    alt="Selected Preview"
                    style={{
                      width: "100px",
                      height: "auto",
                      display: "block",
                    }}
                  />
                </div>
              </>
            )}
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Service provider practice start`}
              <label className="mp-label-red"> *</label>
            </div>
            <div style={{ paddingTop: "20px" }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  style={{ width: "100%" }}
                  value={
                    providerToCreate?.consultationInfo?.identityDetails
                      ?.practiceStartDate
                      ? moment(
                          providerToCreate?.consultationInfo?.identityDetails
                            ?.practiceStartDate
                        ).utc()
                      : null
                  }
                  onChange={(date) => {
                    setProviderToCreate({
                      ...providerToCreate,
                      consultationInfo: {
                        ...providerToCreate.consultationInfo,
                        identityDetails: {
                          ...providerToCreate.consultationInfo.identityDetails,
                          practiceStartDate: moment(date)
                            .utc()
                            .startOf("day")
                            .toISOString(),
                        },
                      },
                    });
                  }}
                  autoOk={true}
                  className="mp-consultation-exp-datepicker"
                  format="DD MMM YYYY"
                  placeholder="DD MM YYYY"
                  keyboardIcon={<FontIcon>calendar_month</FontIcon>}
                  variant="inline"
                  maxDate={moment().utc()}
                  disabled={mode == 2}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={1} md={1} lg={1}>
          <div style={{ height: "20px" }}></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Checkbox
                id="mp-checkbox"
                disabled={
                  providerToCreate?.consultationInfo?.identityDetails
                    ?.practiceStartDate == null || mode == 2
                }
                color={mode == 2 ? "default" : "secondary"}
                name="mp-service-provider-experience-checkbox"
                style={{ marginLeft: -13, marginTop: 3 }}
                checked={
                  providerToCreate?.consultationInfo?.identityDetails
                    ?.showPracticeStartDate != null
                    ? providerToCreate.consultationInfo.identityDetails
                        .showPracticeStartDate == true
                      ? true
                      : false
                    : false
                }
                onChange={(value, proxy) => {
                  providerToCreate?.consultationInfo?.identityDetails
                    ?.showPracticeStartDate == true
                    ? setProviderToCreate({
                        ...providerToCreate,
                        consultationInfo: {
                          ...providerToCreate.consultationInfo,
                          identityDetails: {
                            ...providerToCreate.consultationInfo
                              .identityDetails,
                            showPracticeStartDate: false,
                          },
                        },
                      })
                    : setProviderToCreate({
                        ...providerToCreate,
                        consultationInfo: {
                          ...providerToCreate.consultationInfo,
                          identityDetails: {
                            ...providerToCreate.consultationInfo
                              .identityDetails,
                            showPracticeStartDate: true,
                          },
                        },
                      });
                }}
              />
            </div>
            <div className="mp-label-checkbox">
              {`Show service provider experience on UI`}
            </div>
          </div>

          <div
            className="mp-checkbox-field-container"
            style={{ marginTop: "-16px" }}
          >
            <div>
              <TextField
                disabled
                lineDirection="left"
                placeholder="Years/Months"
                value={
                  providerToCreate?.consultationInfo?.identityDetails
                    ?.practiceStartDate != null
                    ? calculateExp()
                    : ""
                }
              />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Language known`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              {languagesArr.length > 0 && (
                <MultipleSelectChip
                  disabled={mode == 2}
                  names={languagesArr}
                  setValues={setSelectedLanguages}
                  selected={
                    mode == 2 || mode == 3
                      ? providerToCreate.consultationInfo.identityDetails
                          .languages.length > 0
                        ? providerToCreate.consultationInfo.identityDetails
                            .languages
                        : null
                      : null
                  }
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={11} md={11} lg={11}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Service provider short description (50 characters)`}
              <label className="mp-label-red"> *</label>
            </div>
            <div>
              <TextField
                disabled={mode == 2}
                placeholder="Type here"
                value={
                  providerToCreate?.consultationInfo?.identityDetails
                    ?.shortdescription
                    ? `${providerToCreate?.consultationInfo?.identityDetails?.shortdescription}`
                    : ""
                }
                lineDirection="left"
                // placeholder="Enter short description for the service"
                error={
                  providerToCreate?.consultationInfo?.identityDetails
                    ?.shortdescription != null
                    ? providerToCreate.consultationInfo.identityDetails
                        .shortdescription >= 50
                      ? true
                      : false
                    : false
                }
                errorText="Short description can be of max 50 characters only"
                onChange={(e) => {
                  if (e.length <= 50) {
                    setProviderToCreate({
                      ...providerToCreate,
                      consultationInfo: {
                        ...providerToCreate.consultationInfo,
                        identityDetails: {
                          ...providerToCreate.consultationInfo.identityDetails,
                          shortdescription: e,
                        },
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={11} md={11} lg={11}>
          <div className="mp-field-container">
            <div className="mp-label-2">
              {`Service provider long description`}
              <label className="mp-label-red"> *</label>
            </div>
            <div
              style={{ borderBottom: "2px solid #E4E4E4", marginTop: "10px" }}
            >
              <Editor
                // toolbarClassName={showToolbar ? null : "toolBar-hidden"}
                // toolbarClassName={showToolbar ? null : " toolBar-hidden-pn"}

                readOnly={mode == 2 ? true : false}
                editorState={editorState}
                wrapperClassName=""
                editorClassName=""
                // hashtag={{
                //   separator: " ",
                //   trigger: "#",
                // }}
                onEditorStateChange={_onEditorStateChange}
                stripPastedStyles={true}
                // placeholder={
                //   <label>{`Please enter service long description`}</label>
                // }
              />
            </div>
          </div>
        </Col>
      </Row>
      <h3 className="mp-sub-heading-margin">{`Contact details`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Contact number`}
              <label className="mp-label-red"> *</label>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "40px", marginRight: "20px" }}>
                <TextField
                  id={"service-provider-contact-number-cc"}
                  lineDirection="left"
                  disabled
                  placeholder="+XX"
                  value={
                    providerToCreate?.consultationInfo?.contactDetails
                      ?.contactNumber?.countryCode != null
                      ? `+${providerToCreate?.consultationInfo?.contactDetails?.contactNumber?.countryCode}`
                      : ""
                  }
                />
              </div>
              <div style={{ flex: "1" }}>
                <TextField
                  id={"service-provider-contact-number-phone"}
                  lineDirection="left"
                  disabled
                  placeholder="Phone number"
                  value={
                    providerToCreate?.consultationInfo?.contactDetails
                      ?.contactNumber?.phoneNo != null
                      ? `${providerToCreate?.consultationInfo?.contactDetails?.contactNumber?.phoneNo}`
                      : ""
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div
              className="mp-add-options"
              onClick={() => {
                if (mode != 2) {
                  addAlternateContact();
                }
              }}
            >{`+ Add alternate contact number`}</div>
            {altContactNumber.map((e, index) => {
              return (
                <div>
                  <div className="mp-label" style={{ marginTop: "5px" }}>
                    {`Alternate number`}
                  </div>
                  <div className="mp-phone-container">
                    <div style={{ width: "40px", marginRight: "20px" }}>
                      <SelectField
                        id={`service-provider-alt-contact-number-${index}-cc`}
                        placeholder="+XX"
                        disabled={mode == 2}
                        className="mp-select-field"
                        menuItems={countryCodeObj}
                        value={e.countryCode}
                        onChange={(e) => setAltPhoneCC(e, index)}
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <TextField
                        id={`service-provider-alt-contact-number-${index}-phone`}
                        type="text"
                        disabled={mode == 2}
                        lineDirection="left"
                        placeholder={`Alternate phone ${index + 1}`}
                        value={e.phoneNo != null ? `${e.phoneNo}` : ""}
                        onChange={(elem) => {
                          let value = elem.replace(/\D/g, "");
                          if (value.length <= phoneNoLen(e.countryCode)) {
                            setAltPhone(value, index);
                          }
                        }}
                      />
                    </div>
                    <div
                      className="mp-alt-phone-remove"
                      onClick={() => {
                        if (mode != 2) {
                          removeAltPhone(index);
                        }
                      }}
                    >
                      <FontIcon style={{ fontSize: "16px" }}>close</FontIcon>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Mobile number used to call patient`}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "40px", marginRight: "20px" }}>
                <SelectField
                  id={"mp-service-provider-calling-phone-cc"}
                  placeholder="+XX"
                  disabled={mode == 2}
                  className="mp-select-field"
                  menuItems={countryCodeObj}
                  value={
                    providerToCreate?.consultationInfo?.contactDetails
                      ?.callingNumber?.countryCode
                  }
                  onChange={(e) =>
                    setProviderToCreate({
                      ...providerToCreate,
                      consultationInfo: {
                        ...providerToCreate.consultationInfo,
                        contactDetails: {
                          ...providerToCreate.consultationInfo.contactDetails,
                          callingNumber: {
                            ...providerToCreate.consultationInfo.contactDetails
                              .callingNumber,
                            countryCode: e,
                          },
                        },
                      },
                    })
                  }
                />
              </div>
              <div style={{ flex: "1" }}>
                <TextField
                  id={"service-provider-contact-number-phone"}
                  lineDirection="left"
                  placeholder="Enter phone number"
                  disabled={mode == 2}
                  type="text"
                  value={
                    providerToCreate?.consultationInfo?.contactDetails
                      ?.callingNumber?.phoneNo != null
                      ? `${providerToCreate?.consultationInfo?.contactDetails?.callingNumber?.phoneNo}`
                      : ""
                  }
                  onChange={(e) => {
                    let value = e.replace(/\D/g, "");
                    if (
                      value.length <=
                      phoneNoLen(
                        providerToCreate?.consultationInfo?.contactDetails
                          ?.callingNumber?.countryCode
                      )
                    ) {
                      setProviderToCreate({
                        ...providerToCreate,
                        consultationInfo: {
                          ...providerToCreate.consultationInfo,
                          contactDetails: {
                            ...providerToCreate.consultationInfo.contactDetails,
                            callingNumber: {
                              ...providerToCreate.consultationInfo
                                .contactDetails.callingNumber,
                              phoneNo: value == "" ? "" : Number(value),
                            },
                          },
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">
              {`Email Id`}
              <label className="mp-label-red"> *</label>
            </div>

            <TextField
              id={"service-provider-contact-number-email"}
              lineDirection="left"
              placeholder="Type here"
              disabled
              value={
                providerToCreate?.consultationInfo?.contactDetails?.emailId !=
                null
                  ? `${providerToCreate?.consultationInfo?.contactDetails?.emailId}`
                  : ""
              }
            />
          </div>
          <div>
            <div
              className="mp-add-options"
              onClick={() => {
                if (mode != 2) {
                  addAlternateEmail();
                }
              }}
            >{`+ Add alternate email ID`}</div>
            {altEmail.map((e, index) => {
              return (
                <div>
                  <div className="mp-label" style={{ marginTop: "5px" }}>
                    {`Alternate email`}
                  </div>
                  <div className="mp-email-container">
                    <div style={{ flex: "1" }}>
                      <TextField
                        id={`service-provider-alt-email-${index}`}
                        lineDirection="left"
                        disabled={mode == 2}
                        value={e != null ? `${e}` : ""}
                        onChange={(e) => {
                          if (e.length <= 50) {
                            setAltEmails(e, index);
                          }
                        }}
                      />
                    </div>
                    <div
                      className="mp-alt-email-remove"
                      onClick={() => {
                        if (mode != 2) {
                          removeAltEmail(index);
                        }
                      }}
                    >
                      <FontIcon style={{ fontSize: "16px" }}>close</FontIcon>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Website link`}</div>

            <TextField
              id={"service-provider-contact-number-website"}
              lineDirection="left"
              disabled={mode == 2}
              placeholder="Type here"
              value={
                providerToCreate?.consultationInfo?.contactDetails?.website !=
                null
                  ? `${providerToCreate?.consultationInfo?.contactDetails?.website}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    contactDetails: {
                      ...providerToCreate.consultationInfo.contactDetails,
                      website: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <div className="mp-spoc-heading-container">
        <h3 className="mp-sub-heading-margin">{`SPOC details`}</h3>
        <div
          className="mp-add-options"
          style={{ padding: "5px" }}
          onClick={() => {
            if (mode != 2) {
              addSpocRow();
            }
          }}
        >{`+ Add new`}</div>
      </div>
      <Row>
        {spocDetails.map((e, index) => {
          return (
            <>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div
                  className="mp-field-container"
                  style={{ marginTop: "5px" }}
                >
                  <div className="mp-label">{`SPOC ${
                    index + 1
                  } full name`}</div>

                  <TextField
                    id={"service-provider-spoc-name"}
                    lineDirection="left"
                    disabled={mode == 2}
                    placeholder="Type here"
                    value={e.name != "" ? e.name : ""}
                    onChange={(elem) => {
                      let data = { ...e, name: elem };
                      setSpocData(data, index);
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div className="mp-between-two-side"></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-field-container">
                  <div className="mp-label">{`SPOC ${
                    index + 1
                  } contact number`}</div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "40px", marginRight: "20px" }}>
                      <SelectField
                        id={`service-provider-spoc-contact-number-cc-${index}`}
                        placeholder="+XX"
                        disabled={mode == 2}
                        className="mp-select-field"
                        menuItems={countryCodeObj}
                        value={e.countryCode}
                        onChange={(elem) => {
                          let data = { ...e, countryCode: elem };
                          setSpocData(data, index);
                        }}
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <TextField
                        id={"service-provider-spoc-contact-number-phone"}
                        lineDirection="left"
                        placeholder="Enter phone number"
                        type="text"
                        disabled={mode == 2}
                        value={e.phoneNo != "" ? e.phoneNo : ""}
                        onChange={(elem) => {
                          let value = elem.replace(/\D/g, "");
                          if (value.length <= phoneNoLen(e.countryCode)) {
                            let data = {
                              ...e,
                              phoneNo: value == "" ? "" : Number(value),
                            };
                            setSpocData(data, index);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div className="mp-between-two-side"></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-field-container">
                  <div className="mp-label">{`SPOC ${index + 1} email ID`}</div>

                  <TextField
                    id={"service-provider-contact-number-website"}
                    lineDirection="left"
                    placeholder="Type here"
                    disabled={mode == 2}
                    value={e.emailId != "" ? e.emailId : ""}
                    onChange={(elem) => {
                      let data = { ...e, emailId: elem };
                      setSpocData(data, index);
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                {index != 0 && (
                  <div
                    className="mp-spoc-remove"
                    onClick={() => {
                      if (mode != 2) {
                        removeSpocRow(index);
                      }
                    }}
                  >
                    <FontIcon style={{ fontSize: "16px" }}>close</FontIcon>
                  </div>
                )}
              </Col>
            </>
          );
        })}
      </Row>
      <h3 className="mp-sub-heading-margin">{`Address details`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Pincode`}</div>

            <TextField
              value={
                providerToCreate?.consultationInfo?.address?.pincode
                  ? `${providerToCreate?.consultationInfo?.address?.pincode}`
                  : ""
              }
              type="number"
              placeholder="Type here"
              disabled={mode == 2}
              lineDirection="left"
              onChange={(e) => {
                if (e.length <= 6) {
                  if (/^\d+$/.test(e) || e === "") {
                    setProviderToCreate({
                      ...providerToCreate,
                      consultationInfo: {
                        ...providerToCreate.consultationInfo,
                        address: {
                          ...providerToCreate.consultationInfo.address,
                          pincode: Number(e),
                        },
                      },
                    });
                  }

                  // if (e.length == 6) {
                  //   fetchPincodeDetails(e);
                  // }
                }
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`City`}</div>

            <TextField
              id={"service-provider-address-city"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              value={
                providerToCreate?.consultationInfo?.address?.city
                  ? `${providerToCreate?.consultationInfo?.address?.city}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    address: {
                      ...providerToCreate.consultationInfo.address,
                      city: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`District`}</div>

            <TextField
              id={"service-provider-address-district"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              value={
                providerToCreate?.consultationInfo?.address?.district
                  ? `${providerToCreate?.consultationInfo?.address?.district}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    address: {
                      ...providerToCreate.consultationInfo.address,
                      district: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`State`}</div>

            <TextField
              id={"service-provider-address-state"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              value={
                providerToCreate?.consultationInfo?.address?.state
                  ? `${providerToCreate?.consultationInfo?.address?.state}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    address: {
                      ...providerToCreate.consultationInfo.address,
                      state: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`House No. /Floor /Building`}</div>

            <TextField
              id={"service-provider-address-houseno"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              value={
                providerToCreate?.consultationInfo?.address?.housing
                  ? `${providerToCreate?.consultationInfo?.address?.housing}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    address: {
                      ...providerToCreate.consultationInfo.address,
                      housing: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Colony /Street /Area`}</div>

            <TextField
              id={"service-provider-address-area"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              value={
                providerToCreate?.consultationInfo?.address?.area
                  ? `${providerToCreate?.consultationInfo?.address?.area}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    address: {
                      ...providerToCreate.consultationInfo.address,
                      area: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Landmark/ Locality`}</div>

            <TextField
              id={"service-provider-address-landmark"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              value={
                providerToCreate?.consultationInfo?.address?.landmark
                  ? `${providerToCreate?.consultationInfo?.address?.landmark}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    address: {
                      ...providerToCreate.consultationInfo.address,
                      landmark: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={7} md={7} lg={7}>
          <div className="mp-field-container">
            <div className="mp-label">{`Google map link`}</div>

            <TextField
              id={"service-provider-address-google-map-link"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              value={
                providerToCreate?.consultationInfo?.address?.googleMapLink
                  ? `${providerToCreate?.consultationInfo?.address?.googleMapLink}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    address: {
                      ...providerToCreate.consultationInfo.address,
                      googleMapLink: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <h3 className="mp-sub-heading-margin">{`Reviews`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`CSAT Rating`}</div>

            <TextField
              id={"service-provider-address-rating"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              type="number"
              value={
                providerToCreate?.consultationInfo?.reviewData?.csatRating
                  ? `${providerToCreate?.consultationInfo?.reviewData?.csatRating}`
                  : ""
              }
              onChange={(e) => {
                const value = e === "" ? "" : Number(e);

                if (
                  value === "" ||
                  (Number.isInteger(value) && value >= 1 && value <= 5)
                ) {
                  setProviderToCreate({
                    ...providerToCreate,
                    consultationInfo: {
                      ...providerToCreate.consultationInfo,
                      reviewData: {
                        ...providerToCreate.consultationInfo.reviewData,
                        csatRating: value === "" ? null : value,
                      },
                    },
                  });
                }
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Google review link`}</div>

            <TextField
              id={"service-provider-address-google-review"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              value={
                providerToCreate?.consultationInfo?.reviewData?.googleReviewLink
                  ? `${providerToCreate?.consultationInfo?.reviewData?.googleReviewLink}`
                  : ""
              }
              onChange={(e) => {
                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    reviewData: {
                      ...providerToCreate.consultationInfo.reviewData,
                      googleReviewLink: e,
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />

      {
        <AvailabilityCalendar
          availability={providerToCreate.consultationInfo.availability}
          setAvailability={setAvailability}
          mode={mode}
          headings={calendarHeadings}
          // showOnlySchedule={true}
          // slotsValidationSchedule={
          //   providerToCreate.consultationInfo.availability.schedule
          // }
        />
      }
      <h3 className="mp-sub-heading-margin">{`Master booking details`}</h3>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Duration (mins)`}</div>

            <TextField
              id={"service-provider-Master-booking-duration"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              type="text"
              value={`${providerToCreate?.consultationInfo?.availability?.config?.slotDurationInMinutes}`}
              onChange={(e) => {
                let value = e.replace(/\D/g, "");
                if (value.length > 8) return;

                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    availability: {
                      ...providerToCreate.consultationInfo.availability,
                      config: {
                        ...providerToCreate.consultationInfo.availability
                          .config,
                        slotDurationInMinutes: value == "" ? "" : Number(value),
                      },
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Booking per slot`}</div>

            <TextField
              id={"service-provider-Master-booking-per-slot"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              type="text"
              value={`${providerToCreate?.consultationInfo?.availability?.config?.bookingPerSlot}`}
              onChange={(e) => {
                let value = e.replace(/\D/g, "");
                if (value.length > 8) return;

                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    availability: {
                      ...providerToCreate.consultationInfo.availability,
                      config: {
                        ...providerToCreate.consultationInfo.availability
                          .config,
                        bookingPerSlot: value == "" ? "" : Number(value),
                      },
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
      <div style={{ height: "20px" }} />
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Min booking notice (days)`}</div>

            <TextField
              id={"service-provider-Master-booking-notice-days"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              type="text"
              value={`${providerToCreate?.consultationInfo?.availability?.config?.minBookingDays}`}
              onChange={(e) => {
                let value = e.replace(/\D/g, "");
                if (value.length > 8) return;

                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    availability: {
                      ...providerToCreate.consultationInfo.availability,
                      config: {
                        ...providerToCreate.consultationInfo.availability
                          .config,
                        minBookingDays: value == "" ? "" : Number(value),
                      },
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div className="mp-between-two-side"></div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-field-container">
            <div className="mp-label">{`Max advance booking (days)`}</div>

            <TextField
              id={"service-provider-Master-max-advance-booking"}
              lineDirection="left"
              placeholder="Type here"
              disabled={mode == 2}
              type="text"
              value={`${providerToCreate?.consultationInfo?.availability?.config?.maxAdvBookingDays}`}
              onChange={(e) => {
                let value = e.replace(/\D/g, "");
                if (value.length > 8) return;

                setProviderToCreate({
                  ...providerToCreate,
                  consultationInfo: {
                    ...providerToCreate.consultationInfo,
                    availability: {
                      ...providerToCreate.consultationInfo.availability,
                      config: {
                        ...providerToCreate.consultationInfo.availability
                          .config,
                        maxAdvBookingDays: value == "" ? "" : Number(value),
                      },
                    },
                  },
                });
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ServiceProviderConsultation;
