import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  Button,
  SelectField,
  TextField,
  ExpansionList,
  ExpansionPanel,
} from "react-md";
import "../styles/createPlan.css";
import FontIcon from "react-md/lib/FontIcons";
import "../styles/assignServiceToPlan.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EventService from "../../../../../service/event/EventService";
import PlansUtils from "../services/PlansUtils";
import { Checkbox } from "@material-ui/core";
import RangeSlider from "../services/RangeSlider";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AssignedPlansUtil from "../services/AssignedPlansUtils";
import CreateServiceUtil from "../../service-module/services/CreateServiceUtil";
import ServiceDashboardUtils from "../../service-module/services/ServiceDashboardUtils";
import AssignServiceProviderUtil from "../../service-module/services/AssignServiceProviderUtil";
import ExpandableTableAssignedServiceProviders from "../helper/ExpandableTableAssignedServiceProviders";

export const AssignServiceToPlan = (props) => {
  const [planData, setPlanData] = useState({});
  const [formData, setFormData] = useState({
    isContinueClicked: false,
  });
  const [assignServiceToPlanModesData, setAssignServiceToPlanModesData] =
    useState({});
  const [
    extraAssignServiceToPlanModesData,
    setExtraAssignServiceToPlanModesData,
  ] = useState({});
  const [serviceTypeData, setServiceTypeData] = useState({});

  const [mode, setMode] = useState(1);
  const [accountDetails, setAccountDetails] = useState(null);
  const [familyRelationsArr, setFamilyRelationsArr] = useState([]);
  const [allRelations, setAllRelations] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [expandableTables, setExpandableTables] = useState(<></>);
  const [assignedServiceProviderPageNums, setAssignedServiceProviderPageNums] =
    useState({});
  const [pageSize, setPageSize] = useState(2);
  const [totalCount, setTotalCount] = useState(10);
  const [assignedServiceProviders, setAssignedServiceProviders] = useState([]);
  const [componentMounted, setComponentMounted] = useState(false);

  const plansUtils = new PlansUtils();
  const assignedPlansUtils = new AssignedPlansUtil();
  const createServiceUtil = new CreateServiceUtil();
  const assignServiceProviderUtil = new AssignServiceProviderUtil();
  const serviceDashboardUtils = new ServiceDashboardUtils();

  useEffect(() => {
    console.log("ni -> props in assignServiceToPlan", props);
    setComponentMounted(true);
    if (props?.data?.mode == "view" || props?.data?.mode == "update") {
      setFormData({
        isContinueClicked: true,
      });
      fetchAssignedServiceToPlan(props?.data?.planId, props?.data?.serviceId);
    }
    getPlanByFilter(props?.data?.planId);
    getFamilyRelations();
    getServiceTypes("true");
  }, []);

  useEffect(() => {
    if (planData?.accountCode != null) {
      getAccountByAccountCode(planData?.accountCode);
    }
  }, [planData]);

  useEffect(() => {
    console.log("ni- > asp -> ", assignedServiceProviders);

    // return Object.entries(data).map(([key, value]) => ({
    //   modeName: key,
    //   ...value,
    // }));

    if (componentMounted) {
      Object.entries(assignedServiceProviders).forEach(([key, value]) => {
        setExpandableTables((prev) => ({
          ...prev,
          [key]: (
            <ExpandableTableAssignedServiceProviders
              {...{
                data: {
                  planId: props?.data?.planId,
                  serviceMode: key,
                  assignedServiceProviders: value?.providers,
                },
                setView: props?.setView,
                goToPrevPage: navigateToPrevPage,
                goToNextPage: navigateToNextPage,
                fetchAssignedServiceProvidersByFilter:
                  fetchAssignedServiceProvidersByFilterHelper,
                totalCount: value?.totalCount,
                pageNum: assignedServiceProviderPageNums?.[key],
                pageSize: pageSize,
              }}
            />
          ),
        }));
      });

      console.log(
        " ni-> ExpandableTableAssignedServiceProviders -> ",
        expandableTables
      );
    }
  }, [assignedServiceProviders]);

  // useEffect(() => {
  //   console.log("ni- > asp -> ", assignedServiceProviders);

  //   if (props?.data?.editMode == "service-providers") {
  //     fetchAssignedServiceProvidersByFilterHelper(
  //       props?.data?.planId,
  //       props?.data?.serviceId,
  //       modeName
  //     );
  //   }

  // }, [assignedServiceProviderPageNums]);

  const navigateToNextPage = (modeName) => {
    if (assignedServiceProviderPageNums?.[modeName] * pageSize < totalCount) {
      if (props?.data?.editMode == "service-providers") {
        fetchAssignedServiceProvidersByFilterHelper(
          props?.data?.planId,
          props?.data?.serviceId,
          modeName,
          assignedServiceProviderPageNums?.[modeName] + 1
        );
      }
      setAssignedServiceProviderPageNums((prevPageNums) => ({
        ...prevPageNums,
        [modeName]: prevPageNums?.[modeName] + 1,
      }));
    }
  };

  const navigateToPrevPage = (modeName) => {
    if (assignedServiceProviderPageNums?.[modeName] > 1) {
      if (props?.data?.editMode == "service-providers") {
        fetchAssignedServiceProvidersByFilterHelper(
          props?.data?.planId,
          props?.data?.serviceId,
          modeName,
          assignedServiceProviderPageNums?.[modeName] - 1
        );
      }
      setAssignedServiceProviderPageNums((prevPageNums) => ({
        ...prevPageNums,
        [modeName]: prevPageNums?.[modeName] - 1,
      }));
    }
  };

  const EMPTY_PLAN_SERVCE_MODE_RULE = {
    relations: [],
    planAgeRule: {
      isApplicable: false,
      fromAge: 18,
      toAge: 60,
    },
    planGenderRule: {
      isApplicable: false,
      gender: 0,
    },
    planTimeRule: {
      isServiceAvailLimited: true,
      maxServiceAvailCount: 0,
      isTimeRuleApplicable: false,
      timeRuleApplicableDays: 0,
      isInclusiveOfAllRelations: false,
    },
    price: 0.0,
  };

  const removeKey = (obj, keyToRemove) => {
    const newObj = { ...obj }; // Create a shallow copy to avoid mutating the original object
    delete newObj[keyToRemove]; // Delete the key
    return newObj;
  };

  const fetchAssignedServiceProvidersByFilterHelper = (
    planId,
    serviceId,
    modeName,
    pageNumValue = null
  ) => {
    if (!planId || !serviceId || !modeName) {
      return;
    }
    // setPageNum(1);
    // setFilterObj(filterObj);
    // if (modesData) {
    //   modesData.forEach((e) => {
    //     // console.log("ni -> modeData -> ", e);
    //     if (e?.modeName) {
    fetchAssignedServiceProvidersByFilter(
      planId,
      serviceId,
      modeName,
      pageNumValue
    );
    //   }
    // });
    // }
  };

  const fetchAssignedServiceProvidersByFilter = (
    planId,
    serviceId,
    modeName,
    pageNumValue = null
  ) => {
    const onResponse = (res) => {
      console.log("ni- fetchAssignedServiceProvidersByFilter res=", res);
      // if (res?.services?.[0]) {
      if (props?.data?.editMode == "service-providers") {
        setAssignedServiceProviders((prev) => ({
          ...prev,
          [modeName]: {
            providers: res?.providers ?? [],
            totalCount: res?.totalCount,
          },
        }));
      }
      // } else {
      //   EventService.emit("showError", {
      //     message: `No service matched with the provided details.`,
      //   });
      // }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetchAssignedServiceProvidersByFilter: ${err?.message}`,
      });
    };
    assignedPlansUtils.fetchAssignedServiceProvidersByFilter(
      {
        pageNum: pageNumValue ?? assignedServiceProviderPageNums?.[modeName],
        pageSize: pageSize,
        serviceIds: [serviceId],
        planId: planId,
        mode: modeName,
      },
      onResponse,
      onError
    );
  };

  const updateAssignServiceToPlanModesDataField = (
    parentKey,
    fieldKey,
    newValue
  ) => {
    // setAssignedServiceProviderData({serviceProvider: {id: "176df7y"}})
    console.log("ni -> new value", newValue);
    setAssignServiceToPlanModesData((prevData) => ({
      ...prevData,
      [parentKey]: {
        ...prevData[parentKey],
        [fieldKey]: newValue,
      },
    }));
    if (fieldKey === "isEnabled" && newValue === false) {
      const newObj = removeKey(assignServiceToPlanModesData, parentKey);
      setAssignServiceToPlanModesData(newObj);
    }
  };

  const updateExtraAssignServiceToPlanModesDataField = (
    parentKey,
    fieldKey,
    newValue
  ) => {
    // setAssignedServiceProviderData({serviceProvider: {id: "176df7y"}})
    console.log("ni -> new value", newValue);
    setExtraAssignServiceToPlanModesData((prevData) => ({
      ...prevData,
      [parentKey]: {
        ...prevData[parentKey],
        [fieldKey]: newValue,
      },
    }));
  };

  const exitPage = () => {
    setPlanData({});
    setFormData({
      isContinueClicked: false,
    });
    setAssignServiceToPlanModesData({});
    setExtraAssignServiceToPlanModesData({});
    setServiceTypeData({});
    props.setView(3, {
      mode: "assign-service",
      planId: planData?.id,
    });
  };

  const getServiceTypes = (status) => {
    const onResponse = (res) => {
      console.log("ni- service types res=", res);
      setServiceTypes(res);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching service types: ${err?.message}`,
      });
    };
    createServiceUtil.getServiceType(status, onResponse, onError);
  };

  const fetchAssignedServiceToPlan = (planId, serviceId) => {
    const onResponse = (res) => {
      console.log("ni- fetchAssignedServiceToPlan types res=", res);
      // setServiceTypes(res);
      if (res?.serviceId && res?.serviceTypeId) {
        getServiceDataByFilter(res?.serviceId, res?.serviceTypeId, null);
        getServiceTypeDataById(res?.serviceTypeId);
      }
      // fetching assigned service providers
      // if (props?.data?.editMode == "service-providers") {
      //   fetchAssignedServiceProvidersByFilterHelper(
      //     planId,
      //     serviceId,
      //     res?.serviceModeDetails
      //   );
      // }

      console.log("ni -> 1 ", res?.serviceModeDetails);
      console.log(
        "ni -> 2 ",
        reverseConvertServiceModesData(res?.serviceModeDetails)
      );
      if (res?.serviceModeDetails) {
        setAssignServiceToPlanModesData((prevData) =>
          reverseConvertServiceModesData(res?.serviceModeDetails)
        );
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching AssignedServiceToPlan : ${err?.message}`,
      });
    };
    assignedPlansUtils.fetchAssignedServiceToPlan(
      planId,
      serviceId,
      onResponse,
      onError
    );
  };

  const getAccountByAccountCode = (code) => {
    let body = {
      pageNum: 1,
      pageSize: 1,
      accountCode: code,
    };
    const onResponse = (res) => {
      console.log("pr- acc code res=", res);
      setAccountDetails(res.accounts[0]);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    plansUtils.getAccountsByFilter(body, onResponse, onError);
  };

  const stringToInt = (str) => {
    const num = parseInt(str, 10); // Convert string to integer with base 10
    return isNaN(num) ? null : num; // Check if conversion failed
  };

  const getServiceTypeDataById = (id) => {
    let onResponse = (res) => {
      console.log("ni- got servType res for id=", res);
      setServiceTypeData(res);
      // intializing pagenums for assigned service providers table
      if (res?.modes) {
        res.modes.forEach((e) => {
          setAssignedServiceProviderPageNums((prev) => ({ ...prev, [e]: 1 }));
        });
      }
      // setAssignedServiceProviderData({service: assignedServiceProviderData?.service, serviceProvider: res});
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching the service provider details: ${err?.message}`,
      });
    };
    assignServiceProviderUtil.getServiceTypeById(id, onResponse, onError);
  };

  const getServiceDataByFilter = (idNum, serviceTypeId, serviceCode) => {
    const onResponse = (res) => {
      console.log("ni- getServiceDataByFilter res=", res);
      if (res?.services?.[0]) {
        setFormData((prev) => ({ ...prev, service: res?.services?.[0] }));
      } else {
        EventService.emit("showError", {
          message: `No service matched with the provided details.`,
        });
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching service details: ${err?.message}`,
      });
    };
    serviceDashboardUtils.getServicesByFilter(
      idNum
        ? {
            pageNum: 1,
            pageSize: 1,
            status: "active",
            Ids: [stringToInt(idNum)],
            serviceTypeIds: [serviceTypeId],
          }
        : {
            pageNum: 1,
            pageSize: 1,
            status: "active",
            nameMatch: [serviceCode?.toString()],
            serviceTypeIds: [serviceTypeId],
          },
      onResponse,
      onError
    );
  };

  const getPlanByFilter = (planId) => {
    const onResponse = (res) => {
      console.log("ni- got res for getPlansByFilter: ", res);
      if (res?.plans?.[0]) {
        setPlanData((prevData) => res?.plans?.[0] ?? {});
      } else {
        EventService.emit("showError", {
          message: `No plan found with planId - (${planId.toString()})`,
        });
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching plan details: ${err?.message}`,
      });
    };
    assignedPlansUtils.getAssignedPlansByFilter(
      {
        pageNum: 1,
        pageSize: 1,
        planIds: [planId],
      },
      onResponse,
      onError
    );
  };

  const assignServiceToPlan = (body) => {
    const onResponse = (res) => {
      if (props?.data?.mode == "assign") {
        console.log("ni- got res for assignServiceToPlan: ", res);
        EventService.emit("showError", {
          message: `Successfully assigned the service to the plan`,
        });
      } else if (props?.data?.mode == "update") {
        console.log("ni- got res for updateAssignedServiceToPlan: ", res);
        EventService.emit("showError", {
          message: `Successfully updated the service assignment to plan`,
        });
      }
      exitPage();
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in assigning service to plan: ${err?.message}`,
      });
    };

    if (props?.data?.mode == "assign") {
      assignedPlansUtils.assignServiceToPlan(body, onResponse, onError);
    } else if (props?.data?.mode == "update") {
      assignedPlansUtils.updateAssignedServiceToPlan(body, onResponse, onError);
    }
  };

  const updateFormDataField = (parentKey, fieldKey, newValue) => {
    // setAssignedServiceProviderData({serviceProvider: {id: "176df7y"}})
    console.log("ni -> new value", newValue);
    setFormData((prevData) => ({
      ...prevData,
      [parentKey]: {
        ...prevData[parentKey],
        [fieldKey]: newValue,
      },
    }));
  };

  const convertServiceModesData = (data) => {
    // console.log("ni -> convertServiceModesData fxn: ", data);
    if (!data || Object.keys(data).length === 0) {
      return []; // Return an empty array if data is null, undefined, or empty
    }

    // console.log("ni -> convertServiceModesData fxn: ", data);

    return Object.entries(data).map(([key, value]) => ({
      modeName: key,
      ...value,
    }));
  };

  const prepareBodyForServiceAssignment = () => {
    console.log("ni ->  form data -> ", formData);
    return {
      planId: planData?.id,
      planIdNum: planData?.planIdNum,
      serviceId: formData?.service?.id,
      serviceTypeId: formData?.service?.serviceTypeId,
      serviceSubtypeId: formData?.service?.serviceSubtypeId,
      serviceModeDetails: convertServiceModesData(assignServiceToPlanModesData),
      isSponsoredOnly: formData?.isSponsoredOnly ?? false,
    };
  };

  const getFamilyRelations = () => {
    const onResponse = (res) => {
      console.log("pr- rel=", res);
      let allRelations = res.map((e) => {
        return e.code;
      });
      setAllRelations(allRelations);
      setFamilyRelationsArr(res);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching family relations: ${err?.message}`,
      });
    };
    plansUtils.getFamilyRelations(onResponse, onError);
  };

  const isServiceValidForConfirm = () => {
    let errorOccurred = false;
    if (formData?.service) {
      const serviceRequiredFields = [
        "serviceTypeId",
        "id",
        "name",
        "serviceSubtypeName",
        "serviceName",
      ];
      serviceRequiredFields.forEach((e) => {
        if (errorOccurred) return;
        if (formData?.service?.[e]) {
        } else {
          errorOccurred = true;
          EventService.emit("showError", {
            message: `${e} is required in service details`,
          });
        }
      });
    } else {
      EventService.emit("showError", {
        message: "Please enter service details",
      });
      errorOccurred = false;
      return false;
    }

    if (errorOccurred) {
      return false;
    }

    return true;
  };

  const reverseConvertServiceModesData = (array) => {
    console.log("ni -> array -> ", array);

    if (!Array.isArray(array) || array.length === 0) {
      return {}; // Return an empty object if input is null, undefined, or empty
    }

    const ans = array.reduce((acc, item) => {
      const { modeName, ...rest } = item; // Extract mode as key, rest as value
      acc[modeName] = rest;
      return acc;
    }, {});

    console.log("ni -> ans -> ", ans);
    return ans;
  };

  let isFormValid = (formData, ModesData) => {
    console.log("ni -> formdata recvd in isformvalid -> ", formData);
    console.log("ni -> ,modesData recvd in isformvalid -> ", ModesData);

    if (!props?.data?.planId) {
      EventService.emit("showError", {
        message: `Invalid plan ID`,
      });
      return false;
    }

    if (
      formData?.service?.serviceTypeId == null ||
      formData?.service?.serviceTypeId?.toString()?.trim() === ""
    ) {
      EventService.emit("showError", {
        message: `Service type id num is required in service details.`,
      });
      return false;
    }

    if (
      formData?.service?.serviceSubtypeId == null ||
      formData?.service?.serviceSubtypeId?.toString()?.trim() === ""
    ) {
      EventService.emit("showError", {
        message: `Service subType id num is required in service details.`,
      });
      return false;
    }

    // should have at least one serviceMode
    if (ModesData == null || Object.entries(ModesData)?.length === 0) {
      EventService.emit("showError", {
        message: `At least one service mode is required.`,
      });
      return false;
    }

    // add required fields as validation targets to validate them.
    let validationTargets = { service: ["serviceTypeId", "id", "name"] };

    const modesRequiredFields = ["maxPrice", "discountedPrice", "isModeActive"];
    const planServiceModeRulesRequiredFields = ["maxServiceAvailCount"];

    let errorOccurred = false;
    Object.entries(validationTargets).forEach(([key, values]) => {
      values.forEach((val) => {
        if (errorOccurred) return;
        const value = formData?.[key]?.[val];
        console.log(`  - ${val} is required in ${key} details: ${value}`);
        if (value == null || value?.toString()?.trim() === "") {
          EventService.emit("showError", {
            message: `${val} is required in ${key} details.`,
          });
          errorOccurred = true;
        }
      });
      // }
    });

    if (errorOccurred) {
      return false;
    }

    errorOccurred = false;
    Object.entries(ModesData).forEach(([key, values]) => {
      modesRequiredFields.forEach((e) => {
        if (errorOccurred) return;
        if (ModesData?.[key]?.isEnabled) {
          const value = ModesData?.[key]?.[e];
          // console.log(`  - ${val} is required in ${key} details: ${value}`);
          if (value == null || value?.toString()?.trim() === "") {
            EventService.emit("showError", {
              message: `${e} is required in ${key} mode details.`,
            });
            errorOccurred = true;
          }
        }
      });
      if (errorOccurred) {
        return false;
      }

      errorOccurred = false;
      if (
        ModesData?.[key]?.isAdvanceRuleApplicable &&
        ModesData?.[key]?.planServiceModeRules
      ) {
        ModesData[key].planServiceModeRules.forEach((ele, i) => {
          if (errorOccurred) return;
          const rels = ele?.relations ?? [];
          if (rels.length <= 0) {
            EventService.emit("showError", {
              message: `Please select at least one relation in sponsorship rule no. - ${
                i + 1
              } for the mode - ${key}`,
            });
            errorOccurred = true;
            return;
          }
          const valu = ele?.planTimeRule?.maxServiceAvailCount;
          if (
            ele?.planTimeRule?.isServiceAvailLimited &&
            (valu == null ||
              valu?.toString()?.trim() === "" ||
              valu?.toString()?.trim() == "0")
          ) {
            EventService.emit("showError", {
              message: `Service availability - is required in sponsorship rule no. - ${
                i + 1
              } for the mode - ${key}`,
            });
            errorOccurred = true;
            return;
          }
        });
      }
    });

    if (errorOccurred) {
      return false;
    }

    return true;
  };

  const getAdvanceRuleSection = (
    rule,
    setAdvanceRule,
    idx,
    removeRule,
    alreadySelectedRelations,
    planServiceModeRules
  ) => {
    console.log("ni -> alreadySelectedRelations -> ", alreadySelectedRelations);
    // prepare alreadySelectedRelations (by removing relations selected for current rule idx)
    let alreadySelectedRelationsExceptCurrent = [];
    alreadySelectedRelations.forEach((e, i) => {
      if (i != idx) {
        alreadySelectedRelationsExceptCurrent = [
          ...alreadySelectedRelationsExceptCurrent,
          ...e,
        ];
      }
    });
    console.log(
      "ni -> alreadySelectedRelationsExceptCurrent -> ",
      alreadySelectedRelationsExceptCurrent
    );

    return (
      <div className="mp-astp-rules-outer-div">
        <div className="mp-astp-rules-family-member-div">
          <div>
            Applicable for &nbsp; &nbsp;
            <Checkbox
              style={{ margin: 0, padding: 0 }}
              checked={
                (rule?.relations ?? []).length >=
                familyRelationsArr.length -
                  alreadySelectedRelationsExceptCurrent.length
              }
              color="secondary"
              disabled={props?.data?.mode == "view"}
              onChange={(e) => {
                console.log("clicked");
                if (e.target.checked) {
                  let allRelCodes = (familyRelationsArr ?? []).map(
                    (obj) => obj.code
                  );
                  let removedCommonRelCode = [];
                  allRelCodes.forEach((e) => {
                    if (!alreadySelectedRelationsExceptCurrent.includes(e)) {
                      removedCommonRelCode.push(e);
                    }
                  });
                  rule.relations = removedCommonRelCode;
                } else {
                  rule.relations = [];
                }
                setAdvanceRule(rule);
              }}
            />
            &nbsp; All
          </div>
          {Boolean(
            planServiceModeRules.length > 1 && props?.data?.mode != "view"
          ) && (
            <div
              className="mp-advance-rule-close-fonticon-astp"
              onClick={(e) => removeRule(idx)}
            >
              <FontIcon>close</FontIcon>
            </div>
          )}
        </div>

        <div className="mp-assign-service-to-plan-family-relations-bubbles-div">
          {familyRelationsArr &&
            familyRelationsArr?.map((rel) => {
              if (alreadySelectedRelationsExceptCurrent.includes(rel?.code)) {
                return <></>;
              }
              return (
                <div
                  className={
                    (rule?.relations ?? []).includes(rel?.code)
                      ? "mp-plan-box-selected"
                      : "mp-plan-box-unselected"
                  }
                  onClick={() => {
                    if (props?.data?.mode == "view") {
                      return;
                    }
                    if ((rule?.relations ?? []).includes(rel?.code)) {
                      rule.relations = (rule?.relations ?? []).filter(
                        (item) => item !== rel?.code
                      );
                      setAdvanceRule(rule);
                    } else {
                      rule.relations = [...(rule?.relations ?? []), rel?.code];
                      setAdvanceRule(rule);
                    }
                  }}
                >
                  <div className="mp-plan-font">
                    {rel.code == 6
                      ? "Daughter < 18"
                      : rel.code == 7
                      ? "Daughter > 18"
                      : rel.code == 8
                      ? "Son < 18"
                      : rel.code == 9
                      ? "Son > 18"
                      : rel.code == 6
                      ? "Daughter < 18"
                      : rel.value}
                  </div>
                </div>
              );
            })}
        </div>
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">
                <Checkbox
                  style={{ margin: 0, padding: 0 }}
                  checked={rule?.planAgeRule?.isApplicable ?? false}
                  color="secondary"
                  disabled={props?.data?.mode == "view"}
                  onChange={(e) => {
                    console.log("ni-> ", e.target.checked);
                    console.log("ni-> ", rule?.planAgeRule?.isApplicable);
                    rule.planAgeRule.isApplicable = e.target.checked;
                    setAdvanceRule(rule);
                  }}
                />
                {`Age Rule Applicable`}
              </div>
              {/* <div> */}
              <RangeSlider
                min={rule.planAgeRule.fromAge}
                max={rule.planAgeRule.toAge}
                rangeMin={0}
                rangeMax={150}
                disabled={
                  !(rule?.planAgeRule?.isApplicable ?? false) ||
                  props?.data?.mode == "view"
                }
                setAge={(min, max) => {
                  rule.planAgeRule.fromAge = min;
                  rule.planAgeRule.toAge = max;
                  setAdvanceRule(rule);
                }}
              />
              {/* </div> */}
            </div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container-v2">
              <div className="mp-plan-label">
                <Checkbox
                  style={{ margin: 0, padding: 0 }}
                  checked={rule?.planGenderRule?.isApplicable ?? false}
                  color="secondary"
                  disabled={props?.data?.mode == "view"}
                  onChange={(e) => {
                    console.log("ni-> ", e.target.checked);
                    console.log("ni-> ", rule?.planGenderRule?.isApplicable);
                    rule.planGenderRule.isApplicable = e.target.checked;
                    setAdvanceRule(rule);
                  }}
                />
                {`Gender rule applicable`}
              </div>
              {/* <div> */}
              <SelectField
                menuItems={[
                  {
                    label: "Female",
                    value: 1,
                  },
                  {
                    label: "Male",
                    value: 2,
                  },
                ]}
                placeholder="Select"
                fullWidth
                disabled={
                  !(rule?.planGenderRule?.isApplicable ?? false) ||
                  props?.data?.mode == "view"
                }
                onChange={(e) => {
                  console.log("ni-> ", rule?.planAgeRule?.gender);
                  rule.planAgeRule.gender = e;
                  setAdvanceRule(rule);
                }}
                value={
                  rule?.planAgeRule?.gender == 0
                    ? ""
                    : rule?.planAgeRule?.gender ?? ""
                }
              />
              {/* </div> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container-v2">
              <div className="mp-plan-label-v2">
                <Checkbox
                  style={{ margin: 0, padding: 0 }}
                  checked={!(rule?.planTimeRule?.isServiceAvailLimited ?? true)}
                  color="secondary"
                  disabled={props?.data?.mode == "view"}
                  onChange={(e) => {
                    console.log("ni-> ", e.target.checked);
                    console.log(
                      "ni-> ",
                      rule?.planTimeRule?.isServiceAvailLimited
                    );
                    if (e.target.checked) {
                      rule.planTimeRule.isTimeRuleApplicable = false;
                    }
                    rule.planTimeRule.isServiceAvailLimited = !e.target.checked;
                    setAdvanceRule(rule);
                  }}
                />
                {`Check mark if unlimited or define limit below`}
                <label className="account-module-label-red"> *</label>
              </div>
              {/* <div> */}
              <TextField
                fullWidth
                placeholder="Type here to define limit"
                value={
                  rule?.planTimeRule?.maxServiceAvailCount == 0
                    ? ""
                    : rule?.planTimeRule?.maxServiceAvailCount ?? ""
                }
                disabled={
                  !(rule?.planTimeRule?.isServiceAvailLimited ?? true) ||
                  props?.data?.mode == "view"
                }
                onChange={(e) => {
                  rule.planTimeRule.maxServiceAvailCount = stringToInt(
                    e == "" ? "0" : e
                  );
                  setAdvanceRule(rule);
                }}
                type="number"
                lineDirection="left"
              />
              {/* </div> */}
            </div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container-v2">
              <div className="mp-plan-label">
                <Checkbox
                  style={{ margin: 0, padding: 0 }}
                  checked={rule?.planTimeRule?.isTimeRuleApplicable ?? false}
                  color="secondary"
                  disabled={
                    !(rule?.planTimeRule?.isServiceAvailLimited ?? true) ||
                    props?.data?.mode == "view"
                  }
                  onChange={(e) => {
                    console.log("ni-> ", e.target.checked);
                    console.log(
                      "ni-> ",
                      rule?.planTimeRule?.isTimeRuleApplicable
                    );
                    rule.planTimeRule.isTimeRuleApplicable = e.target.checked;
                    setAdvanceRule(rule);
                  }}
                />
                {`Time period rule is applicable`}
              </div>
              {/* <div> */}
              <TextField
                fullWidth
                placeholder="Enter number of days"
                value={rule?.planTimeRule?.timeRuleApplicableDays ?? ""}
                disabled={
                  !(rule?.planTimeRule?.isTimeRuleApplicable ?? false) ||
                  props?.data?.mode == "view"
                }
                onChange={(e) => {
                  rule.planTimeRule.timeRuleApplicableDays = stringToInt(
                    e == "" ? "0" : e
                  );
                  setAdvanceRule(rule);
                }}
                type="number"
                lineDirection="left"
              />
              {/* </div> */}
            </div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container-v2">
              <div className="mp-plan-label">
                <Checkbox
                  style={{ margin: 0, padding: 0 }}
                  checked={
                    rule?.planTimeRule?.isInclusiveOfAllRelations ?? false
                  }
                  color="secondary"
                  disabled={props?.data?.mode == "view"}
                  onChange={(e) => {
                    console.log("ni-> ", e.target.checked);
                    console.log(
                      "ni-> ",
                      rule?.planTimeRule?.isInclusiveOfAllRelations
                    );
                    rule.planTimeRule.isInclusiveOfAllRelations =
                      e.target.checked;
                    setAdvanceRule(rule);
                  }}
                />
                {`Inclusive of all`}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  const getServiceModesSection = () => {
    console.log("ni-> 3 ", serviceTypeData);
    return (
      <ExpansionList>
        {serviceTypeData?.modes?.map((row, index) => (
          <ExpansionPanel
            key={index}
            onExpandToggle={(expanded) => {
              if (expanded && !expandableTables?.[row]) {
                // fetching assigned service providers
                if (props?.data?.editMode == "service-providers") {
                  fetchAssignedServiceProvidersByFilterHelper(
                    props?.data?.planId,
                    props?.data?.serviceId,
                    row
                  );
                }
              }
            }}
            style={{
              borderRadius: "8px",
              backgroundColor: "#CED4DA",
              marginBottom: "20px",
            }}
            label={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  style={{ margin: 0, padding: 0 }}
                  checked={assignServiceToPlanModesData?.[row]?.isEnabled}
                  color="secondary"
                  disabled={props?.data?.mode == "view"}
                  onChange={(e) => {
                    updateAssignServiceToPlanModesDataField(
                      row.toString(),
                      "isEnabled",
                      e.target.checked
                    );
                  }}
                />
                <div style={{ width: "10px" }}></div>
                <div style={{ fontWeight: 700 }}>{row ? row : "None"}</div>
              </div>
            }
            expandedSecondaryLabel={null}
            footer={null}
            headerStyle={{
              margin: 0,
              padding: 0,
              marginLeft: 20,
              marginRight: 20,
              height: "40px",
            }}
            contentClassName="expansion-list-content-class-v2"
            // contentStyle={{ backgroundColor: "white", margin:0, padding:0, paddingBottom: "20px", paddingTop: "20px" }}
            children={
              assignServiceToPlanModesData?.[row]?.isEnabled && (
                <div>
                  <Row className="mp-row--modes-textfields-astp">
                    <Col xs={12} sm={3} md={3} lg={3}>
                      <div className="mp-plan-create-service-field-container">
                        <div className="mp-plan-label">
                          {`Service MRP (Rs)`}
                          <label className="account-module-label-red"> *</label>
                        </div>
                        <div>
                          <TextField
                            fullWidth
                            value={
                              assignServiceToPlanModesData?.[row]?.maxPrice ??
                              ""
                            }
                            onChange={(e) =>
                              updateAssignServiceToPlanModesDataField(
                                row.toString(),
                                "maxPrice",
                                stringToInt(e)
                              )
                            }
                            disabled={props?.data?.mode == "view"}
                            type="number"
                            lineDirection="left"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3}>
                      <div className="mp-plan-create-service-field-container">
                        <div className="mp-plan-label">
                          {`Discounted price (Rs)`}
                          <label className="account-module-label-red"> *</label>
                        </div>
                        <div>
                          <TextField
                            fullWidth
                            value={
                              assignServiceToPlanModesData?.[row]
                                ?.discountedPrice ?? ""
                            }
                            onChange={(e) =>
                              updateAssignServiceToPlanModesDataField(
                                row.toString(),
                                "discountedPrice",
                                stringToInt(e)
                              )
                            }
                            disabled={props?.data?.mode == "view"}
                            type="number"
                            lineDirection="left"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xs={12} sm={3} md={3} lg={3}>
                      <div className="mp-plan-create-service-field-container">
                        <div className="mp-plan-label">
                          {`Service status on plan`}
                          <label className="account-module-label-red"> *</label>
                        </div>
                        <div>
                          <SelectField
                            menuItems={["Active", "Inactive"]}
                            fullWidth
                            onChange={(e) =>
                              updateAssignServiceToPlanModesDataField(
                                row.toString(),
                                "isModeActive",
                                e === "Active" ? true : false
                              )
                            }
                            disabled={props?.data?.mode == "view"}
                            value={
                              assignServiceToPlanModesData?.[row]
                                ?.isModeActive === true
                                ? "Active"
                                : assignServiceToPlanModesData?.[row]
                                    ?.isModeActive === false
                                ? "Inactive"
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div
                    className="mp-assign-service-apply-new-rule-label-outer-div"
                    onClick={(e) => {
                      if (
                        assignServiceToPlanModesData?.[row]
                          ?.isAdvanceRuleApplicable ??
                        false
                      ) {
                        updateExtraAssignServiceToPlanModesDataField(
                          row?.toString(),
                          "expanded",
                          !(
                            extraAssignServiceToPlanModesData?.[row]
                              ?.expanded ?? false
                          )
                        );
                      } else {
                        updateExtraAssignServiceToPlanModesDataField(
                          row?.toString(),
                          "expanded",
                          false
                        );
                      }
                    }}
                  >
                    <Checkbox
                      style={{ margin: 0, padding: 0 }}
                      checked={
                        assignServiceToPlanModesData?.[row]
                          ?.isAdvanceRuleApplicable ?? false
                      }
                      color="secondary"
                      disabled={props?.data?.mode == "view"}
                      onChange={(e) => {
                        updateAssignServiceToPlanModesDataField(
                          row.toString(),
                          "isAdvanceRuleApplicable",
                          e.target.checked
                        );
                        updateExtraAssignServiceToPlanModesDataField(
                          row.toString(),
                          "expanded",
                          e.target.checked
                        );
                        if (e.target.checked) {
                          updateAssignServiceToPlanModesDataField(
                            row.toString(),
                            "planServiceModeRules",
                            [EMPTY_PLAN_SERVCE_MODE_RULE]
                          );
                        } else {
                          updateAssignServiceToPlanModesDataField(
                            row.toString(),
                            "planServiceModeRules",
                            []
                          );
                        }
                      }}
                    />
                    <div style={{ width: "10px" }}></div>
                    <div className="mp-assign-service-apply-new-rule-label-div">
                      <div style={{ fontWeight: 700 }}>
                        {"Apply sponsorship rules"}
                      </div>
                      <div className="mp-add-rule-btn-and-arrow-font-icon">
                        <Button
                          flat
                          // style={{ backgroundColor: "white", color: "#F97F0F" }}
                          disabled={
                            !(
                              (
                                assignServiceToPlanModesData?.[row]
                                  ?.planServiceModeRules ?? []
                              )?.at(-1)?.relations?.length > 0
                            ) ||
                            props?.data?.mode == "view" ||
                            (assignServiceToPlanModesData?.[
                              row
                            ]?.planServiceModeRules?.map(
                              (objectt) => objectt.relations
                            )?.[0]?.length ?? 0) >=
                              (familyRelationsArr?.length ?? 0)
                          }
                          className={"mp-plan-button-add-new-rule-enabled-astp"}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateAssignServiceToPlanModesDataField(
                              row.toString(),
                              "planServiceModeRules",
                              [
                                ...(assignServiceToPlanModesData?.[row]
                                  ?.planServiceModeRules ?? []),
                                EMPTY_PLAN_SERVCE_MODE_RULE,
                              ]
                            );
                          }}
                        >
                          +Add new rule
                        </Button>
                        <div style={{ width: "20px" }}></div>
                        {extraAssignServiceToPlanModesData?.[row]?.expanded ??
                        false ? (
                          <FontIcon>arrow_drop_up</FontIcon>
                        ) : (
                          <FontIcon>arrow_drop_down</FontIcon>
                        )}
                        <div style={{ width: "20px" }}></div>
                      </div>
                    </div>
                  </div>
                  {(extraAssignServiceToPlanModesData?.[row]?.expanded ??
                    false) && (
                    <div className="mp-row-modes-adv-rules-div-astp">
                      {assignServiceToPlanModesData?.[
                        row
                      ]?.planServiceModeRules.map((e, idx) =>
                        getAdvanceRuleSection(
                          e,
                          (newRuleObj) => {
                            if (
                              assignServiceToPlanModesData?.[row]
                                ?.planServiceModeRules?.[idx]
                            ) {
                              console.log("ni -> new obj ->", newRuleObj);
                              assignServiceToPlanModesData[
                                row
                              ].planServiceModeRules[idx] = newRuleObj;
                              updateAssignServiceToPlanModesDataField(
                                row.toString(),
                                "planServiceModeRules",
                                assignServiceToPlanModesData[row]
                                  .planServiceModeRules
                              );
                            }
                          },
                          idx,
                          (indexToRemove) => {
                            if (
                              assignServiceToPlanModesData?.[row]
                                ?.planServiceModeRules?.[indexToRemove]
                            ) {
                              const newArr = assignServiceToPlanModesData[
                                row
                              ].planServiceModeRules.filter(
                                (_, index) => index !== indexToRemove
                              );
                              updateAssignServiceToPlanModesDataField(
                                row.toString(),
                                "planServiceModeRules",
                                newArr
                              );
                            }
                          },
                          assignServiceToPlanModesData?.[
                            row
                          ]?.planServiceModeRules?.map(
                            (objectt) => objectt.relations
                          ),
                          assignServiceToPlanModesData?.[row]
                            ?.planServiceModeRules ?? []
                        )
                      )}

                      <div className="mp-plan-label">
                        <Checkbox
                          style={{ margin: 0, padding: 0 }}
                          checked={
                            assignServiceToPlanModesData?.[row]
                              ?.isSponsoredOnly ?? false
                          }
                          color="secondary"
                          disabled={props?.data?.mode == "view"}
                          onChange={(e) => {
                            console.log("ni-> ", e.target.checked);
                            updateAssignServiceToPlanModesDataField(
                              row.toString(),
                              "isSponsoredOnly",
                              e.target.checked
                            );
                          }}
                        />
                        {`Show this service only to users who are eligible to avail sponsored service`}
                      </div>
                    </div>
                  )}

                  {Boolean(props?.data?.editMode == "service-providers") && (
                    <>
                      <div className="mp-assigned-service-providers-label-outer-div">
                        <div style={{ width: "10px" }}></div>
                        <div className="mp-assign-service-apply-new-rule-label-div">
                          <div style={{ fontWeight: 700 }}>
                            {"Service providers assigned to the service"}
                          </div>
                        </div>
                      </div>
                      <div className="mp-astp-expandable-tables-outer-div">
                        {expandableTables?.[row]}
                      </div>
                    </>
                  )}
                </div>
              )
            }
          />
        ))}
      </ExpansionList>
    );
  };

  return (
    <div className="mp-plan-page-padding mp-astp-first-div">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="mp-plan-view">
            <div className="mp-plan-heading-with-back-button">
              <div
                className="mp-plan-back-btn"
                onClick={() => {
                  exitPage();
                }}
              >
                <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
              </div>
              <h1>{`Assign service to plan`}</h1>
            </div>
            <div className="mp-plan-buttons-container">
              <div className="mp-plan-buttons-right-alignment">
                {Boolean(props?.data?.mode != "view") && (
                  <Button
                    raised
                    disabled={
                      !(formData?.isContinueClicked ?? false) ||
                      props?.data?.mode == "view"
                    }
                    className={
                      !(formData?.isContinueClicked ?? false)
                        ? "mp-plan-button-save-disabled"
                        : "mp-plan-button-save-enabled"
                    }
                    onClick={() => {
                      if (isFormValid(formData, assignServiceToPlanModesData)) {
                        if (isServiceValidForConfirm()) {
                          assignServiceToPlan(
                            prepareBodyForServiceAssignment()
                          );
                        }
                      }
                    }}
                  >
                    Save
                  </Button>
                )}

                <Button
                  raised
                  className="mp-plan-button-cancel"
                  onClick={() => {
                    exitPage();
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <div className="mp-plan-grey-heading">Plan details</div>
      <br />
      <div className="mp-plan-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Plan ID`}</div>
              <div>
                <TextField
                  value={props?.data?.planId ?? ""}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Plan name`}</div>
              <div>
                <TextField
                  value={planData?.basicDetails?.name ?? ""}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Account name`}</div>
              <div>
                <TextField
                  value={accountDetails?.accountName ?? ""}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div style={{ height: "25px" }}></div>

      <h3>Add new service</h3>

      <div className="mp-plan-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">
                {`Service type`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <SelectField
                  placeholder="Select service type"
                  className="mp-plan-select-field"
                  menuItems={
                    serviceTypes
                      ? serviceTypes?.map((e) => ({
                          label: e?.name,
                          value: e?.id,
                        }))
                      : []
                  }
                  disabled={formData?.isContinueClicked ?? false}
                  value={formData?.service?.serviceTypeId ?? ""}
                  onChange={(e, idx) => {
                    console.log("ni -> new value item", idx);
                    console.log(
                      "ni -> serviceTypes item",
                      serviceTypes?.at(idx)?.idNum
                    );
                    setFormData((prev) => ({ ...prev, service: null }));
                    updateFormDataField("service", "serviceTypeId", e);
                    // setFormData((prev) => ({
                    //   ...prev,
                    //   serviceTypeIdNum: serviceTypes?.at(idx)?.id,
                    // }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">
                {`Service ID`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  type="text"
                  value={formData?.service?.id ?? ""}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      service: {
                        serviceTypeId: formData?.service?.serviceTypeId,
                      },
                    }));
                    updateFormDataField("service", "id", e);
                  }}
                  disabled={
                    !formData?.service?.serviceTypeId ||
                    (formData?.isContinueClicked ?? false)
                  }
                  lineDirection="left"
                  inlineIndicator={
                    <Button
                      icon
                      className="text-fields__inline-btn"
                      disabled={
                        !formData?.service?.serviceTypeId ||
                        (formData?.isContinueClicked ?? false)
                      }
                      style={{
                        margin: "-10px",
                      }}
                    >
                      <FontIcon
                        style={{
                          color: "orange",
                          lineHeight: "0",
                          marginBottom: "15px",
                        }}
                        onClick={() => {
                          // check if this service provider is already assigned to this service And proceed
                          getServiceDataByFilter(
                            formData?.service?.id,
                            formData?.service?.serviceTypeId,
                            null
                          );
                        }}
                      >
                        search
                      </FontIcon>
                    </Button>
                  }
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div className="mp-astp-or-text">OR</div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">
                {`Service code`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  type="text"
                  value={formData?.service?.name ?? ""}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      service: {
                        serviceTypeId: formData?.service?.serviceTypeId,
                      },
                    }));
                    updateFormDataField("service", "name", e);
                  }}
                  disabled={
                    !formData?.service?.serviceTypeId ||
                    (formData?.isContinueClicked ?? false)
                  }
                  lineDirection="left"
                  inlineIndicator={
                    <Button
                      icon
                      className="text-fields__inline-btn"
                      // disabled={!formData?.service?.serviceIdNum}
                      style={{
                        margin: "-10px",
                      }}
                      disabled={
                        !formData?.service?.serviceTypeId ||
                        (formData?.isContinueClicked ?? false)
                      }
                    >
                      <FontIcon
                        style={{
                          color: "orange",
                          lineHeight: "0",
                          marginBottom: "15px",
                        }}
                        onClick={() => {
                          // check if this service provider is already assigned to this service And proceed
                          getServiceDataByFilter(
                            null,
                            formData?.service?.serviceTypeId,
                            formData?.service?.name
                          );
                        }}
                      >
                        search
                      </FontIcon>
                    </Button>
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          {formData?.service?.serviceTypeId != 2 && (
            <>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="mp-plan-create-service-field-container">
                  <div className="mp-plan-label">{`Service sub type`}</div>
                  <div>
                    <TextField
                      value={formData?.service?.serviceSubtypeName ?? ""}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>

              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
            </>
          )}
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">{`Service name`}</div>
              <div>
                <TextField
                  value={formData?.service?.serviceName ?? ""}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Button
            style={{ marginLeft: "8px", marginTop: "18px" }}
            raised
            secondary
            disabled={formData?.isContinueClicked ?? false}
            onClick={() => {
              if (isServiceValidForConfirm()) {
                setFormData((prev) => ({ ...prev, isContinueClicked: true }));
                getServiceTypeDataById(formData?.service?.serviceTypeId);
              }
            }}
          >
            Confirm
          </Button>
        </Row>
      </div>
      <br />
      <div style={{ height: "60px" }}></div>
      {(formData?.isContinueClicked ?? false) && getServiceModesSection()}
    </div>
  );
};

export default AssignServiceToPlan;
