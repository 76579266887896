import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, TextField, Checkbox } from "react-md";
import UploadFileIcon from "../../../../../assets/icons/upload-file-icon.png";
import "../styles/createAccountStyles.css";
import "../styles/assignedPlansStyles.css";
import FontIcon from "react-md/lib/FontIcons";
import AccountDashboardUtils from "../services/AccountDashboardUtils";
import EventService from "../../../../../service/event/EventService";
import AssignedPlansUtil from "../services/AssignedPlansUtils";
import ExpandableTableAssignedPlans from "../helper/expandableTableAssignedPlans";

export const AssignedPlans = (props) => {
  const [accountData, setAccountData] = useState({});
  const [accountType, setAccountType] = useState(0);
  const [accountIdPrefix, setAccountIdPrefix] = useState("XX");
  const [accountCode, setAccountCode] = useState("");
  const [fullAccountCode, setFullAccountCode] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountMode, setAccountMode] = useState("");
  const [accountId, setAccountId] = useState("");
  const [keyAccountManager, setKeyAccountManager] = useState("");
  const [keyAccManagersObj, setKeyAccManagersObj] = useState([]);
  const [whitelistedDomains, setWhitelistedEmails] = useState([]);
  const [domainTyped, setDomainTyped] = useState("");
  const [logoViewImage, setLogoViewImage] = useState(null);
  const [logoViewPreview, setLogoViewPreview] = useState(null);
  const [mode, setMode] = useState(1);
  const logoViewImageInputRef = useRef(null);
  const [componentMounted, setComponentMounted] = useState(false);

  const [assignedPlansData, setAssignedPlansData] = useState({});
  const [expandableTile, setExpandableTile] = useState(<></>);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(2);
  const [totalCount, setTotalCount] = useState(10);
  const [filterObj, setFilterObj] = useState({});

  const accountDashboardUtils = new AccountDashboardUtils();
  const assignedPlansUtils = new AssignedPlansUtil();

  useEffect(() => {
    console.log("ni ->  props on assigned plans", props);
    getAccount(props.data.accountId);
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (componentMounted) {
      console.log("ni -> filterOBJ in useeffect", filterObj);
      getAssignedPlansByFilter(fullAccountCode);
    }
  }, [accountId, pageNum, filterObj]);

  useEffect(() => {
    console.log("ni- > asp -> ", assignedPlansData);

    if (componentMounted) {
      setExpandableTile((prev) => (
        <ExpandableTableAssignedPlans
          {...{
            data: {
              accountId: props.data.accountId,
              assignedPlans: assignedPlansData,
            },
            setView: props?.setView,
            goToPrevPage: navigateToPrevPage,
            goToNextPage: navigateToNextPage,
            fetchAssignedPlansByFilter: fetchAssignedPlansByFilter,
            totalCount: totalCount,
            pageNum: pageNum,
            pageSize: pageSize,
          }}
        />
      ));
    }
  }, [assignedPlansData]);

  const fetchAssignedPlansByFilter = (filterObj) => {
    setPageNum(1);
    setFilterObj(filterObj);
  };

  const getAssignedPlansByFilter = (fullAccountCode) => {
    const onResponse = (res) => {
      console.log("pr- got res for getAssignedPlansByFilter: ", res);
      // setAssignedServiceProvidersData(res?.assignedServiceProviders);
      setTotalCount((prev) => res?.totalCount ?? 0);
      setAssignedPlansData((prevData) => res?.plans ?? []);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching assigned plans: ${err?.message}`,
      });
    };
    assignedPlansUtils.getAssignedPlansByFilter(
      filterObj?.status
        ? {
            pageNum: pageNum,
            pageSize: pageSize,
            accountCodes: [fullAccountCode?.toString()],
            status: filterObj?.status,
          }
        : {
            pageNum: pageNum,
            pageSize: pageSize,
            accountCodes: [fullAccountCode?.toString()],
          },
      onResponse,
      onError
    );
  };

  const navigateToNextPage = () => {
    if (pageNum * pageSize < totalCount) {
      setPageNum((prevPageNum) => prevPageNum + 1);
    }
  };
  const navigateToPrevPage = () => {
    if (pageNum > 1) {
      setPageNum((prevPageNum) => prevPageNum - 1);
    }
  };

  const exitPage = () => {
    setAccountData({});
    setPageNum(1);
    setPageSize(2);
    setTotalCount(10);
    setAccountType(0);
    setAccountIdPrefix("XX");
    setAccountCode("");
    setAccountStatus("");
    setAccountName("");
    setAccountMode("");
    setKeyAccountManager("");
    setKeyAccManagersObj([]);
    setWhitelistedEmails([]);
    setDomainTyped("");
    setLogoViewImage(null);
    setLogoViewPreview(null);
    props.setView(0);
  };
  const ACCOUNT_TYPE_OPTIONS = [
    {
      label: "Zyla",
      value: 1,
    },
    {
      label: "Insurance",
      value: 2,
    },
    {
      label: "Pharma",
      value: 3,
    },
    {
      label: "Corporate",
      value: 4,
    },
  ];
  let ACCOUNT_STATUS_OPTIONS = [
    {
      label: "Active",
      value: "true",
    },
    {
      label: "Inactive",
      value: "false",
    },
  ];
  let ACCOUNT_MODE_OPTIONS = [
    {
      label: "B2B",
      value: "B2B",
    },
    {
      label: "B2C",
      value: "B2C",
    },
  ];
  const getAccount = (id) => {
    const onResponse = (res) => {
      let accType = res.accountCode.substring(0, 2);
      if (accType === "01") {
        setAccountType(1);
        setAccountIdPrefix("01");
      } else if (accType === "02") {
        setAccountType(2);
        setAccountIdPrefix("02");
      } else if (accType === "03") {
        setAccountType(3);
        setAccountIdPrefix("03");
      } else if (accType === "04") {
        setAccountType(4);
        setAccountIdPrefix("04");
      } else {
        setAccountType(0);
      }

      setAccountCode(res.accountCode.substring(2, 6));
      setFullAccountCode(res?.accountCode);
      setAccountId(res.id);
      setAccountStatus(res.status == true ? "true" : "false");
      setAccountName(res.accountName);
      setAccountMode(res.mode);
      setKeyAccountManager(res.managerName);
      // setKeyAccManagersObj([]);
      setWhitelistedEmails(res.whitelistedDomains);
      // setDomainTyped("");
      // setLogoViewImage(null);
      setLogoViewPreview(res.logoImage);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching account: ${err?.message}`,
      });
    };
    accountDashboardUtils.getAccountsById(id, onResponse, onError);
  };

  const renderWhitelistedDomains = () => {
    if (whitelistedDomains.length == 0) {
      return <></>;
    } else {
      return (
        <div className="account-module-domains-container">
          {whitelistedDomains.map((e, index) => {
            return (
              <div className="account-module-domains-box">
                <div className="account-module-font">{`@${e}`}</div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className="account-module-page-padding">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="account-module-view">
            <div className="account-module-heading-with-back-button">
              <div
                className="account-module-back-btn"
                onClick={() => {
                  exitPage();
                }}
              >
                <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
              </div>
              <h1>Assigned plans</h1>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <div className="account-module-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">{`Account type`}</div>
              <div>
                <SelectField
                  placeholder="Select account type"
                  className="account-module-select-field"
                  disabled
                  menuItems={ACCOUNT_TYPE_OPTIONS}
                  value={accountType}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">{`Account ID`} </div>
              <div style={{ display: "flex" }}>
                <div className="account-module-prefix">{accountIdPrefix}</div>
                <TextField
                  value={`${accountCode}`}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">{`Status`}</div>
              <div>
                <SelectField
                  placeholder="Select"
                  className="account-module-select-field"
                  menuItems={ACCOUNT_STATUS_OPTIONS}
                  disabled
                  value={accountStatus}
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">{`Account name`} </div>
              <div>
                <TextField value={accountName} lineDirection="left" disabled />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">{`Account mode`}</div>
              <div>
                <SelectField
                  placeholder="Select account type"
                  // disabled={fieldsEnabled.serviceType == false}
                  className="account-module-select-field"
                  menuItems={ACCOUNT_MODE_OPTIONS}
                  disabled
                  value={accountMode}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Key account manager`}
              </div>
              <div>
                <TextField
                  value={keyAccountManager}
                  lineDirection="left"
                  placeholder="Key account manager"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Whitelisted email domains`}
              </div>
              <div style={{ height: "10px" }}></div>
              {renderWhitelistedDomains()}
            </div>
          </Col>
        </Row>
      </div>

      <div style={{ height: "60px" }}></div>
      <div className="assigned-plans-table-outer-div">
        <h2 className="">Assigned plans</h2>
        <div className="service-provider-header-inner-div">
          <Button
            // disabled={!(serviceData?.serviceTypeId == 2)}
            raised
            secondary
            onClick={() => {
              props.setView(3, {
                mode: "create",
                accountId: accountId,
              });
            }}
          >
            Add
          </Button>
          <Button raised secondary>
            Upload csv
          </Button>
        </div>
      </div>
      <div style={{ height: "16px" }}></div>
      {expandableTile}
    </div>
  );
};

export default AssignedPlans;
