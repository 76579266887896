import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, DialogContainer, SelectField, TextField } from "react-md";
import { Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../styles/createAppointmentStyles.css";
import FontIcon from "react-md/lib/FontIcons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NavigoDashboardUtils from "../services/NavigoDashboardUtils";
import EventService from "../../../../../service/event/EventService";
import debounce from "debounce";
import { TextField as TextField2 } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import intToTime from "../helper/intToTime";
import getStatusName from "../helper/navigoStatusList";
import PatientsService from "../../../../ops/modules/patients/service/PatientsService";

export const DocumentUpload = (props) => {
  const [loading, setLoading] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [reasonList, setReasonList] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [appDocMap, setAppDocMap] = useState([]);
  const [token, setToken] = useState("");
  const [openRescheduleDialog, setOpenRescheduleDialog] = useState(false);
  const [serviceProviderListToShow, setServiceProviderListToShow] = useState(
    []
  );
  const [serviceProviderSelected, setServiceProviderSelected] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);
  const [slotList, setSlotList] = useState([]);
  const [slotSelected, setSlotSelected] = useState(null);
  const [selectServiceProviderCheckbox, setSelectServiceProviderCheckbox] =
    useState(false);
  const [serviceProviderAutoSelected, setServiceProviderAutoSelected] =
    useState(null);
  const navigoDashboardUtils = new NavigoDashboardUtils();
  const patientService = new PatientsService();
  const uploadFileRef = useRef(null);

  const MODES_WITH_AUTO_SP = ["Video", "Tele", "At Home"];
  function isAutoSPAllowed(mode) {
    return MODES_WITH_AUTO_SP.includes(mode);
  }

  useEffect(() => {
    fetchAppointment();
    fetchReasonsForConsultation();
    generateDocumentToken();
    getAppointmentDocMapping(props.data.appId);
  }, []);

  useEffect(() => {
    if (appointment != null && reasonList != null) {
      setIsMounted(true);
    }
  }, [appointment, reasonList]);

  useEffect(() => {
    if (openRescheduleDialog) {
      getProviderOfPlanService();
    }
  }, [openRescheduleDialog]);

  useEffect(() => {
    console.log("pr- tie=", dateSelected?.format("YYYY-MM-DD"));

    if (dateSelected != null) {
      setSlotSelected(null);
      getAvailableSlots(dateSelected?.format("YYYY-MM-DD"));
    }
  }, [dateSelected]);

  const getAvailableSlots = (date) => {
    let body = {
      planId: appointment?.planID,
      serviceId: appointment?.serviceID,
      serviceProviderId: selectServiceProviderCheckbox
        ? 0
        : serviceProviderSelected?.serviceProviderId == undefined
        ? null
        : serviceProviderSelected.serviceProviderId,
      bookingMode: appointment?.mode,
      collectionDate: date,
      vendor: "",
      vendorCode: "",
    };
    const onResponse = (res) => {
      console.log("pr- got slots=", res);
      setSlotList(res.slots == null ? [] : res.slots);
      if (selectServiceProviderCheckbox) {
        setServiceProviderAutoSelected(res.serviceProviderId);
      } else {
        setServiceProviderAutoSelected(null);
      }
    };
    const onError = (err) => {
      setServiceProviderAutoSelected(null);
      setSlotList([]);
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getAvailableSlots(body, onResponse, onError);
  };

  const getProviderOfPlanService = () => {
    let body = {
      pageNum: 0,
      pageSize: 0,
      serviceIds: [appointment?.serviceID],
      planId: appointment?.planID,
      mode: appointment?.mode,
    };
    const onResponse = (res) => {
      console.log("pr- gort SPs=", res);
      setServiceProviderListToShow(
        res.providers.length != 0 ? res.providers : []
      );
      if (res.providers.length != 0) {
        setServiceProviderSelected(
          res.providers.find(
            (e) => e.serviceProviderId == appointment?.serviceProviderID
          )
        );
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getProviderOfPlanService(body, onResponse, onError);
  };

  const fetchAppointment = () => {
    const onResponse = (res) => {
      if (res.appointments != 0) {
        setAppointment(res.appointments[0]);
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.fetchAppointments(
      {
        pageNum: 1,
        pageSize: 1,
        appointmentId: props.data.appId,
      },
      onResponse,
      onError
    );
  };
  const getAppointmentDocMapping = (appId) => {
    const onResponse = (res) => {
      console.log("pr- app doc map=", res);
      if (res != undefined && res.documents.length > 0) {
        setAppDocMap(res.documents);
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getAppointmentDocMapping(appId, onResponse, onError);
  };
  const fetchReasonsForConsultation = () => {
    const onResponse = (res) => {
      console.log("pr- rfc=", res);
      setReasonList(res.length != 0 ? res : []);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.fetchReasonsForConsultation(onResponse, onError);
  };
  const getDocumentFormat = (type) => {
    switch (type) {
      case "jpg":
      case "jpeg":
        return 1;
      case "png":
        return 2;
      case "gif":
        return 3;
      case "pdf":
        return 4;
      case "mp3":
        return 5;
      case "mp4":
        return 6;
      default:
        return 0;
    }
  };
  const handleUploadReportClick = () => {
    if (uploadFileRef && uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };
  const handleUploadFile = (e) => {
    if (e.target.files.length === 0) return;
    let document = {
      title: "",
      description: "",
      url: "",
      type: 11,
      format: getDocumentFormat(e.target.files[0].name.split(".").pop()),
      status: 4,
    };
    if (appointment?.bookedFor != 0) {
      let body = {
        ...document,
      };
      document.type = 6;
      navigoDashboardUtils.uploadDocument(
        e.target.files[0],
        e.target.files[0].name,
        body,
        (res) => {
          console.log("pr- doc upload succ=", res);

          navigoDashboardUtils.uploadDocumentDigital(
            {
              appointmentId: appointment?.id,
              documentId: res.id,
            },
            (res2) => {
              console.log("pr- doc mapped succ=", res2);
              getAppointmentDocMapping(appointment?.id);
            },
            (err2) => {
              console.log("pr- error in mapping=", err2);
              EventService.emit("showError", {
                message: `Error: ${err2?.message}`,
              });
            }
          );
          labtestAutomation(res);
        },
        (err) => {
          console.log("pr- err in uploading doc=", err);

          EventService.emit("showError", {
            message: `Error: ${err?.message}`,
          });
        },
        appointment?.bookedFor
      );
    }
    navigoDashboardUtils.uploadDocument(
      e.target.files[0],
      e.target.files[0].name,
      document,
      (res) => {
        console.log("pr- doc upload succ=", res);

        navigoDashboardUtils.uploadDocumentDigital(
          {
            appointmentId: appointment?.id,
            documentId: res.id,
          },
          (res2) => {
            console.log("pr- doc mapped succ=", res2);
            getAppointmentDocMapping(appointment?.id);
          },
          (err2) => {
            console.log("pr- error in mapping=", err2);
            EventService.emit("showError", {
              message: `Error: ${err2?.message}`,
            });
          }
        );
        labtestAutomation(res);
      },
      (err) => {
        console.log("pr- err in uploading doc=", err);

        EventService.emit("showError", {
          message: `Error: ${err?.message}`,
        });
      },
      appointment?.patientID
    );
  };
  const labtestAutomation = (res) => {
    navigoDashboardUtils.uploadDocumentLabtestAutomation(
      {
        patientId: res.patientId,
        documentURL: res.url,
      },
      (res3) => {
        console.log("pr- lab test auto success=", res3);
      },
      (err3) => {
        EventService.emit("showError", {
          message: `Error: ${err3?.message}`,
        });
      }
    );
  };

  const generateDocumentToken = () => {
    patientService.getAndSaveDocumentToken(
      (response) => {
        localStorage.setItem("zy.bridge.doc.token", JSON.stringify(response));
        setToken(response.token);
      },
      (error) => {}
    );
  };
  const viewDocument = (url, token) => {
    if (url) {
      window.open(url + "?token=" + token.toString(), "_blank");
    }
  };

  const exitPage = () => {
    setLoading(false);
    setAppointment(null);
    setReasonList(null);
    setIsMounted(false);
    setAppDocMap([]);
    setToken("");
    props.setView(0);
  };

  const resetValues = (code) => {
    switch (code) {
      case 1: // after closing dialog box
        setServiceProviderSelected(null);
        setServiceProviderAutoSelected(null);
        setSelectServiceProviderCheckbox(false);
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setLoading(false);
        setOpenRescheduleDialog(false);
        break;
      case 7: // after service provider change
        setDateSelected(null);
        setSlotList([]);
        setSlotSelected(null);
        setLoading(false);
        break;
    }
  };

  const rescheduleAppointment = () => {
    if (
      serviceProviderSelected == null &&
      selectServiceProviderCheckbox == false
    ) {
      EventService.emit("showError", {
        message: `Please select a service provider`,
      });
      return;
    } else if (dateSelected == null) {
      EventService.emit("showError", {
        message: `Please select a date`,
      });
      return;
    } else if (slotSelected == null) {
      EventService.emit("showError", {
        message: `Please select a time slot`,
      });
      return;
    } else {
      setLoading(true);
      let body = {
        appointmentID: appointment?.id,
        appointmentDate: moment(dateSelected)
          .utc()
          .startOf("day")
          .toISOString(),
        timeslot: slotSelected.value,
        serviceProviderID: selectServiceProviderCheckbox
          ? serviceProviderAutoSelected
          : serviceProviderSelected?.serviceProviderId,
      };
      const onResponse = (res) => {
        resetValues(1);
        console.log("pr- app resch success=", res);
        EventService.emit("showError", {
          message: `Appointment rescheduled successfully`,
        });
        setLoading(false);
        setOpenRescheduleDialog(false);
        fetchAppointment();
      };
      const onError = (err) => {
        EventService.emit("showError", {
          message: `Error: ${err?.message}`,
        });
        setLoading(false);
      };
      console.log("pr- gointoto send=", body);

      navigoDashboardUtils.rescheduleAppointment(body, onResponse, onError);
    }
  };

  return !isMounted ? (
    <></>
  ) : (
    <div className="navigo-module-page-padding">
      {console.log(
        "pr- app=",
        selectServiceProviderCheckbox,
        serviceProviderSelected,
        serviceProviderAutoSelected,
        moment(dateSelected).utc().startOf("day").toISOString(),
        slotSelected
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="navigo-module-view">
            <div className="navigo-module-heading-with-back-button">
              <div
                className="navigo-module-back-btn"
                onClick={() => {
                  exitPage();
                }}
              >
                <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
              </div>
              <h2>{`Appointment details`}</h2>
            </div>
            <div className="navigo-module-buttons-container">
              <div className="navigo-module-buttons-right-alignment">
                {isMounted && (
                  <Button
                    raised
                    disabled={loading}
                    className="navigo-module-button-save-enabled"
                    style={{ width: "200px" }}
                    onClick={() => {
                      setOpenRescheduleDialog(true);
                    }}
                  >
                    {"Reschedule"}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <div className="navigo-module-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Appointment status`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={getStatusName(appointment?.status)}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <h4 className="navigo-module-sub-heading-margin">{`Personal details`}</h4>
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Patient ID`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={
                    appointment?.bookedFor == 0
                      ? appointment?.patientID
                      : appointment?.bookedFor
                  }
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Patient name`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.patientName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Patient phone number`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "40px", marginRight: "20px" }}>
                    <TextField
                      value={`+${appointment.phoneNumber.countryCode}`}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                  <div style={{ flex: "1" }}>
                    <TextField
                      value={`+${appointment.phoneNumber.phoneNo}`}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <h4 className="navigo-module-sub-heading-margin">{`Appointment details`}</h4>
        {appointment?.serviceTypeID == 1 ? (
          <>
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Appointment ID`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={appointment?.id}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>

              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Patient calling number`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "40px", marginRight: "20px" }}>
                        <TextField
                          value={`+${appointment.customContactNumber.countryCode}`}
                          lineDirection="left"
                          disabled
                        />
                      </div>
                      <div style={{ flex: "1" }}>
                        <TextField
                          value={`+${appointment.customContactNumber.phoneNo}`}
                          lineDirection="left"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Reason for consultation`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={
                        reasonList.find((e) => e.id == appointment?.reasonId)
                          ?.name
                      }
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Reason description`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={appointment?.reasonDetails}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : appointment?.serviceTypeID == 2 ? (
          <>
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Appointment ID`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={appointment?.id}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}

        <br />
        {/* 1-------- */}
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Creation date`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={moment(appointment?.createdAt).format("DD MMM YYYY")}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Appointment date`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={moment(appointment?.appointmentDate).format(
                    "DD MMM YYYY"
                  )}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Time slot`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={intToTime(appointment?.timeSlot)}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        {/* 2-------- */}
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service ID`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceID}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service type`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceTypeName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service subtype`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceSubtypeName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        {/* 3-------- */}
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service code`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceCode}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service name`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service mode`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.mode}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        {/* 4-------- */}
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service provider ID`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceProviderID}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service provider name`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceProviderName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <h4 className="navigo-module-sub-heading-margin">{`User account and plan for appointment`}</h4>
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Account code`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.accountCode}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Account name`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.accountName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Plan`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={`${appointment?.planID} - ${appointment?.planName}`}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <h4 className="navigo-module-sub-heading-margin">{`Documents related to appointment`}</h4>
        {appDocMap.length != 0 && (
          <>
            <Row>
              <Col xs={4} sm={2} md={2} lg={2}>
                <h4>Document ID</h4>
              </Col>

              <Col xs={4} sm={2} md={2} lg={2}>
                <h4>Update date</h4>
              </Col>

              <Col xs={4} sm={2} md={2} lg={2}>
                <h4>Document name</h4>
              </Col>
            </Row>

            {appDocMap.map((doc) => {
              return (
                <>
                  <Row>
                    <Col xs={12} sm={2} md={2} lg={2}>
                      <div>{doc.id}</div>
                    </Col>

                    <Col xs={12} sm={2} md={2} lg={2}>
                      <div>{moment(doc.updatedAt).format("DD MMM YYYY")}</div>
                    </Col>

                    <Col xs={12} sm={2} md={2} lg={2}>
                      <div>{doc.title}</div>
                    </Col>
                    <Col xs={12} sm={2} md={3} lg={3}>
                      <div style={{ height: "20px" }}></div>
                    </Col>
                    <Col xs={12} sm={4} md={3} lg={3}>
                      <Button
                        raised
                        className={"navigo-module-view-doc"}
                        onClick={() => viewDocument(doc.url, token)}
                      >
                        {"View document"}
                      </Button>
                    </Col>
                  </Row>
                  <br />
                </>
              );
            })}
          </>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            raised
            disabled={loading}
            style={{ textAlign: "center" }}
            className={
              loading
                ? "navigo-module-button-save-disabled"
                : "navigo-module-button-save-enabled"
            }
            onClick={() => {
              handleUploadReportClick();
            }}
          >
            {"Upload a new document"}
          </Button>
          <input
            type="file"
            hidden
            accept={"application/pdf,image/png,image/jpeg,image/jpg"}
            ref={uploadFileRef}
            onChange={handleUploadFile}
          />
        </div>
      </div>
      {openRescheduleDialog && (
        <>
          <DialogContainer
            id="appointment-reschedule-dialog"
            visible={openRescheduleDialog}
            focusOnMount={false}
            onHide={() => resetValues(1)}
            width="580px"
            autosizeContent={true}
            height="320px"
          >
            <div className="navigo-module-dialog-container">
              <div className="navigo-module-heading-container">
                <div>
                  <h4>Reschedule</h4>
                </div>
                <div
                  className="navigo-module-close-btn"
                  onClick={() => {
                    resetValues(1);
                  }}
                >
                  <FontIcon>close</FontIcon>
                </div>
              </div>
              <div>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div>
                        <Checkbox
                          id="mp-appointment-checkbox-3"
                          name="mp-appointment-checkbox-3"
                          style={{ marginLeft: -13, marginTop: 3 }}
                          disabled={!isAutoSPAllowed(appointment?.mode)}
                          checked={selectServiceProviderCheckbox}
                          onChange={(value, proxy) => {
                            setDateSelected(null);
                            setSlotSelected(null);
                            setSlotList([]);
                            setServiceProviderSelected(null);
                            setServiceProviderAutoSelected(null);
                            if (selectServiceProviderCheckbox) {
                              setSelectServiceProviderCheckbox(false);
                            } else {
                              setSelectServiceProviderCheckbox(true);
                            }
                          }}
                        />
                      </div>
                      <div className="navigo-module-label-checkbox">
                        {`Select service provider or check mark to select automatically`}{" "}
                        <label className="navigo-module-label-red"> *</label>
                      </div>
                    </div>

                    <div
                      className="navigo-module-checkbox-field-container"
                      style={{ marginTop: "-16px" }}
                    >
                      <div>
                        <Autocomplete
                          id="mp-navigo-service-provider-selection"
                          options={serviceProviderListToShow}
                          value={serviceProviderSelected}
                          disabled={selectServiceProviderCheckbox}
                          onChange={(e, v) => {
                            if (v) {
                              if (serviceProviderSelected != null) {
                                resetValues(7);
                              }
                              setServiceProviderSelected(v);
                              console.log("pr- SP selected :", v);
                            } else {
                              setServiceProviderSelected(null);
                              console.log("pr- No SP selected");
                              resetValues(7);
                            }
                          }}
                          getOptionLabel={(option) =>
                            option ? option.serviceProviderName : ""
                          }
                          filterOptions={(options, { inputValue }) => {
                            const searchText = inputValue.toLowerCase();

                            return options.filter((option) => {
                              const serviceProviderName =
                                option.serviceProviderName?.toLowerCase() || "";

                              return serviceProviderName.includes(searchText);
                            });
                          }}
                          renderInput={(params) => (
                            <TextField2
                              {...params}
                              placeholder="Select"
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  fontFamily: "Montserrat, sans-serif",
                                  marginTop: "8px",
                                },
                                classes: {
                                  underline: "custom-underline",
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xs={12} sm={5} md={5} lg={5}>
                    <div className="navigo-module-create-service-field-container">
                      <div className="navigo-module-label">
                        {`Choose date`}
                        <label className="navigo-module-label-red"> *</label>
                      </div>
                      <div style={{ paddingTop: "10px" }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            style={{ width: "100%" }}
                            value={dateSelected}
                            onChange={(date) => {
                              setDateSelected(date);
                            }}
                            autoOk={true}
                            disabled={
                              selectServiceProviderCheckbox == false &&
                              serviceProviderSelected == null
                            }
                            className="navigo-module-appointment-datepicker"
                            format="DD MMM YYYY"
                            placeholder="DD MMM YYYY"
                            keyboardIcon={<FontIcon>calendar_month</FontIcon>}
                            variant="inline"
                            minDate={
                              selectServiceProviderCheckbox
                                ? undefined
                                : serviceProviderSelected?.serviceModes[0]
                                    .availability.config.minBookingDays ===
                                  undefined
                                ? moment()
                                : moment().add(
                                    serviceProviderSelected.serviceModes[0]
                                      .availability.config.minBookingDays,
                                    "days"
                                  )
                            }
                            maxDate={
                              selectServiceProviderCheckbox
                                ? undefined
                                : serviceProviderSelected?.serviceModes[0]
                                    .availability.config.maxAdvBookingDays ===
                                  undefined
                                ? moment()
                                : moment().add(
                                    serviceProviderSelected.serviceModes[0]
                                      .availability.config.maxAdvBookingDays,
                                    "days"
                                  )
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2}>
                    <div style={{ height: "20px" }}></div>
                  </Col>
                  <Col xs={12} sm={5} md={5} lg={5}>
                    <div className="navigo-module-create-service-field-container">
                      <div className="navigo-module-label">
                        {`Choose timeslot`}
                        <label className="navigo-module-label-red"> *</label>
                      </div>
                      <div>
                        <Autocomplete
                          id="mp-navigo-slot-selection"
                          value={slotSelected}
                          options={slotList}
                          onChange={(e, v) => {
                            if (v) {
                              setSlotSelected(v);
                              console.log("pr- slot selected :", v);
                            } else {
                              setSlotSelected(null);
                              console.log("pr- No slot selected");
                            }
                          }}
                          getOptionLabel={(option) =>
                            option ? intToTime(option.value) : ""
                          }
                          renderInput={(params) => (
                            <TextField2
                              {...params}
                              placeholder="Choose timeslot"
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  fontFamily: "Montserrat, sans-serif",
                                  marginTop: "8px",
                                },
                                classes: {
                                  underline: "custom-underline",
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <br />
                <Button
                  raised
                  disabled={loading}
                  className={
                    loading
                      ? "navigo-module-button-save-disabled"
                      : "navigo-module-button-save-enabled"
                  }
                  style={{ width: "100%", marginLeft: "0px" }}
                  onClick={() => {
                    rescheduleAppointment();
                  }}
                >
                  {"Reschedule"}
                </Button>
              </div>
            </div>
          </DialogContainer>
        </>
      )}
    </div>
  );
};

export default DocumentUpload;
