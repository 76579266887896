import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import {
  Button,
  SelectField,
  TextField,
  ExpansionList,
  ExpansionPanel,
  DialogContainer,
} from "react-md";
import "../styles/createPlan.css";
import FontIcon from "react-md/lib/FontIcons";
import "../styles/assignServiceToPlan.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EventService from "../../../../../service/event/EventService";
import PlansUtils from "../services/PlansUtils";
import { Checkbox } from "@material-ui/core";
import RangeSlider from "../services/RangeSlider";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AssignedPlansUtil from "../services/AssignedPlansUtils";
import CreateServiceUtil from "../../service-module/services/CreateServiceUtil";
import ServiceDashboardUtils from "../../service-module/services/ServiceDashboardUtils";
import AssignServiceProviderUtil from "../../service-module/services/AssignServiceProviderUtil";
import ExpandableTableAssignedServiceProviders from "../helper/ExpandableTableAssignedServiceProviders";

import "../styles/whitelistedUser.css";
import CreateServiceProviderUtil from "../../service-module/services/CreateServiceProviderUtil";

export const WhitelistUser = (props) => {
  const [planData, setPlanData] = useState({});
  const [formData, setFormData] = useState({
    isContinueClicked: false,
    whitelistType: "",
    whitelistArray: [{ countryCode: null, phoneNo: null }],
    recordToBeDeletedObjId: null,
    whitelistArrayEmails: [null],
    whitelistedPhoneNumbers: [],
    whitelistedEmails: [],
    phoneNoFilter: [],
    EmailFilter: [],
  });
  const [dialog, setDialog] = useState({
    visible: false,
    text: null,
    hide: () => {
      setDialog((prev) => ({ ...prev, visible: false }));
    },
    show: () => {
      setDialog((prev) => ({ ...prev, visible: true }));
    },
  });
  const [loading, setLoading] = useState(false);

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [totalCount, setTotalCount] = useState(100);
  const [componentMounted, setComponentMounted] = useState(false);

  const [countries, setCountries] = useState(null);

  const plansUtils = new PlansUtils();
  const assignedPlansUtils = new AssignedPlansUtil();
  const createServiceProviderUtil = new CreateServiceProviderUtil();

  useEffect(() => {
    console.log("ni -> props in whitelist user", props);
    setComponentMounted(true);
    getCountryCodes();
    if (props?.data?.mode != "edit") {
      getPlanByFilter(props?.data?.planId);
    }
  }, []);

  useEffect(() => {
    if (componentMounted && props?.data?.mode == "edit") {
      fetchWhiteListedUsers(prepareBodyForWhitelistedUsers());
    }
  }, [pageNum]);

  const WHITELISTING_TYPES = [
    {
      label: "Email ID",
      value: 1,
    },
    {
      label: "Phone number",
      value: 2,
    },
  ];

  const phoneNoLen = (cc) => {
    return countries?.find((c) => c.code == cc)?.phoneNoLen;
  };

  let getCountryCodes = () => {
    let onResponse = (res) => {
      setCountries(res);
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceProviderUtil.getCountries(onResponse, onError);
  };

  const navigateToNextPage = () => {
    if (pageNum * pageSize < totalCount) {
      setPageNum((prevPageNum) => prevPageNum + 1);
    }
  };
  const navigateToPrevPage = () => {
    if (pageNum > 1) {
      setPageNum((prevPageNum) => prevPageNum - 1);
    }
  };

  const exitPage = () => {
    setPlanData({});
    setDialog({
      visible: false,
      text: null,
      hide: () => {
        setDialog((prev) => ({ ...prev, visible: false }));
      },
      show: () => {
        setDialog((prev) => ({ ...prev, visible: true }));
      },
    });
    setCountries(null);
    setFormData({
      isContinueClicked: false,
      whitelistType: "",
      whitelistArray: [{ countryCode: null, phoneNo: null }],
      recordToBeDeletedObjId: null,
      whitelistArrayEmails: [null],
      whitelistedPhoneNumbers: [],
      whitelistedEmails: [],
      phoneNoFilter: [
        {
          countryCode: null,
          phoneNo: null,
        },
      ],
      EmailFilter: [],
    });
    props.setView(2, {
      // mode: "assign-service",
      planId: planData?.id,
      accountId: props?.data?.accountId,
    });
  };

  const stringToInt = (str) => {
    const num = parseInt(str, 10); // Convert string to integer with base 10
    return isNaN(num) ? null : num; // Check if conversion failed
  };

  const getPlanByFilter = (planId) => {
    const onResponse = (res) => {
      console.log("ni- got res for getPlansByFilter: ", res);
      if (res?.plans?.[0]) {
        setPlanData((prevData) => res?.plans?.[0] ?? {});
      } else {
        EventService.emit("showError", {
          message: `No plan found with planId - (${planId.toString()})`,
        });
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching plan details: ${err?.message}`,
      });
    };
    assignedPlansUtils.getAssignedPlansByFilter(
      {
        pageNum: 1,
        pageSize: 1,
        planIds: [planId],
      },
      onResponse,
      onError
    );
  };

  const deleteWhitelistedUser = (objId) => {
    const onResponse = (res) => {
      if (res) {
        EventService.emit("showError", {
          message: `Successfully deleted whitelisted ${
            formData?.whitelistType == 2 ? `phone number` : `email id`
          }`,
        });
        fetchWhiteListedUsers(prepareBodyForWhitelistedUsers());
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching whitelisted user details: ${err?.message}`,
      });
    };

    if (!objId) {
      EventService.emit("showError", {
        message: `Unable to get object id of the whitelisted item to be deleted`,
      });
      return;
    }

    if (formData?.whitelistType == 2) {
      plansUtils.deleteWhiteListedPhoneNumber(objId, onResponse, onError);
    } else {
      plansUtils.deleteWhiteListedEmail(objId, onResponse, onError);
    }
  };

  const fetchWhiteListedUsers = (body) => {
    const onResponse = (res) => {
      if (res) {
        if (formData?.whitelistType == 2) {
          setFormData((prev) => ({
            ...prev,
            whitelistedPhoneNumbers: res?.phones,
          }));
          setTotalCount((prev) => res?.totalCount);
        } else {
          setFormData((prev) => ({ ...prev, whitelistedEmails: res?.emails }));
          setTotalCount((prev) => res?.totalCount);
        }
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching whitelisted user details: ${err?.message}`,
      });
    };

    if (formData?.whitelistType == 2) {
      plansUtils.fetchWhiteListedPhoneNumbersByFilter(
        body,
        onResponse,
        onError
      );
    } else {
      plansUtils.fetchWhiteListedEmailsByFilter(body, onResponse, onError);
    }
  };

  const whitelistUsersToPlan = (body) => {
    const onResponse = (res) => {
      EventService.emit("showError", {
        message: `Successfully whitelisted users to plan`,
      });
      console.log("ni- got res for whitelistUsersToPlan: ", res);
      setFormData((prev) => ({
        ...prev,
        whitelistArray: [{ countryCode: null, phoneNo: null }],
        whitelistArrayEmails: [null],
        isContinueClicked: false,
      }));
      // exitPage();
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error in assigning service to plan: ${err?.message}`,
      });
    };

    if (props?.data?.mode == "assign") {
      if (formData?.whitelistType == 2) {
        plansUtils.whitelistUsersToPlanPhoneNo(body, onResponse, onError);
      } else {
        plansUtils.whitelistUsersToPlanEmail(body, onResponse, onError);
      }
    } else if (props?.data?.mode == "update") {
      plansUtils.updateAssignedServiceToPlan(body, onResponse, onError);
    }
  };

  const prepareBodyForWhitelistedUsers = () => {
    console.log("ni ->  form data -> ", formData);
    if (formData?.whitelistType == 2) {
      return {
        pageNum: pageNum,
        pageSize: pageSize,
        planId: props?.data?.planId,
        phoneMatch: formData?.phoneNoFilter,
      };
    } else {
      return {
        pageNum: pageNum,
        pageSize: pageSize,
        planId: props?.data?.planId,
        emailMatch: formData?.EmailFilter,
      };
    }
  };

  const prepareBodyForWhitelistingUsers = () => {
    console.log("ni ->  form data -> ", formData);
    if (formData?.whitelistType == 2) {
      return {
        planId: planData?.id,
        phoneNo: formData?.whitelistArray,
      };
    } else {
      return {
        planId: planData?.id,
        emails: formData?.whitelistArrayEmails,
      };
    }
  };

  let isFormValid = (formData) => {
    console.log("ni -> formdata recvd in isformvalid -> ", formData);

    if (!props?.data?.planId) {
      EventService.emit("showError", {
        message: `Invalid plan ID`,
      });
      return false;
    }

    // add required fields as validation targets to validate them.

    if (!formData?.whitelistType) {
      EventService.emit("showError", {
        message: `Invalid whitelisting type`,
      });
      return false;
    }

    let errorOccurred = false;
    if (formData?.whitelistType == 2) {
      (formData?.whitelistArray ?? []).forEach((e, i) => {
        if (!e?.countryCode) {
          EventService.emit("showError", {
            message: `Country code is missing in whitelisting phone no. data at number ${
              i + 1
            }`,
          });
          errorOccurred = true;
        } else if (!e?.phoneNo) {
          EventService.emit("showError", {
            message: `Phone no. is missing in whitelisting phone no. data at number ${
              i + 1
            }`,
          });
          errorOccurred = true;
        }
      });
      // Object.entries(formData?.whitelistArray).forEach(([key, values]) => {
      //   values.forEach((val) => {
      //     if (errorOccurred) return;
      //     const value = formData?.[key]?.[val];
      //     console.log(`  - ${val} is required in ${key} details: ${value}`);
      //     if (value == null || value?.toString()?.trim() === "") {
      //       EventService.emit("showError", {
      //         message: `${val} is required in ${key} details.`,
      //       });
      //       errorOccurred = true;
      //     }
      //   });
      //   // }
      // });
    } else {
      (formData?.whitelistArrayEmails ?? []).forEach((e, i) => {
        if (!e) {
          EventService.emit("showError", {
            message: `Email is missing in whitelisting emails data at number ${
              i + 1
            }`,
          });
          errorOccurred = true;
        }
      });
    }

    if (errorOccurred) {
      return false;
    }

    return true;
  };

  function removeAtIndex(array, i) {
    if (i < 0 || i >= array.length) {
      // Index out of bounds, return original array
      return array;
    }
    return [...array.slice(0, i), ...array.slice(i + 1)];
  }

  const getPlanDetailsSection = () => {
    return (
      <>
        <div className="mp-plan-grey-heading">Plan details</div>
        <br />
        <div className="mp-plan-create-service-container">
          <Row>
            <Col xs={12} sm={3} md={3} lg={3}>
              <div className="mp-plan-create-service-field-container">
                <div className="mp-plan-label">{`Plan ID`}</div>
                <div>
                  <TextField
                    value={props?.data?.planId ?? ""}
                    lineDirection="left"
                    disabled
                  />
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={3} md={3} lg={3}>
              <div className="mp-plan-create-service-field-container">
                <div className="mp-plan-label">{`Plan name`}</div>
                <div>
                  <TextField
                    value={planData?.basicDetails?.name ?? ""}
                    lineDirection="left"
                    disabled
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={1} md={1} lg={1}>
              <div style={{ height: "20px" }}></div>
            </Col>
            <Col xs={12} sm={3} md={3} lg={3}>
              <div className="mp-plan-create-service-field-container">
                <div className="mp-plan-label">{`Plan price (Rs)`}</div>
                <div>
                  <TextField
                    value={planData?.basicDetails?.price ?? ""}
                    lineDirection="left"
                    disabled
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={1} md={1} lg={1}>
              <div style={{ height: "20px" }}></div>
            </Col>
            <Col xs={12} sm={3} md={3} lg={3}>
              <div className="mp-plan-create-service-field-container">
                <div className="mp-plan-label">{`Plan status`}</div>
                <div>
                  <TextField
                    value={
                      planData?.basicDetails?.status == 1
                        ? "Active"
                        : planData?.basicDetails?.status == 2
                        ? "Inactive"
                        : "-"
                    }
                    lineDirection="left"
                    disabled
                  />
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={3} md={3} lg={3}>
              <div className="mp-plan-create-service-field-container">
                <div className="mp-plan-label">{`Plan duration (in days)`}</div>
                <div>
                  <TextField
                    value={planData?.basicDetails?.duration ?? ""}
                    lineDirection="left"
                    disabled
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={1} md={1} lg={1}>
              <div style={{ height: "20px" }}></div>
            </Col>
            <Col xs={12} sm={3} md={3} lg={3}>
              <div className="mp-plan-create-service-field-container">
                <div className="mp-plan-label">{`Plan start date`}</div>
                <div>
                  <TextField
                    value={moment(planData?.basicDetails?.startDate).format(
                      "DD MMM YYYY"
                    )}
                    lineDirection="left"
                    disabled
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={1} md={1} lg={1}>
              <div style={{ height: "20px" }}></div>
            </Col>
            <Col xs={12} sm={3} md={3} lg={3}>
              <div className="mp-plan-create-service-field-container">
                <div className="mp-plan-label">{`Plan start date`}</div>
                <div>
                  <TextField
                    value={moment(planData?.basicDetails?.endDate).format(
                      "DD MMM YYYY"
                    )}
                    lineDirection="left"
                    disabled
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const getWhitelistingItems = (whitelistArray) => {
    return whitelistArray?.map((e, idx) => (
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-plan-create-service-field-container">
            <div className="mp-plan-label">{`Phone number`}</div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "40px" }}>
                <SelectField
                  placeholder="+"
                  className="mp-plan-select-field"
                  menuItems={
                    countries
                      ? countries?.map((e) => ({
                          label: "+" + e?.code?.toString(),
                          value: e?.code,
                        }))
                      : []
                  }
                  value={e?.countryCode ?? ""}
                  onChange={(e, idxx) => {
                    console.log("ni -> new value item", e);

                    let newWhitelistArray = formData?.whitelistArray;
                    if (newWhitelistArray?.[idx]) {
                      newWhitelistArray[idx] = {
                        countryCode: e,
                        phoneNo: null,
                      };

                      setFormData((prev) => ({
                        ...prev,
                        whitelistArray: newWhitelistArray,
                      }));
                      // formData.whitelistArray;
                      // updateFormDataField("whitelistArray", "co", e);
                      console.log(formData);
                    }
                    // setFormData((prev) => ({
                    //   ...prev,
                    //   serviceTypeIdNum: serviceTypes?.at(idx)?.id,
                    // }));
                  }}
                />
              </div>
              <div style={{ width: "25px" }}></div>

              <TextField
                value={e?.phoneNo ?? ""}
                placeholder="Phone number"
                disabled={!e?.countryCode}
                type="number"
                onChange={(ele) => {
                  // console.log("ni -> new value item", ele);
                  // console.log(
                  //   "ni -> new value length",
                  //   ele?.toString()?.length
                  // );

                  if (
                    (ele?.toString()?.length ?? 100000) >
                    (phoneNoLen(e?.countryCode) ?? 0)
                  ) {
                    // EventService.emit("showError", {
                    //   message: `Max phone number length can be ${
                    //     phoneNoLen(e?.countryCode) ?? 0
                    //   } only.`,
                    // });
                    return;
                  }

                  let newWhitelistArray = formData?.whitelistArray;
                  if (newWhitelistArray?.[idx]) {
                    newWhitelistArray[idx] = {
                      countryCode: newWhitelistArray?.[idx]?.countryCode,
                      phoneNo: stringToInt(ele),
                    };

                    setFormData((prev) => ({
                      ...prev,
                      whitelistArray: newWhitelistArray,
                    }));
                    // formData.whitelistArray;
                    // updateFormDataField("whitelistArray", "co", e);
                    console.log(formData);
                  }

                  // setFormData((prev) => ({
                  //   ...prev,
                  //   serviceTypeIdNum: serviceTypes?.at(idx)?.id,
                  // }));
                }}
                lineDirection="left"
              />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div style={{ height: "20px" }}></div>
        </Col>
        {(whitelistArray?.length ?? 0) > 1 && (
          <Col xs={12} sm={3} md={3} lg={3}>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "end",
              }}
            >
              <FontIcon
                style={{
                  color: "black",
                  lineHeight: "0",
                  marginBottom: "15px",
                }}
                onClick={() => {
                  let newWhitelistArray = whitelistArray;
                  if (newWhitelistArray?.[idx]) {
                    newWhitelistArray = removeAtIndex(newWhitelistArray, idx);

                    setFormData((prev) => ({
                      ...prev,
                      whitelistArray: newWhitelistArray,
                    }));
                    // formData.whitelistArray;
                    // updateFormDataField("whitelistArray", "co", e);
                    console.log(formData);
                  }
                }}
              >
                close
              </FontIcon>
            </div>
          </Col>
        )}
      </Row>
    ));
  };

  const getWhitelistingItemsEmail = (whitelistArray) => {
    return whitelistArray?.map((e, idx) => (
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <div className="mp-plan-create-service-field-container">
            <div className="mp-plan-label">{`Email ID`}</div>

            <TextField
              value={e ?? ""}
              placeholder="Email id"
              onChange={(ele) => {
                // console.log("ni -> new value item", ele);

                let newWhitelistArray = formData?.whitelistArrayEmails;
                if ((newWhitelistArray?.length ?? 0) > idx) {
                  newWhitelistArray[idx] = ele;

                  setFormData((prev) => ({
                    ...prev,
                    whitelistArrayEmails: newWhitelistArray,
                  }));
                  // formData.whitelistArray;
                  // updateFormDataField("whitelistArray", "co", e);
                  console.log(formData);
                }

                // setFormData((prev) => ({
                //   ...prev,
                //   serviceTypeIdNum: serviceTypes?.at(idx)?.id,
                // }));
              }}
              lineDirection="left"
            />
          </div>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <div style={{ height: "20px" }}></div>
        </Col>
        {(whitelistArray?.length ?? 0) > 1 && (
          <Col xs={12} sm={3} md={3} lg={3}>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "end",
              }}
            >
              <FontIcon
                style={{
                  color: "black",
                  lineHeight: "0",
                  marginBottom: "15px",
                }}
                onClick={() => {
                  let newWhitelistArray = whitelistArray;
                  if ((newWhitelistArray?.length ?? 0) > idx) {
                    newWhitelistArray = removeAtIndex(newWhitelistArray, idx);

                    setFormData((prev) => ({
                      ...prev,
                      whitelistArrayEmails: newWhitelistArray,
                    }));
                    // formData.whitelistArray;
                    // updateFormDataField("whitelistArray", "co", e);
                    console.log(formData);
                  }
                }}
              >
                close
              </FontIcon>
            </div>
          </Col>
        )}
      </Row>
    ));
  };

  const getWhitelistedTable = (whitelistedData) => {
    return (
      <div className="mp-whitelisted-users-table-outer-div">
        <div className="mp-whitelisted-users-table-header-div">
          {formData?.whitelistType == 2 ? `Phone numbers` : `Email IDs`}
        </div>
        <div className="mp-whitelisted-users-table-pagination-div">
          <Button
            onClick={() => {
              navigateToPrevPage();
            }}
            disabled={pageNum <= 1 || loading}
          >
            <FontIcon className="navigo-count-icon">arrow_left</FontIcon>
          </Button>
          <div style={{ display: "flex", flexDirection: "row" }}>
            Showing&nbsp;
            <span
              style={{
                color: "#ef7c01",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {(pageNum - 1) * pageSize + 1}&nbsp;
            </span>
            <span
              style={{
                color: "#ef7c01",
                display: "flex",
                flexDirection: "row",
              }}
            >
              - &nbsp;
              {totalCount > pageNum * pageSize
                ? pageNum * pageSize
                : totalCount}
            </span>
            <span>&nbsp;&nbsp;of&nbsp;</span>
            <span>{` ${totalCount}`}</span>
          </div>
          <Button
            onClick={() => {
              navigateToNextPage();
            }}
            disabled={
              loading ||
              pageNum * pageSize > totalCount ||
              (totalCount > pageNum * pageSize
                ? pageNum * pageSize
                : totalCount) == totalCount
            }
          >
            <FontIcon>arrow_right</FontIcon>
          </Button>
        </div>
        <div>
          {whitelistedData?.map((e) => (
            <div className="mp-whitelisted-users-table-entry-div">
              <div className="mp-whitelisted-users-table-entry-value-div">
                {formData?.whitelistType == 2
                  ? "+" + e?.phoneNo?.countryCode + e?.phoneNo?.phoneNo
                  : e?.email ?? "Not found"}
              </div>
              <div
                className="mp-advance-rule-close-fonticon-astp"
                // onClick={(e) => removeRule(idx)}
              >
                <FontIcon
                  style={{ color: "#F97F0F" }}
                  onClick={(ele) => {
                    setFormData((prev) => ({
                      ...prev,
                      recordToBeDeletedObjId: e?.id,
                    }));
                    setDialog((prev) => ({
                      ...prev,
                      text:
                        formData?.whitelistType == 2 ? (
                          <p style={{ fontSize: "16px" }}>
                            Are you sure you want to delete the whitelisted
                            phone number{" "}
                            <strong>
                              {"+" +
                                e?.phoneNo?.countryCode +
                                e?.phoneNo?.phoneNo}
                            </strong>{" "}
                            ?
                          </p>
                        ) : (
                          <p style={{ fontSize: "16px" }}>
                            Are you sure you want to delete the whitelisted
                            email <strong>{e?.email}</strong> ?
                          </p>
                        ),
                    }));
                    dialog.show();
                  }}
                >
                  delete_outlined
                </FontIcon>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="mp-plan-page-padding mp-astp-first-div mp-whitelisted-users-main">
      <DialogContainer
        visible={dialog?.visible}
        // title="Use Google's location service?"
        width={"50vw"}
        onHide={dialog.hide}
        modal
      >
        <div className="mp-whitelisted-users-dialog-outer-div">
          {dialog?.text}
          <div style={{ height: "20px" }}></div>
          <div>
            <Button
              raised
              onClick={(e) => {
                deleteWhitelistedUser(formData?.recordToBeDeletedObjId);
                dialog.hide();
              }}
              className="mp-plan-button-save-enabled"
            >
              Confirm
            </Button>
            <Button
              raised
              onClick={dialog?.hide}
              className="mp-plan-button-cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContainer>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="mp-plan-view">
            <div className="mp-plan-heading-with-back-button">
              <div
                className="mp-plan-back-btn"
                onClick={() => {
                  exitPage();
                }}
              >
                <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
              </div>
              <h1>
                {props?.data?.mode == "edit"
                  ? `Whitelisted users list`
                  : `Whitelist users to plan`}
              </h1>
            </div>
            {props?.data?.mode != "edit" && (
              <div className="mp-plan-buttons-container">
                <div className="mp-plan-buttons-right-alignment">
                  <Button
                    raised
                    disabled={
                      !(formData?.isContinueClicked ?? false) ||
                      props?.data?.mode == "view"
                    }
                    className={
                      !(formData?.isContinueClicked ?? false)
                        ? "mp-plan-button-save-disabled"
                        : "mp-plan-button-save-enabled"
                    }
                    onClick={() => {
                      if (isFormValid(formData)) {
                        whitelistUsersToPlan(prepareBodyForWhitelistingUsers());
                      }
                    }}
                  >
                    Save
                  </Button>

                  <Button
                    raised
                    className="mp-plan-button-cancel"
                    onClick={() => {
                      exitPage();
                    }}
                  >
                    {"Cancel"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <br />
      <br />
      {!(props?.data?.mode == "edit") && getPlanDetailsSection()}

      <div style={{ height: "25px" }}></div>

      {!(props?.data?.mode == "edit") && <h3>Whitelist user</h3>}

      <div className="mp-plan-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-plan-create-service-field-container">
              <div className="mp-plan-label">
                {`Whitelisting type`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <SelectField
                  placeholder="Select Whitelisting type"
                  className="mp-plan-select-field"
                  menuItems={
                    // serviceTypes
                    //   ? serviceTypes?.map((e) => ({
                    //       label: e?.name,
                    //       value: e?.id,
                    //     }))
                    //   : []
                    WHITELISTING_TYPES
                  }
                  value={formData?.whitelistType ?? ""}
                  onChange={(e, idx) => {
                    console.log("ni -> new value item", idx);
                    // console.log(
                    //   "ni -> serviceTypes item",
                    //   serviceTypes?.at(idx)?.idNum
                    // );
                    setFormData((prev) => ({
                      ...prev,
                      whitelistType: e,
                      isContinueClicked: false,
                    }));
                    setPageNum(1);
                    setPageSize(30);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <Button
              // style={{ marginLeft: "8px", marginTop: "18px" }}
              raised
              secondary
              disabled={
                (formData?.isContinueClicked ?? false) ||
                !formData?.whitelistType
              }
              onClick={() => {
                if (props?.data?.mode == "edit") {
                  fetchWhiteListedUsers(prepareBodyForWhitelistedUsers());
                }
                setFormData((prev) => ({
                  ...prev,
                  isContinueClicked: true,
                }));
              }}
            >
              Continue
            </Button>
          </Col>
        </Row>
        <br />
        {formData?.isContinueClicked && props?.data?.mode == "edit" && (
          <>
            <div style={{ height: "20px" }}></div>
            {formData?.whitelistType == 2 && (
              <>
                <h3>Whitelisted phone numbers</h3>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <div className="mp-plan-create-service-field-container">
                    <div className="mp-plan-label">
                      {`Search`}
                      {/* <label className="account-module-label-red"> *</label> */}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ width: "40px" }}>
                        <SelectField
                          placeholder="+"
                          className="mp-plan-select-field"
                          menuItems={
                            countries
                              ? countries?.map((e) => ({
                                  label: "+" + e?.code?.toString(),
                                  value: e?.code,
                                }))
                              : []
                          }
                          value={
                            formData?.phoneNoFilter?.at(0)?.countryCode
                              ? "+" +
                                formData?.phoneNoFilter?.at(0)?.countryCode
                              : ""
                          }
                          onChange={(e, idxx) => {
                            console.log("ni -> new value item", e);

                            setFormData((prev) => ({
                              ...prev,
                              phoneNoFilter: [
                                {
                                  countryCode: e,
                                  phoneNo: prev?.phoneNoFilter?.at(0)?.phoneNo,
                                },
                              ],
                            }));
                            console.log(formData);
                          }}
                        />
                      </div>
                      <div style={{ width: "25px" }}></div>

                      <TextField
                        value={formData?.phoneNoFilter?.at(0)?.phoneNo ?? ""}
                        disabled={!formData?.phoneNoFilter?.at(0)?.countryCode}
                        placeholder="Search by phone number"
                        type="number"
                        onChange={(ele) => {
                          if (
                            (ele?.toString()?.length ?? 100000) >
                            (phoneNoLen(
                              formData?.phoneNoFilter?.at(0)?.countryCode
                            ) ?? 0)
                          ) {
                            // EventService.emit("showError", {
                            //   message: `Max phone number length can be ${
                            //     phoneNoLen(e?.countryCode) ?? 0
                            //   } only.`,
                            // });
                            return;
                          }
                          setFormData((prev) => ({
                            ...prev,
                            phoneNoFilter: [
                              {
                                countryCode:
                                  prev?.phoneNoFilter?.at(0)?.countryCode,
                                phoneNo: stringToInt(ele),
                              },
                            ],
                          }));
                          console.log(formData);
                        }}
                        lineDirection="left"
                        inlineIndicator={
                          <Button
                            icon
                            className="text-fields__inline-btn"
                            style={{
                              margin: "-10px",
                            }}
                            disabled={
                              !formData?.phoneNoFilter?.at(0)?.countryCode
                            }
                          >
                            <FontIcon
                              style={{
                                color: "#F97F0F",
                                lineHeight: "0",
                                marginBottom: "15px",
                              }}
                              onClick={() => {
                                fetchWhiteListedUsers(
                                  prepareBodyForWhitelistedUsers()
                                );
                              }}
                            >
                              search
                            </FontIcon>
                          </Button>
                        }
                      />
                    </div>
                  </div>
                </Col>
              </>
            )}
            {formData?.whitelistType == 1 && (
              <>
                <h3>Whitelisted Email IDs</h3>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <div className="mp-plan-create-service-field-container">
                    <div className="mp-plan-label">
                      {`Search`}
                      {/* <label className="account-module-label-red"> *</label> */}
                    </div>
                    <div>
                      <TextField
                        type="text"
                        placeholder="Search by email ID"
                        value={formData?.EmailFilter?.at(0) ?? ""}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            EmailFilter: [e],
                          }));
                        }}
                        lineDirection="left"
                        inlineIndicator={
                          <Button
                            icon
                            className="text-fields__inline-btn"
                            style={{
                              margin: "-10px",
                            }}
                          >
                            <FontIcon
                              style={{
                                color: "#F97F0F",
                                lineHeight: "0",
                                marginBottom: "15px",
                              }}
                              onClick={() => {
                                fetchWhiteListedUsers(
                                  prepareBodyForWhitelistedUsers()
                                );
                              }}
                            >
                              search
                            </FontIcon>
                          </Button>
                        }
                      />
                    </div>
                  </div>
                </Col>
              </>
            )}
            <div style={{ height: "10px" }}></div>
          </>
        )}

        {formData?.isContinueClicked &&
          (props?.data?.mode == "edit" ? (
            <>
              {formData?.whitelistType == 2
                ? getWhitelistedTable(formData?.whitelistedPhoneNumbers)
                : getWhitelistedTable(formData?.whitelistedEmails)}
            </>
          ) : (
            <>
              {formData?.whitelistType == 2
                ? getWhitelistingItems(formData?.whitelistArray)
                : getWhitelistingItemsEmail(formData?.whitelistArrayEmails)}
              <Row>
                <Button
                  style={{ marginLeft: "8px", marginTop: "18px" }}
                  raised
                  secondary
                  onClick={() => {
                    if (formData?.whitelistType == 2) {
                      const lastEle = formData?.whitelistArray?.at(-1);
                      if (lastEle?.countryCode && lastEle?.phoneNo) {
                        setFormData((prev) => ({
                          ...prev,
                          whitelistArray: [
                            ...prev?.whitelistArray,
                            { countryCode: null, phoneNo: null },
                          ],
                        }));
                      } else {
                        EventService.emit("showError", {
                          message: `Please fill last whitelisting user data before adding new.`,
                        });
                      }
                    } else {
                      const lastEle = formData?.whitelistArrayEmails?.at(-1);
                      if (lastEle) {
                        setFormData((prev) => ({
                          ...prev,
                          whitelistArrayEmails: [
                            ...prev?.whitelistArrayEmails,
                            null,
                          ],
                        }));
                      } else {
                        EventService.emit("showError", {
                          message: `Please fill last whitelisting user data before adding new.`,
                        });
                      }
                    }
                  }}
                >
                  Add new
                </Button>
              </Row>
            </>
          ))}
      </div>
      <br />
      <div style={{ height: "60px" }}></div>
    </div>
  );
};

export default WhitelistUser;
