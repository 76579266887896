import React, { useRef, useState, useEffect, useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../styles/serviceDashboardStyle.scss";
import {
  TextField,
  MenuItem,
  Menu,
  IconButton,
  Radio,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import filterApplied from "../../../../../assets/icons/filter-applied-icon.svg";
import funnelIcon from "../../../../../assets/icons/funnel.svg";
import goIcon from "../../../../../assets/icons/right_arrow_go_btn.svg";
import FontIcon from "react-md/lib/FontIcons";
import OptionsIcon from "react-md-icon/dist/RoundMoreVert";
import EventService from "../../../../../service/event/EventService";
import moment from "moment";
import Button from "react-md/lib/Buttons/Button";
import GlobalSkeletonLoader from "../../../../../components/common/GlobalSkeletonLoader";
import ServiceDashboardUtils from "../services/ServiceDashboardUtils";

function ServiceDashboard(props) {
  const [serviceList, setServiceList] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuFor, setMenuFor] = useState({});
  const [componentMounted, setComponentMounted] = useState(false);
  const serviceDashboardUtils = new ServiceDashboardUtils();
  const [patientId, setPatientId] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [filterObj, setFilterObj] = useState({
    pageNum: 1,
    pageSize: 30,
    serviceTypeIds: [],
    serviceSubtypeIds: [],
  });
  const [onlyPageChange, setOnlyPageChange] = useState(false);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [serviceTypeSelectedOptions, setServiceTypeSelectedOptions] = useState(
    []
  );
  const [serviceSubTypeSelectedOptions, setServiceSubTypeSelectedOptions] =
    useState([]);
  const [serviceSubTypeOptions, setServiceSubTypeOptions] = useState([]);
  const [serviceIdFilterText, setServiceIdFilterText] = useState("");
  const [serviceFilterText, setServiceFilterText] = useState("");
  const [serviceNameFilterText, setServiceNameFilterText] = useState("");
  const [serviceCodeOptions, setServiceCodeOptions] = useState([]);
  const [filterServiceCodeOptions, setFilterServiceCodeOptions] = useState([]);
  const [serviceNameOptions, setServiceNameOptions] = useState([]);
  const [filterServiceNameOptions, setFilterServiceNameOptions] = useState([]);

  const handleClick = (event, appointment) => {
    setAnchorEl(event.currentTarget);
    setMenuFor(appointment);
  };

  const [listStatus, setListStatus] = useState(4);
  const [noService, setNoService] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedClientCode, setSelectedClientCode] = useState("All");
  const [totalCount, setTotalCount] = useState(0);
  const [numFrom, setNumFrom] = useState(0);
  const [numTo, setNumTo] = useState(0);

  const [dateRangeFilter, setDateRangeFilter] = useState(false);

  const [dateRangeFilterValueFrom, setDateRangeFilterValueFrom] =
    useState(null);
  const [dateRangeFilterValueTo, setDateRangeFilterValueTo] = useState(null);
  const [serviceTypeFilter, setServiceTypeFilter] = useState(false);
  const [creationDateRangeFilterValueFrom, setCreationDateRangeFilterFrom] =
    useState(null);
  const [creationDateRangeFilterValueTo, setCreationDateRangeFilterTo] =
    useState(null);
  const [serviceTypeFilterValue, setServiceTypeFilterValue] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openFilter, setOpenFilter] = useState(0);
  const [selectedServiceType, setSelectedServiceType] = useState("all");
  const [maxDate, setMaxDate] = useState("");
  const skip = useRef(30);

  useEffect(() => {
    handleGetAllServices(1, 0, listStatus, selectedClientCode);
    setComponentMounted(true);
    getServiceTypes();
  }, []);

  useEffect(() => {
    if (componentMounted && onlyPageChange == true) {
      setFilterObj({ ...filterObj, pageNum: pageNum });
    }
  }, [onlyPageChange]);
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setMaxDate(today);
  }, []);

  useEffect(() => {
    if (componentMounted) {
      skip.current = 30;
      handleGetAllServices(1, 0, listStatus, "");
    }
  }, [filterObj, serviceTypeFilterValue]);

  useEffect(() => {
    setFilterObj({
      ...filterObj,
      pageNum: 1,
      nameMatch: filterServiceCodeOptions.map((e) => {
        return e.name;
      }),
    });
    setPageNum(1);
  }, [filterServiceCodeOptions]);
  useEffect(() => {
    setFilterObj({
      ...filterObj,
      pageNum: 1,
      serviceNameMatch: filterServiceNameOptions.map((e) => {
        return e.serviceName;
      }),
    });
    setPageNum(1);
  }, [filterServiceNameOptions]);

  useEffect(() => {
    if (
      dateRangeFilterValueFrom &&
      dateRangeFilterValueTo &&
      componentMounted
    ) {
      skip.current = 30;
      handleGetAllServices(1, 0, listStatus, "");
      setDateRangeFilter(true);
    }
  }, [dateRangeFilterValueFrom, dateRangeFilterValueTo]);

  const getServiceTypes = () => {
    let onResponse = (res) => {
      let options = res.map((e) => {
        return { name: e.name, value: e.id };
      });
      setServiceTypeOptions(options);
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    serviceDashboardUtils.getServiceType(true, onResponse, onError);
  };

  const getServiceSubTypes = async () => {
    let totalSubTypes = [];

    if (!filterObj.serviceTypeIds || filterObj.serviceTypeIds.length === 0) {
      console.log("No serviceTypeIds found.");
      return;
    }

    try {
      const responses = await Promise.all(
        filterObj.serviceTypeIds.map(
          (id) =>
            new Promise((resolve, reject) => {
              serviceDashboardUtils.getServiceSubType(
                id,
                true,
                (res) => resolve(res),
                (err) => reject(err)
              );
            })
        )
      );

      totalSubTypes = responses.flat().map((e) => ({
        name: e.name,
        value: e.id,
      }));
      setServiceSubTypeOptions(totalSubTypes);
    } catch (err) {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    }
  };
  const handleFetchServiceCodeOpt = (str) => {
    serviceDashboardUtils.getServicesByFilter(
      {
        ...filterObj,
        pageNum: 0,
        pageSize: 0,
        nameMatch: [],
        name: str,
      },
      (res) => {
        setOnlyPageChange(false);
        if (res && res.services && res.services.length > 0) {
          let allList = [];

          allList = res.services.map((service) => {
            return { ...service };
          });
          setServiceCodeOptions(allList);
        } else {
          setServiceCodeOptions([]);
        }
      },
      (err) => {
        setLoading(false);
        setNoService(true);
        EventService.emit("showError", {
          message: "Cannot retrieve services",
        });
      }
    );
  };
  function filterUniqueByServiceName(arr) {
    const seen = new Map();
    return arr.filter(
      (item) => !seen.has(item.serviceName) && seen.set(item.serviceName, true)
    );
  }
  const handleFetchServiceNameOpt = (str) => {
    serviceDashboardUtils.getServicesByFilter(
      {
        ...filterObj,
        pageNum: 0,
        pageSize: 0,
        serviceNameMatch: [],
        serviceName: str,
      },
      (res) => {
        setOnlyPageChange(false);
        if (res && res.services && res.services.length > 0) {
          let allList = [];

          allList = res.services.map((service) => {
            return { ...service };
          });
          allList = filterUniqueByServiceName(allList);
          setServiceNameOptions(allList);
        } else {
          setServiceNameOptions([]);
        }
      },
      (err) => {
        setLoading(false);
        setNoService(true);
        EventService.emit("showError", {
          message: "Cannot retrieve services",
        });
      }
    );
  };
  const handleGetAllServices = (
    type,
    btnType = 0,
    status = 4,
    clientCode = "",
    dateFlag = true
  ) => {
    setServiceList([]);
    setLoading(true);
    serviceDashboardUtils.getServicesByFilter(
      filterObj,
      (res) => {
        setOnlyPageChange(false);
        if (res && res.services && res.services.length > 0) {
          let allList = [];

          allList = res.services.map((service) => {
            return { ...service };
          });
          let localNumFrom, localNumTo;
          if (type === 1) {
            localNumFrom = 1;
            localNumTo = Math.min(30, res.resultCount);
          } else if (type === 2) {
            if (btnType === 0) {
              localNumTo = numFrom - 1;
              localNumFrom = Math.max(numFrom - 30, 1);
            } else {
              localNumFrom = numTo + 1;
              localNumTo = Math.min(numTo + 30, res.resultCount);
            }
          } else if (type === 3) {
            localNumFrom = numFrom;
            localNumTo = numTo;
          }
          setServiceList([...allList]);
          setNoService(false);
          setNumFrom(localNumFrom);
          setNumTo(localNumTo);
          setTotalCount(res.totalCount);
          setLoading(false);
        } else {
          setServiceList([]);
          setNoService(true);
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
        setNoService(true);
        EventService.emit("showError", {
          message: "Cannot retrieve services",
        });
      }
    );
  };

  const handleAddNewService = () => {
    props.setView(2, {
      mode: "create",
      serviceId: "",
    });
  };

  const handleEditService = (ele, opt) => {
    if (opt == 1) {
      props.setView(2, {
        mode: "view",
        serviceId: ele.id,
      });
    } else if (opt == 2) {
      props.setView(2, {
        mode: "update",
        serviceId: ele.id,
      });
    } else if (opt == 3) {
      props.setView(3, {
        mode: "assign",
        serviceId: ele.id,
      });
    }
  };

  const handlePrevNextAppoint = (arg) => {
    setOnlyPageChange(true);
    if (arg) {
      setPageNum((prevPage) => prevPage + 1);
    } else {
      if (pageNum > 1) {
        setPageNum((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
      }
    }
  };

  useEffect(() => {
    if (!patientId && !appointmentId && !phoneNumber && componentMounted) {
      handleGetAllServices(1, 0, listStatus, "");
    }
  }, [patientId, appointmentId, phoneNumber]);

  const handleChangeServiceType = (event) => {
    setSelectedServiceType(event.target.value);
    if (event.target.value == "" || event.target.value == "all") {
      setFilterObj({
        ...filterObj,
        pageNum: 1,
        serviceTypeIds: [],
      });
      setPageNum(1);
    } else {
      setFilterObj({
        ...filterObj,
        pageNum: 1,
        serviceTypeIds: [Number(event.target.value)],
      });
      setPageNum(1);
    }
  };

  return (
    <div className="service-dashboard-table-container">
      <div className="navigo-appoint-container">
        <div className="navigo-appoint-section-3">
          <div
            className="navigo-appoint-section-1"
            onClick={() => {
              props.setView(0);
            }}
          >
            <FontIcon style={{ fontSize: "36px", marginTop: "8px" }}>
              arrow_back
            </FontIcon>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1 className="navigo-appoint-section-2">Service dashboard</h1>
            <div style={{ marginLeft: "30px" }}>
              <FormControl variant="outlined" style={{ width: "250px" }}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Search via service type
                </InputLabel>
                <Select
                  native
                  value={selectedServiceType}
                  onChange={(e) => {
                    handleChangeServiceType(e);
                    e.target.blur();
                  }}
                  label="Search via service type"
                  inputProps={{
                    name: "age",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option value="all">Show all</option>
                  {serviceTypeOptions.map((e) => {
                    return <option value={e.value}>{e.name}</option>;
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="navigo-appoint-section-4-bottom-button-container">
            <button
              className="navigo-appoint-section-4-bottom-button"
              onClick={handleAddNewService}
            >
              Add New
            </button>
          </div>
        </div>

        <div className="navigo-appoint-section-5">
          <div className="navigo-appoint-section-5-grid-container">
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Service <br /> Id
              </p>
              <img
                src={filterObj?.Ids?.length > 0 ? filterApplied : funnelIcon}
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(4);
                }}
              />
              {openFilter === 4 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup-serviceId">
                  <TextField
                    id="appointment-dialog-client-code-filter-serviceId"
                    className="appointment-dialog-client-code-filter-class"
                    placeholder="Service ID"
                    value={serviceIdFilterText}
                    onChange={(e) => {
                      let inputValue = e.target.value.replace(/[^0-9,]/g, ""); // Allow only numbers and commas

                      setServiceIdFilterText(inputValue);
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                    InputProps={{
                      endAdornment: serviceIdFilterText && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setServiceIdFilterText("");
                              let removedFilterObj = { ...filterObj };
                              delete removedFilterObj.Ids;
                              setFilterObj({
                                ...removedFilterObj,
                                pageNum: 1,
                              });
                              setPageNum(1);
                            }}
                            edge="end"
                            size="small"
                          >
                            <FontIcon>close</FontIcon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={() => {
                      if (serviceIdFilterText.trim()) {
                        const Ids = serviceIdFilterText
                          .split(",")
                          .map((id) => id.trim())
                          .filter((id) => id !== "" && !isNaN(id))
                          .map(Number);

                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          Ids,
                        });
                        setPageNum(1);
                      }
                    }}
                  >
                    <img
                      src={goIcon}
                      alt="Go"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section">
              <p>
                Service code
                <br />
              </p>
              <img
                src={
                  filterServiceCodeOptions.length > 0
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(9);
                }}
              />
              {openFilter === 9 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup-serviceId">
                  <Autocomplete
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                    multiple
                    id="multiple-limit-service-code"
                    options={serviceCodeOptions}
                    getOptionLabel={(option) => option.name}
                    value={filterServiceCodeOptions}
                    onChange={(e, v) => {
                      if (v) {
                        setFilterServiceCodeOptions(v);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e, v) => {
                          handleFetchServiceCodeOpt(e.target.value);
                        }}
                        {...params}
                        placeholder=""
                        className="autocomplete-text-field-class"
                      />
                    )}
                  />
                  {/* <TextField
                    id="appointment-dialog-client-code-filter-service-name"
                    className="appointment-dialog-client-code-filter-class"
                    placeholder="Service name"
                    value={serviceFilterText}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setServiceFilterText(inputValue);
                      setFilterObj({
                        ...filterObj,
                        pageNum: 1,
                        name: inputValue,
                      });
                      setPageNum(1);
                    }}
                    InputProps={{
                      endAdornment: serviceFilterText && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setServiceFilterText("");
                              let removedFilterObj = filterObj;
                              delete removedFilterObj.name;
                              setFilterObj({
                                ...removedFilterObj,
                                pageNum: 1,
                              });
                              setPageNum(1);
                            }}
                            edge="end"
                            size="small"
                          >
                            <FontIcon>close</FontIcon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                </div>
              )}
            </div>
            {/* <div className="navigo-appoint-section-5-grid-container-common-section">
              <p>
                Service <br /> type
              </p>
              <img
                src={
                  filterObj?.serviceTypeIds?.length > 0
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(1);
                }}
              />
              {openFilter === 1 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                  <Autocomplete
                    multiple
                    id="appointment-dialog-client-code-filter"
                    className="appointment-dialog-client-code-filter-class"
                    disableCloseOnSelect
                    options={serviceTypeOptions}
                    value={serviceTypeSelectedOptions}
                    onChange={(event, selectedOptions) => {
                      setServiceTypeSelectedOptions(selectedOptions);
                      let filt = selectedOptions.map((e) => {
                        return e.value;
                      });
                      setFilterObj({
                        ...filterObj,
                        pageNum: 1,
                        serviceTypeIds: filt,
                      });
                      setPageNum(1);
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason == "clear") {
                        setServiceTypeSelectedOptions([]);
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Enter the service type"}
                      />
                    )}
                  />
                </div>
              )}
            </div> */}
            <div className="navigo-appoint-section-5-grid-container-common-section">
              <p>
                Service <br /> subtype
              </p>
              <img
                src={
                  filterObj?.serviceSubtypeIds?.length > 0
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(3);
                  getServiceSubTypes();
                }}
              />
              {openFilter === 3 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                  <Autocomplete
                    multiple
                    id="appointment-dialog-client-code-filter"
                    className="appointment-dialog-client-code-filter-class"
                    disableCloseOnSelect
                    options={serviceSubTypeOptions}
                    value={serviceSubTypeSelectedOptions}
                    onChange={(event, selectedOptions) => {
                      setServiceSubTypeSelectedOptions(selectedOptions);
                      let filt = selectedOptions.map((e) => e.value);
                      setFilterObj({
                        ...filterObj,
                        pageNum: 1,
                        serviceSubtypeIds: filt,
                      });
                      setPageNum(1);
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason == "clear") {
                        setServiceSubTypeSelectedOptions([]);
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Enter the service subtype"}
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Service name <br />
              </p>
              <img
                src={
                  filterServiceNameOptions.length > 0
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(6);
                }}
              />
              {openFilter === 6 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup-serviceId">
                  <Autocomplete
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                    multiple
                    id="multiple-limit-service-name"
                    options={serviceNameOptions}
                    getOptionLabel={(option) => option.serviceName}
                    value={filterServiceNameOptions}
                    onChange={(e, v) => {
                      if (v) {
                        setFilterServiceNameOptions(v);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e, v) => {
                          handleFetchServiceNameOpt(e.target.value);
                        }}
                        {...params}
                        placeholder=""
                        className="autocomplete-text-field-class"
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Updated <br />
                on
              </p>
              <img
                src={
                  filterObj?.updatedAt?.from && filterObj?.updatedAt?.to
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(8);
                  // setOpenDateRangeFilter(true);
                }}
              />
              {openFilter === 8 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                  <div className="navigo-appointment-date-range-filter ">
                    <p>From</p>
                    <TextField
                      type="date"
                      style={{ width: "90%" }}
                      className="dob"
                      id="dob"
                      onChange={(v) => {
                        if (v.target.value) {
                          setCreationDateRangeFilterFrom(v.target.value);
                          setFilterObj({
                            ...filterObj,
                            pageNum: 1,
                            updatedAt: {
                              ...filterObj.updatedAt,
                              from: moment(v.target.value)
                                .utc()
                                .startOf("day")
                                .add(1, "day")
                                .toISOString(),
                            },
                          });
                          setPageNum(1);
                        }
                      }}
                      inputProps={{ max: maxDate }}
                      value={creationDateRangeFilterValueFrom}
                    ></TextField>
                  </div>
                  <div className="navigo-appointment-date-range-filter ">
                    <p>To</p>
                    <TextField
                      type="date"
                      defaultValue={""}
                      style={{ width: "90%" }}
                      className="dob"
                      id="dob"
                      onChange={(v) => {
                        if (v.target.value) {
                          setCreationDateRangeFilterTo(v.target.value);
                          setFilterObj({
                            ...filterObj,
                            pageNum: 1,
                            updatedAt: {
                              ...filterObj.updatedAt,
                              to: moment(v.target.value)
                                .utc()
                                .endOf("day")
                                .add(1, "day")
                                .toISOString(),
                            },
                          });
                          setPageNum(1);
                        }
                      }}
                      inputProps={{ max: maxDate }}
                      value={creationDateRangeFilterValueTo}
                    ></TextField>
                  </div>

                  <Button
                    className=""
                    onClick={() => {
                      let removedFilterObj = filterObj;
                      delete removedFilterObj.updatedAt;
                      setFilterObj({
                        ...removedFilterObj,
                        pageNum: 1,
                      });
                      setCreationDateRangeFilterFrom(null);
                      setCreationDateRangeFilterTo(null);
                      setPageNum(1);
                      setOpenFilter(0);
                      setOpenBackdrop(false);
                    }}
                  >
                    <FontIcon className="">close</FontIcon>
                  </Button>
                </div>
              )}
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Address <br /> required
              </p>
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Can be <br />
                booked for
              </p>
              <img
                src={
                  filterObj?.canBookedFor && filterObj?.canBookedFor != ""
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(5);
                  // setOpenStatusFilter(true);
                }}
              />
              {openFilter === 5 && (
                <div className="can-be-booked-for-filter">
                  <div>
                    <Radio
                      label="Male"
                      checked={
                        filterObj.canBookedFor
                          ? filterObj.canBookedFor == "Male"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          canBookedFor: "Male",
                        });
                        setPageNum(1);
                      }}
                    />
                    <label className="label-radio-btn">Male</label>
                  </div>
                  <div>
                    <Radio
                      label="Female"
                      checked={
                        filterObj.canBookedFor
                          ? filterObj.canBookedFor == "Female"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          canBookedFor: "Female",
                        });
                        setPageNum(1);
                      }}
                    />
                    <label className="label-radio-btn">Female</label>
                  </div>
                  <div>
                    <Radio
                      label="Both"
                      checked={
                        filterObj.canBookedFor
                          ? filterObj.canBookedFor == "Both"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          canBookedFor: "Both",
                        });
                        setPageNum(1);
                      }}
                    />
                    <label className="label-radio-btn">Both</label>
                  </div>
                  <Button
                    className="clear-navigo-booked-for"
                    onClick={() => {
                      let removedFilterObj = filterObj;
                      delete removedFilterObj.canBookedFor;
                      setFilterObj({
                        ...removedFilterObj,
                        pageNum: 1,
                      });
                      setPageNum(1);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              )}
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Age rule <br /> applicable
              </p>
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>Status</p>
              <img
                src={
                  filterObj?.status && filterObj?.status != ""
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(2);
                  // setOpenStatusFilter(true);
                }}
              />
              {openFilter === 2 && (
                <div className="mp-service-dashboard-status-filter">
                  <div>
                    <Radio
                      label="active"
                      checked={
                        filterObj.status
                          ? filterObj.status == "active"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          status: "active",
                        });
                        setPageNum(1);
                      }}
                    />
                    <label className="label-radio-btn">Active</label>
                  </div>
                  <div>
                    <Radio
                      label="inactive"
                      checked={
                        filterObj.status
                          ? filterObj.status == "inactive"
                            ? true
                            : false
                          : false
                      }
                      onClick={() => {
                        setFilterObj({
                          ...filterObj,
                          pageNum: 1,
                          status: "inactive",
                        });
                        setPageNum(1);
                      }}
                    />
                    <label className="label-radio-btn">Inactive</label>
                  </div>

                  <Button
                    className="clear-navigo-booked-for"
                    onClick={() => {
                      let removedFilterObj = filterObj;
                      delete removedFilterObj.status;
                      setFilterObj({
                        ...removedFilterObj,
                        pageNum: 1,
                      });
                      setPageNum(1);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
        <div className="navigo-appoint-section-6">
          {serviceList && serviceList.length > 0 && (
            <div className="navigo-appoint-bottom-bar">
              <Button
                className="navigo-appoint-bottom-bar-button"
                onClick={() => {
                  handlePrevNextAppoint(0);
                }}
                disabled={pageNum == 1 || loading}
              >
                <FontIcon className="navigo-count-icon">arrow_drop_up</FontIcon>
              </Button>
              <div className="navigo-appoint-bottom-bar-text">
                Showing&nbsp;
                <span
                  style={{
                    color: "#ef7c01",
                  }}
                >
                  {(pageNum - 1) * pageSize + 1}&nbsp;- &nbsp;
                  {totalCount > pageNum * pageSize
                    ? pageNum * pageSize
                    : totalCount}
                </span>
                <span>&nbsp;of&nbsp;</span>
                <span>{` ${totalCount}`}</span>
              </div>
              <Button
                className="navigo-appoint-bottom-bar-button"
                onClick={() => {
                  handlePrevNextAppoint(1);
                }}
                disabled={
                  loading ||
                  pageNum * pageSize > totalCount ||
                  (totalCount > pageNum * pageSize
                    ? pageNum * pageSize
                    : totalCount) == totalCount
                }
              >
                <FontIcon className="navigo-count-icon">
                  arrow_drop_down
                </FontIcon>
              </Button>
            </div>
          )}
          <div className="navigo-appoint-section-6-list">
            {serviceList &&
              serviceList.map((ele) => {
                return (
                  <div
                    className="navigo-appoint-section-6-grid-container"
                    key={ele.id}
                  >
                    <div className="general-font-class">{`${ele.id}`}</div>
                    <div className="general-font-class ">{ele.name}</div>
                    {/* <div className="general-font-class ">
                      {ele.serviceTypeName}
                    </div> */}
                    <div className="general-font-class ">
                      {ele.serviceTypeId == 2 ? "NA" : ele.serviceSubtypeName}
                    </div>
                    <div className="general-font-class ">{ele.serviceName}</div>

                    <div className="general-font-class">
                      {moment(ele.updatedAt).format("DD MMM YYYY")}
                    </div>

                    <div className="general-font-class">
                      {`${ele.addressRequired ? "Yes" : "No"}`}
                    </div>
                    <div className="general-font-class">
                      {ele.canBeBookedFor}
                    </div>
                    <div className="general-font-class">
                      {`${ele.ageRule.isApplicable ? "Yes" : "No"}`}
                    </div>
                    <div className="navigo-appoint-section-6-grid-status">
                      <div
                        className={`${
                          ele.isActive
                            ? "navigo-appoint-non-select-status-active"
                            : "navigo-appoint-non-select-status-inactive"
                        } `}
                      >
                        <span>{ele.isActive ? "Active" : "Inactive"}</span>
                      </div>
                    </div>
                    <IconButton
                      onClick={(e) => handleClick(e, ele)}
                      className="navigo-appoint-section-6-grid-edit-call"
                    >
                      <OptionsIcon />
                    </IconButton>
                  </div>
                );
              })}
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  handleEditService(menuFor, 1);
                  setAnchorEl(null);
                }}
              >
                View service
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleEditService(menuFor, 2);
                  setAnchorEl(null);
                }}
              >
                Edit service
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleEditService(menuFor, 3);
                  setAnchorEl(null);
                }}
              >
                Assign service provider
              </MenuItem>
            </Menu>
          </div>
          {loading && <GlobalSkeletonLoader />}
          {loading === false && noService && (
            <div className="no-appointments-container">
              <p>No service found!</p>
            </div>
          )}
        </div>
        {openBackdrop && (
          <div
            className="navigo-appointment-backdrop-class"
            onClick={() => {
              setOpenFilter(0);
              setOpenBackdrop(false);
            }}
          ></div>
        )}
      </div>
    </div>
  );
}

export default ServiceDashboard;
