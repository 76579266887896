import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";
import Helper from "util/Helper";

export class AssignUtils {
  constructor() {
    this.apiService = new APIService();
    this.helper = new Helper();
  }
  saveAccount(body, onResponse, onError) {
    let url = APIConfig.myPlan.accountDashboard.create;
    return this.apiService.post(url, body, onResponse, onError);
  }
  //---
  fetchPatientId(id, onResponse, onError) {
    let url = APIConfig.patient.get.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  fetchAccountsOnPatientId(id, onResponse, onError) {
    let url = APIConfig.myPlan.assigned.accountsOnPid.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  getAccountsByFilter(body, onResponse, onError) {
    let url = APIConfig.myPlan.accountDashboard.getAccountsByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }
  assignAccount(body, onResponse, onError) {
    let url = APIConfig.myPlan.assigned.savePatientAccount;
    return this.apiService.post(url, body, onResponse, onError);
  }
  getPlansForPid(pid, onResponse, onError) {
    let url = APIConfig.myPlan.navigo.getPlansForPid.format(pid);
    return this.apiService.get(url, onResponse, onError);
  }
}

export default AssignUtils;
